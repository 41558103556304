import React from "react";
import { Col, Row } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';

const EventLoader = () => {
    return (
        <>
            <Row>
                <Col md={6} lg={4}>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0' }}>
                        <ContentLoader viewBox="0 0 500 150" >
                            <rect x="45%" y="38" rx="2" ry="2" width="234" height="12" />
                            <rect x="0" y="0" rx="10" ry="10" width="40%" height="100%" />
                            <rect x="45%" y="64" rx="2" ry="2" width="200" height="12" />
                            <rect x="45%" y="91" rx="2" ry="2" width="160" height="12" />
                        </ContentLoader>
                    </div>
                </Col>
                <Col md={6} lg={4}>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0' }}>
                        <ContentLoader viewBox="0 0 500 150" >
                            <rect x="45%" y="38" rx="2" ry="2" width="234" height="12" />
                            <rect x="0" y="0" rx="10" ry="10" width="40%" height="100%" />
                            <rect x="45%" y="64" rx="2" ry="2" width="200" height="12" />
                            <rect x="45%" y="91" rx="2" ry="2" width="160" height="12" />
                        </ContentLoader>
                    </div>
                </Col>
                <Col md={6} lg={4}>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0' }}>
                        <ContentLoader viewBox="0 0 500 150" >
                            <rect x="45%" y="38" rx="2" ry="2" width="234" height="12" />
                            <rect x="0" y="0" rx="10" ry="10" width="40%" height="100%" />
                            <rect x="45%" y="64" rx="2" ry="2" width="200" height="12" />
                            <rect x="45%" y="91" rx="2" ry="2" width="160" height="12" />
                        </ContentLoader>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default EventLoader;
