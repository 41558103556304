import { faEllipsis, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SingleBookmarkedArticles = (props) => {

    const [ triggerBookmark, setTriggerBookmark ] = useState();

    const handleOnClick = () => {
        props.onDeleteCallback();
    }

    return (
        <div className={`position-relative ${triggerBookmark ? 'dargbookmark' : '' }`}>
            <div className="milestone-single d-flex flex-wrap justify-content-between align-items-lg-center">
                <Link className="link-holder" to={`../article/${props.link}`}>
                    <div className='image-holder'><img src={props.image} alt="Description" /></div>
                    <div className='milestone-content'>
                        <div>
                            <time>{props.date}</time>
                            <h2>{props.title}</h2>
                            <p>{props.tagLine}</p>
                        </div>
                        <div className="content d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center gap-2">
                                <img className="authorImg" src={props.authorImage} alt={props.authorName} />
                                <p className="m-0 authur-name">{props.authorName}</p>
                            </div>
                            {/* <Reactions /> */}
                        </div>
                    </div>
                </Link>
                <Button onClick={()=>{setTriggerBookmark(!triggerBookmark)}} className="trigger-btn" variant="link"><FontAwesomeIcon icon={faEllipsis} /></Button>
            </div>
            <Button className="btn-delete text-white" variant='danger' onClick={handleOnClick}><FontAwesomeIcon icon={faTrash} /></Button>
        </div>
    );
}

export default SingleBookmarkedArticles;