import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Button, Col, Container, Row } from "react-bootstrap";
import Wrapper from "components/wrapper/Wrapper";
import "./article.scss"
import CompanySlider from "components/company/CompanySlider";
import { useNavigate, useParams } from "react-router-dom";
import { authContext } from "contexts/AuthContext";
import { ARTICLE } from "graphql/queries";
import { toast } from "react-toastify";
import CommentSection from "components/commentSection/CommentSection";
import Milestones from "components/milestones/Milestones";
import NewsFeed from "components/newsFeed/NewsFeed";
import Reactions from "components/reactions/Reactions";
import placeholder from "assets/images/placeholder.png"
import ContentLoader from "react-content-loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";

const ArticleDetail = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null)
    const { getClient } = useContext(authContext);
    let client = getClient();

    useEffect(() => {
        setData(null)
        client.query({
            query: ARTICLE,
            variables: {
                id: id
            }
        }).then(res => {
            setData(res.data.article)
        }).catch(err => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }, [id])

    useEffect(() => {
        if (document.querySelector(".heading-text")) {
            document.querySelector(".heading-text").remove()
        }
    }, [data])

    const MyLoader = (props) => (
        <ContentLoader
            speed={2}
            width="100%"
            height={1080}
            viewBox="0 0 100% 1080"
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
            {...props}
        >
            <rect x="1" y="0" rx="10" ry="10" width="100%" height="45vh" />
            <rect x="10%" y="50vh" rx="10" ry="10" width="80%" height="30" />
            <rect x="35%" y="56vh" rx="8" ry="8" width="30%" height="30" />
            <rect x="42%" y="63vh" rx="5" ry="5" width="16%" height="20" />
            <rect x="0" y="70vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="75vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="80vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="85vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="90vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="95vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="100vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="105vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="1010vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="1015vh" rx="5" ry="5" width="100%" height="30" />

        </ContentLoader>
    )

    return (
        <Wrapper>
            <Container fluid>
                <div className="articleDetail">
                    <div style={{ overflow: "hidden", padding: "1rem 0 0" }}>
                        <Button className="btn-back" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Go Back</Button>
                    </div>
                    {data ?
                        <>
                            <div className="img-holder">
                                <img className="articleImg mx-auto" src={data?.featuredImage?.largeUrl ? data?.featuredImage?.largeUrl : placeholder} alt="" />
                            </div>
                            <header className="articleHeader">
                                <h1 className="text-center m-0">{data?.title}</h1>
                                <p className="text-center font-italic text-small mt-2 mt-md-4"><em>Last updated on {data?.insertedAt ? moment.unix(data?.insertedAt).format("MMM DD, YYYY") : "--"}</em></p>
                            </header>
                            <div className="articleContent" dangerouslySetInnerHTML={{ __html: data?.htmlBlock }}></div>
                            <Row className="articleNavigation align-items-center">
                                <Col className="d-flex justify-content-start">
                                    {/* <p className="d-flex align-items-center m-0"><img src={IconArrowBack} alt="" />&nbsp; Back</p> */}
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <Reactions bookmark gap="gap-3" width="1.25rem" height="1.25rem" id={id} rated={data.rated} bookmarked={data.viewerHasBookmarked} />
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    {/* <p className="d-flex align-items-center m-0">Next&nbsp;<span className="d-none d-md-inline">Article</span> &nbsp;<img src={Rocket} alt="" /></p> */}
                                </Col>
                            </Row>
                            <hr />
                            <CommentSection resourceID={data?.id || null} commentsData={data?.commentConnection || { nodes: [] }} />
                            <Row className="my-5">
                                <Milestones cols={6} heading={"Related Milestones"} data={data?.related?.events} />
                            </Row>
                            <Row className="my-5">
                                <NewsFeed cols={4} heading={"Related Articles"} data={data?.related?.articles} />
                            </Row>
                            <Row className="my-5">
                                <CompanySlider data={data?.related?.companies} />
                            </Row>
                        </> :
                        <MyLoader />
                    }
                </div>
            </Container>
        </Wrapper>
    )
}

export default ArticleDetail
