import React, { useEffect, useState } from 'react';
import placeholder from "assets/images/placeholder.png"
import "./slidetimeline.scss";
import ContentLoader from 'react-content-loader';

const RidiculousSlide = (props) =>{
    const { data , max } = props;
    const [ height , setHeight ] = useState(6)


    useEffect(() => {
        if(data.unlockedEventsCount != 0 && 22/(max/data.unlockedEventsCount) > 22){
            setHeight(22);
        }
        else if((data.unlockedEventsCount != 0 && 22/(max/data.unlockedEventsCount) < 6) || data.unlockedEventsCount == 0){
            setHeight(6);
        }
        else{
            setHeight(22/(max/data.unlockedEventsCount))
        }
    },[height]) // calculating height as per number of events in yeard

    return(
        <div style={{"height":`${height}rem`}} className='timeline-slide-widget text-center d-flex flex-wrap flex-column justify-content-between'>
            <div className='image-holder'>
                <img src={data.imageUrl ? data.imageUrl : placeholder} alt="2022" />
            </div>
            <strong>{data.year == -1 ? "Never" : data.year}</strong>
        </div>
    );
}

export default RidiculousSlide;