import React from 'react';
import { Link } from 'react-router-dom';
import './ourexpert.scss'
import placeholder from "assets/images/placeholder.png";
import expertIcon from "assets/images/icon-expert-card.svg";

const OurExpert=(props)=>{
    return(
        <div className='column expert'>
            <div className='image-holder'>
                <div className='image-frame'>
                    <img src={props?.data?.avatar?.url ? props?.data?.avatar?.url : placeholder} alt="Supertrends" />
                    <div className='expert-icon'><img src={expertIcon} alt="supertrends" /></div>
                </div>
            </div>
            <div className='content-holder'>
                <header>
                    <h2>{props?.data?.firstName} {props?.data?.lastName}</h2>
                </header>
                {/* <p>{props?.data?.position}</p> */}
                <Link className='btn btn-primary' to={props?.data?.id} >Learn More</Link>
            </div>
        </div>
    );
}

export default OurExpert;