import React, { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import iconMenu from 'assets/images/icon-menu.svg';
import MenuWrapper from "components/wrapper/MenuWrapper";
import "./menu.scss"
import { Link } from "react-router-dom";
import ArrowIcon from "assets/images/icon-arrow.svg"
import future from "assets/images/menu/future.png"
import bookmarks from "assets/images/menu/bookmarks.png"
import search from "assets/images/menu/search.png"
import timeline from "assets/images/menu/timeline.png"
import notifications from "assets/images/menu/notifications.png"
import companies from "assets/images/menu/companies.png"
import account from "assets/images/menu/account.png"
import experts from "assets/images/menu/experts.png"
import users from "assets/images/menu/users.png"

const Menu = () => {
    const [open , setOpen] = useState(false)

    const menuData = [
        {
            icon: future,
            heading: 'My Future',
            subHeading: 'Everything current at a glance',
            link: '/',
        },
        {
            icon: bookmarks,
            heading: 'Bookmarks',
            subHeading: 'Your bookmarks',
            link: "bookmarks"
        },
        {
            icon: search,
            heading: 'Search',
            subHeading: 'Search Through all content',
            link: "search"
        },
        {
            icon: timeline,
            heading: 'Timeline',
            subHeading: 'We show you the future on a timeline',
            link: "timeline"
        },
        {
            icon: notifications,
            heading: 'Notifications',
            subHeading: 'See what’s going on',
            link: "notifications"
        },
        // {
        //     icon: settings,
        //     heading: 'Settings',
        //     subHeading: 'Lorem ipsum dolor sit',
        //     link: "settings"
        // },
        {
            icon: companies,
            heading: 'Companies',
            subHeading: 'Trending companies',
            link: "companies"
        },
        {
            icon: account,
            heading: 'My Account',
            subHeading: 'App & account settings',
            link: "myaccount"
        },
        {
            icon: experts,
            heading: 'Experts',
            subHeading: 'Supertrends experts',
            link: "experts"
        },
        {
            icon: users,
            heading: 'Users',
            subHeading: 'Search for new connections',
            link: "users"
        },
    ]

    return(
        <>
        <Button className="menu-opener" type="button" onClick={() => setOpen(!open)}><img width="20px" src={iconMenu} alt="Newsfeeds" /></Button>
        <MenuWrapper show={open} handleClose={setOpen}>
            <div className="menuContent">
                <h1>Menu</h1>
            </div>
            <Row className="my-3 menu-list">
                {menuData.map((menuItem , i) => 
                    <Col key={i} xs={12} sm={6} lg={4}>
                        <div className="menuItem d-flex align-items-center">
                            <div>
                                <img src={menuItem.icon} alt="" />
                            </div>
                            <div>
                                <Link to={menuItem.link} className="menuLink">{menuItem.heading} <img className="m-1" width="16px" src={ArrowIcon} alt="" /></Link>
                                <p className="m-0">{menuItem.subHeading}</p>
                            </div>
                        </div>
                    </Col>
                )}
            </Row>
        </MenuWrapper>
        </>
    )
}

export default Menu;
