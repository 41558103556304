import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/styles.scss';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import { useContext } from 'react';
import { authContext } from 'contexts/AuthContext';
import Routing from 'utils/routing/Routing';
import Header from 'components/header/Header';

function App() {
  const { getClient , auth } = useContext(authContext);

  return (
    <ApolloProvider client={getClient()}>
      <BrowserRouter>
        <ToastContainer />
        {auth.data ? <Header /> : null }
        <div className={auth.data ? "site-frame" : ""}>
          <Routing />
        </div>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
