import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import './yearchart.scss';


const YearChart = (props) => {
    const [swiper , setSwiper ] = useState({})
    const [data, setData] = useState([])
    const swiperSettings = {
        slidesPerView: 8,
        autoplay: true,
        // centeredSlides: true,
        spaceBetween: 16,
        breakpoints: {
            768: {
                slidesPerView: 12
            },
            1024: {
                slidesPerView: 19
            }
        }
    }

    const handleClick = (i) => {
        swiper.slideTo(i);
    }

    useEffect(() => {
        let i = 0;
        let tempArr = []
        for(let year = props.data?.chartData[0]?.year; year <= props.data?.chartData[props.data?.chartData.length - 1]?.year; year++){
            if(props.data?.chartData.some(el => el.year == year)){
                tempArr.push(props.data?.chartData[props.data?.chartData.findIndex(el => el.year == year)])
            }
            else{
                tempArr.push(
                    {
                        count: 0,
                        year: year
                    }
                )
            }
        }
        setData(tempArr)
    }, [props.data])

    return (
        <div className='timeline-chart'>
            <Swiper onInit={(ev) => {setSwiper(ev)}} {...swiperSettings} navigation={true} modules={[Navigation]}>
                {data && data.map((el,i) => {
                    return(
                        <SwiperSlide key={i} onClick={() => handleClick(i)}> 
                            <div className='chart-bar'>
                                <div className='chart-bar-area' style={{ 'height': el.count == 0 ? '1px' : `calc(${(el.count/props.data.totalCount)*100}% - 32px)` }}></div>
                                <time>{el.year}</time>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    );
}

export default YearChart;