import { authContext } from "contexts/AuthContext"
import { BOOKMARKED_EVENTS } from "graphql/queries";
import { useContext, useEffect, useState } from "react"
import { Alert } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { toast } from "react-toastify";
import SingleBookmarkedEvent from "./SingleBookmarkedEvent";
import placeholder from "assets/images/placeholder.png";

export default function BookmarkedEvents(props) {


    const { getClient } = useContext(authContext);

    const [bookmarks, setBookmarks] = useState([]);
    const [loading, setLoading] = useState(true);

    const client = getClient();

    useEffect(() => {
        client.query({
            query: BOOKMARKED_EVENTS
        }).then(res => {
            setLoading(false);
            setBookmarks(res.data.viewer.bookmarkedEventsConnection.nodes);
        }).catch(err => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        });
    }, [])

    const handleOnDelete = (id,index) => {

        props.onDelete(id).then(res=>{

            bookmarks.splice(index,1);
            setBookmarks([...bookmarks]);
        }).catch(err=>{
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }

    const MyLoader = (props) => (
        <ContentLoader
            speed={2}
            width="100%"
            height="11vh"
            viewBox="0 0 100% 1080"
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
            {...props}
        >
            <rect x="0" y="0" rx=".5rem" ry=".5rem" width="5.65rem" height="100%" />
            <rect x="6.8rem" y="2.5vh" rx="0" ry="0" width="60%" height="20" />
            <rect x="6.8rem" y="6.5vh" rx="0" ry="0" width="10%" height="14" />

        </ContentLoader>
    )

    return (
        <div className='px-lg-5 milestones-tab'>
            { loading ? 
                Array(5).fill(0).map(()=><div className={`d-flex flex-wrap justify-content-between align-items-md-center milestone-single`}>
                    <MyLoader />
                </div>)
            : bookmarks.length > 0 ? bookmarks.map((data, index) =>
                <SingleBookmarkedEvent key={data.id} title={data.title} image={data?.featuredImage?.thumbnailUrl ?? placeholder} link={data.id} date={data?.happenedInYear ? data?.happenedInYear : data?.consensusYear} onDeleteCallback={()=>handleOnDelete(data.id,index)}/>
            ) : (<Alert variant="dark">No Bookmarked Events were Found!</Alert>)}

        </div>
    )
}