import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from 'assets/images/logo.png';
import iconNewsfeed from 'assets/images/icon-feeds.svg';
import iconMilestones from 'assets/images/icon-milestines.svg';
import iconFlyover from 'assets/images/icon-flyover.svg';
import Wrapper from "components/wrapper/Wrapper";
import ProfileDropdown from "components/dropdowns/ProfileDropdown";
import Menu from "components/menu/Menu";
import Search from "components/search/Search";
import notifications from "assets/images/menu/notifications.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import iconSearch from 'assets/images/icon-search.svg';

const Header = () => {
    return (
        <header id="header">
            <Container fluid className="shadow-sm p-3 bg-white">
                <Wrapper>
                    <Row className="align-items-center justify-content-between">
                        <Col xs={6} md={3} lg={2}>
                            <div><Link to="/"><img width="130px" src={Logo} alt="SuperTrends" /></Link></div>
                        </Col>
                        <Col className="main-navigation" xs={12} lg={8}>
                            <ul className="m-0 list-unstyled d-flex flex-wrap justify-content-end justify-content-md-center main-menu">
                                <li className="mx-lg-4"><Link to="/" className="text-center text-lg-left d-lg-flex flex-wrap align-items-center"><div className="icon-holder"><img src={iconNewsfeed} alt="Newsfeeds" /></div> Newsfeed</Link></li>
                                <li className="mx-lg-4"><Link to="/timeline" className="text-center text-lg-left d-lg-flex flex-wrap align-items-center"><div className="icon-holder"><img src={iconMilestones} alt="Newsfeeds" /></div> Milestones</Link></li>
                                {/* <li className="mx-lg-4"><Link to="#" className="text-center text-lg-left d-lg-flex flex-wrap align-items-center"><div className="icon-holder"><img src={iconTrends} alt="Newsfeeds" /></div> Trends &nbsp;<span className="d-none d-lg-block">&#38; Technologies</span></Link></li> */}
                                <li className="mx-lg-4"><Link to="/flyover" className="text-center text-lg-left d-lg-flex flex-wrap align-items-center"><div className="icon-holder"><img src={iconFlyover} alt="Newsfeeds" /></div> Flyover</Link></li>
                                <li className="mx-lg-4"><Link to="/notifications" className="text-center text-lg-left d-lg-flex flex-wrap align-items-center"><div className="icon-holder"><FontAwesomeIcon icon={faBell} /></div> Notifications</Link></li>
                            </ul>
                        </Col>
                        <Col xs={6} md={3} lg={2}>
                            <ul className="m-0 list-unstyled d-flex flex-wrap justify-content-end align-items-center header-links">
                                <li><Link to="/search"><img width="20px" src={iconSearch} alt="Newsfeeds" /></Link></li>
                                <li><ProfileDropdown /></li>
                                <li><Menu /></li>
                            </ul>
                        </Col>
                    </Row>
                </Wrapper>
            </Container>
        </header>
    )
}

export default Header;