import TabsSidebar from 'components/TabsSidebar/TabsSidebar';
import React from 'react';
import { Col, Container, Row, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './settings.scss';

const Settings=()=>{

    return(
        <Container className='my-account'>
            <Row>
                <Col sm={3}>
                    <h2 className='mb-3 mb-md-5'>Settings</h2>
                </Col>
                <Col sm={9}>
                    <div className="px-md-5 setting-options">
                        <div className='setting-col'>
                            <div className="form-check form-switch">
                                <label className="form-check-label" for="diagnostics">Diagnostics and usage data</label>
                                <input className="form-check-input" type="checkbox" role="switch" id="diagnostics" />
                            </div>
                            <p>Help us fix issues and improve application features anonymously. Anonymous data include: crash report, error logs, performance measurements and products interaction.</p>
                        </div>
                        <div className='setting-col'>
                            <div className="form-check form-switch">
                                <label className="form-check-label" for="diagnostics">Show glimpses from past</label>
                                <input className="form-check-input" type="checkbox" role="switch" id="diagnostics" />
                            </div>
                            <p>Display information about an important historic event once a day.</p>
                        </div>
                        <div className='setting-col'>
                            <div className="form-check form-switch">
                                <label className="form-check-label" for="diagnostics">Show fact of the day</label>
                                <input className="form-check-input" type="checkbox" role="switch" id="diagnostics" />
                            </div>
                            <p>Fact of a day is a hand picked curiasity you may be presented with on daily basis.</p>
                        </div>
                        <div><h2><Link to="/">Privacy Policy</Link></h2></div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
export default Settings;