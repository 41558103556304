import { faPlay, faPlus, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Form, FormSelect, Modal } from 'react-bootstrap';
// import { DatePicker  } from 'antd';
// import 'antd/dist/antd.css';
import tagsImg from '../../assets/images/bg-flyover.png'
import './tags.scss';
import moment from 'moment'
import { YearRangePicker } from 'react-year-range-picker';

const Tags = (props) => {
    const [show, setShow] = useState(false);
    const [sYear, setSYear] = useState(props.startYear);
    const [eYear, setEYear] = useState(props.endYear);
    const [error, setError] = useState(null);
    const [tags, setTags] = useState(props.selectedTags);
    //const [yearRange, setYearRange] = useState(null);
    const handleOnSelectTag = (lane,tag) => {
        tags[lane] = tag == "" ? null : tag;
        setTags({...tags});
        setError(null);

    }

    const handleOnSubmit = (e)=>{
        e.preventDefault();

        if(Object.values(tags).filter(v=>v==null).length < 5){
            props.handleSubmit(tags,sYear,eYear);
            props.handleClose();
        }else{
            setError("Please select at least one tag.")
        }
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <div>
                <Form className='tags-form' onSubmit={handleOnSubmit}>
                    <div className='d-flex flex-wrap justify-content-between p-4'>
                        <div className='form-area'>
                            <div className='form-row'>
                                <FormSelect onChange={(e)=>handleOnSelectTag("lane1",e.target.value)} defaultValue={tags["lane1"]}>
                                    <option value="">Select a Tag..</option>
                                    { props.tags.map(t=><option value={`${t.id}:${t.name.replace("Supertrend:","")}`}>{t.name.replace("Supertrend:","")}</option>)} 
                                </FormSelect>
                            </div>
                            <div className='form-row'>
                                <FormSelect onChange={(e)=>handleOnSelectTag("lane2",e.target.value)} defaultValue={tags["lane2"]}>
                                    <option value="">Select a Tag..</option>
                                    { props.tags.map(t=><option value={`${t.id}:${t.name.replace("Supertrend:","")}`}>{t.name.replace("Supertrend:","")}</option>)} 
                                </FormSelect>
                            </div>
                            <div className='form-row'>
                                <FormSelect onChange={(e)=>handleOnSelectTag("lane3",e.target.value)} defaultValue={tags["lane3"]}>
                                    <option value="">Select a Tag..</option>
                                    { props.tags.map(t=><option value={`${t.id}:${t.name.replace("Supertrend:","")}`}>{t.name.replace("Supertrend:","")}</option>)} 
                                </FormSelect>
                            </div>
                            <div className='form-row'>
                                <FormSelect onChange={(e)=>handleOnSelectTag("lane4",e.target.value)} defaultValue={tags["lane4"]}>
                                    <option value="">Select a Tag..</option>
                                    { props.tags.map(t=><option value={`${t.id}:${t.name.replace("Supertrend:","")}`}>{t.name.replace("Supertrend:","")}</option>)} 
                                </FormSelect>
                            </div>
                            <div className='form-row'>
                                <FormSelect onChange={(e)=>handleOnSelectTag("lane5",e.target.value)} defaultValue={tags["lane5"]}>
                                    <option value="">Select a Tag..</option>
                                    { props.tags.map(t=><option value={`${t.id}:${t.name.replace("Supertrend:","")}`}>{t.name.replace("Supertrend:","")}</option>)} 
                                </FormSelect>
                            </div>
                            <div className='d-lg-flex flex-wrap align-items-center my-3 my-lg-5'>
                                <p className='m-0 mb-2 mb-lg-0 me-lg-3 fw-bold'>Select Year Range: </p>
                                <YearRangePicker
                                    minYear={new Date().getFullYear() - 1000}
                                    maxYear={new Date().getFullYear() + 1000}
                                    onSelect={(startYear, endYear) => {
                                        setSYear(startYear);
                                        setEYear(endYear)
                                    }}
                                    startYear={sYear}
                                    endYear={eYear}
                                />
                            </div>
                            {/* <p>error</p> */}
                            {/* <div className='d-flex flex-wrap align-items-center mb-3 mb-lg-5'>
                                <div className='settings-col d-flex flex-wrap align-items-center'>
                                    <label className='me-2 fw-bold' htmlFor="autoplay">Autoplay:</label>
                                    <input id="autoplay" type="checkbox" />
                                </div>
                                <div className='settings-col d-flex flex-wrap align-items-center'>
                                    <label className='me-2 fw-bold' htmlFor="loop">Loop:</label>
                                    <input id="loop" type="checkbox" />
                                </div>
                            </div> */}
                            {error && <p className='text-danger'>{error}</p>}
                            <div className='text-center'>
                                <button className='btn-tag-submit' type="submit">Submit</button>
                            </div>
                        </div>
                        <div className='change-image-area'>
                            {/* <button onClick={() => handleShow()} type="button">Change Image</button> */}
                            <img src={tagsImg} alt="Description" />
                        </div>
                    </div>
                </Form>
            </div>
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Custom Background Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="selectbale_wrapper">
                        <label for="custom-file"><FontAwesomeIcon size="36" icon={faPlusCircle} /></label>
                        <input
                            type="file"
                            id="custom-file"
                            className="invisible w-0 absolute"
                            // label={Image}
                            custom
                            accept=".png,.jpeg,.jpg,.gif,.webp,.mp4"
                            // onChange={uploadMedia}
                        />
                    </div>
                    {/* <label className="selectbale_wrapper">
                        <input type="radio" name="galleryImage" />
                            <FontAwesomeIcon icon={faPlusCircle} />
                            <img src={tagsImg} alt="image" className="selectable_image" />
                            <div>
                                <video width="100%" height="100%" controls={false} autoPlay="autoplay" muted>
                                    <source src={process.env.REACT_APP_S3_URL + item.Key} type="video/mp4" />
                                </video>
                                <FontAwesomeIcon width="100px" color="#ffffff" icon={faPlay} />
                            </div>
                        <span variant="danger" className="deleteFileIcon"><FontAwesomeIcon icon={faTrashAlt} /></span>
                    </label> */}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Tags;