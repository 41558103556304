import TabsSidebar from 'components/TabsSidebar/TabsSidebar';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row, Tab } from 'react-bootstrap';
import './notifications.scss';
import { authContext } from 'contexts/AuthContext';
import { toast } from 'react-toastify';
import { NOTIFICATIONS } from 'graphql/queries';
import moment from 'moment';
import SingleNotification from './SingleNotification';
import ContentLoader from 'react-content-loader';

const Notifications=()=>{

    const {getClient} = useContext(authContext);

    const [notifications,setNotifications] = useState([]);
    const [loading,setLoading] = useState(true);
    
    const client = getClient();

    useEffect(()=>{
        client.query({
            query:NOTIFICATIONS
        }).then(res=>{
            setLoading(false);
            setNotifications(res.data.viewer.notificationsConnection.nodes);
        }).catch(err=>{
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        });
    },[])

    const notificationTabData=[
        {
            title: "Unread",
            event: "Unread"
        },
        {
            title: "Read",
            event: "Read"
        }
    ]

    const MyLoader = (props) => (
        <ContentLoader
            speed={2}
            width="100%"
            height="11vh"
            viewBox="0 0 100% 1080"
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
            {...props}
        >
            <rect x="0" y="0" rx=".5rem" ry=".5rem" width="5.65rem" height="100%" /> 
            <rect x="6.8rem" y="1.5vh" rx="0" ry="0" width="120" height="20" /> 
            <rect x="6.8rem" y="5vh" rx="0" ry="0" width="50%" height="14" /> 
            <rect x="6.8rem" y="8vh" rx="0" ry="0" width="60" height="14" />

        </ContentLoader>
    )

    return(
        <Tab.Container id="left-tabs-example" defaultActiveKey="Unread">
            <Container className='my-account'>
                <Row>
                    <Col sm={3}>
                        <TabsSidebar sidebarOptions={notificationTabData} title="Notifications" />
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="Unread">
                                <div className='px-lg-5 milestones-tab notifications'>
                                    {loading ? 
                                            Array(5).fill(0).map(()=><div className="d-flex flex-wrap align-items-md-center milestone-single">
                                                <MyLoader />
                                            </div>)
                                    : notifications.filter(n=>!n.isRead).map((data,index)=>{ 
                                        return (
                                            <>
                                            <SingleNotification key={data.id} avatar={data.sender.avatar.url} title={data.title} body={data.body} date={moment.unix(data.insertedAt).fromNow()} />
                                            </>
                                        )
                                    })}
                                    
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Read">
                                <div className='px-lg-5 milestones-tab notifications'>
                                    {loading ? 
                                            Array(5).fill(0).map(()=><div className="d-flex flex-wrap align-items-md-center milestone-single">
                                                <MyLoader />
                                            </div>)
                                    : notifications.filter(n=>n.isRead).map((data,index)=>{
                                        return (
                                            <SingleNotification key={data.id} avatar={data.sender.avatar.url} title={data.title} body={data.body} date={moment.unix(data.insertedAt).fromNow()} />
                                        )
                                    })}
                                    
                                </div>
                            </Tab.Pane>
                            
                        </Tab.Content>
                    </Col>
                </Row>
            </Container>
        </Tab.Container>
    );
}
export default Notifications;