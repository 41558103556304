import { gql } from "@apollo/client";

export const TAG_FIELDS = gql`
    fragment TagFragment on Tag {
        id
        name
    }
`;

export const IMAGE_FIELDS = gql`
    fragment ImageFragment on Image {
        id
        copyright
        source
        url
    }
`;

export const INTERESTS_FIELDS = gql`
    fragment InterestsFragment on UserInterface {
        id
        interests {
            id
            name
            score
        }
    }
`;

export const ARTICLE_CARD_FIELDS = gql`
    ${IMAGE_FIELDS}
    fragment ArticleCardFragment on Article {
        id
        tags {
            id
            name
        }
        # featured image seems to be missing in model
        featuredImage {
            originalUrl
            thumbnailUrl # 150 x 150
            mediumUrl # 300 x 300
            largeUrl # 1024 x 1024
        }
        title
        tagline
        descriptionBlock{
            plainText
            html
        }
        insertedAt
        rated
        viewerHasBookmarked
        createdBy {
            id
            firstName
            lastName
            education
            avatar {
                ...ImageFragment
            }
        }
        
    }
`;

export const EVENT_CARD_FIELDS = gql`
    fragment EventCellFragment on Event {
        id
        title
        featuredImage {
            originalUrl
            thumbnailUrl # 150 x 150
            mediumUrl # 300 x 300
            largeUrl # 1024 x 1024
        }
        viewerHasBookmarked
        predictionAggregate {
            recentlyMovedFrom {
                consensusYear
            }
        }
        happenedInYear
        consensusYear
        htmlBlock
    }
`;

export const COMPANY_CARD_FIELDS = gql`
    fragment CompanyCellFragment on Company {
        id
        featuredImage {
            originalUrl
        }
        name
        tableEntries {
            key
            value
            isConsideredNew
        }
        viewerHasBookmarked
    }
`;

export const USER_CARD_FIELDS = gql`

    ${IMAGE_FIELDS}

    fragment UserCellFragment on User {
        id
        avatar {
            id
            ...ImageFragment
        }
        firstName
        lastName
        followersConnection {
            totalCount
        }
        viewerIsBlockingUser
        viewerIsFollowingUser
    }
`;

export const USER_FIELDS = gql`
    ${IMAGE_FIELDS}
    ${INTERESTS_FIELDS}
    fragment UserProfileFragment on User {
        id
        avatar {
            id
            ...ImageFragment
        }
        firstName
        lastName
        biographyBlock {
            plainText
        }
        followersConnection {
            totalCount
        }
        followingConnection {
            totalCount
        }
        viewerIsFollowingUser
        viewerIsBlockingUser
        ...InterestsFragment
        isViewer
    }
`;

export const VIEWER_FIELDS = gql`
    ${IMAGE_FIELDS}
    ${INTERESTS_FIELDS}
    ${ARTICLE_CARD_FIELDS}
    ${USER_CARD_FIELDS}

    fragment ViewerFragment on Viewer {
        id
        avatar {
            id
            ...ImageFragment
        }
        firstName
        lastName
        email
        biographyBlock {
            plainText
        }
        followersConnection {
            nodes{
                id
                ...UserCellFragment
            }
            totalCount
        }
        followingConnection {
            nodes{
                id
                ...UserCellFragment
            }
            totalCount
        }
        blockedCount
        blockedUsers{
            id
            ...UserCellFragment
        }
        allowsSearchByEmail
        ...InterestsFragment
        recommendedArticlesConnection{
            nodes {
                id
                ...ArticleCardFragment
            }
        }
        # futureEventsConnection{
        #     nodes {
        #         id
        #         ...EventCellFragment
        #     }
        # }
        # unpredictedEventsConnection {
        #     nodes {
        #         id
        #         ...EventCellFragment
        #     }
        # }
        timeline(includeNever: true) {
            years {
                year
                featuredEventsCount
                unlockedEventsCount
                lockedEventsCount
                historicEventsCount
                imageUrl
                title
            }
        }
    }
`;

export const AUTH_FIELDS = gql`
    ${VIEWER_FIELDS}
    fragment AuthenticationFragment on Authentication {
        accessToken
        refreshToken
        viewer {
            id
            ...ViewerFragment
        }
    }
`;

export const COMMENT_CELL_FIELDS = gql`
    fragment CommentCellFragment on Comment {
        id
        createdBy {
            id
            avatar {
                id
                ...ImageFragment
            }
            firstName
            lastName
        }
        insertedAt
        text
        rated
        reactionCounters {
            dislike
            like
        }
        commentConnection {
            totalCount
        }
    }
`;


export const RELATED_CONTENT_FIELDS = gql`
    
    ${ARTICLE_CARD_FIELDS}
    ${COMPANY_CARD_FIELDS}
    ${EVENT_CARD_FIELDS}

    fragment RelatedContentFragment on Taggable {
        related {
            articles {
                id
                ...ArticleCardFragment
            }
            companies {
                id
                ...CompanyCellFragment
            }
            events {
                id
                ...EventCellFragment
            }
            __typename
        }
    }
`;

export const COMMENT_FIELDS = gql`

    ${IMAGE_FIELDS}
    ${COMMENT_CELL_FIELDS}

    fragment CommentFragment on Comment {
        id
        parentId
        text
        insertedAt
        viewerCanDelete
        viewerCanUpdate
        nestingLevel
        commentConnection {
            nodes{
                id
                ...CommentCellFragment
            }
            totalCount
        }
        createdBy {
            id
            firstName
            lastName
            avatar {
                ...ImageFragment
            }
        }
        reactionCounters {
            dislike
            like
        }
        rated
    }
`;

export const REACTION_FIELDS = gql`
    fragment ReactionFragment on Rateable {
        rated
        reactionCounters{
            like
            dislike
        }
        viewerCanRate
    }
`;

export const ARTICLE_DETAIL_FIELDS = gql`
    
    ${COMMENT_FIELDS}
    ${RELATED_CONTENT_FIELDS}
    ${REACTION_FIELDS}

    fragment ArticleDetailsFragment on Article {
        id
        htmlBlock
        viewerHasBookmarked
        viewerHasRead
        insertedAt
        ...ReactionFragment
        commentConnection {
            nodes {
                id
                ...CommentFragment
            }
        }
        ...RelatedContentFragment
    }
`;

export const EVENT_DETAIL_FIELDS = gql`
    
    ${COMMENT_FIELDS}
    ${RELATED_CONTENT_FIELDS}
    ${REACTION_FIELDS}

    fragment EventDetailsFragment on Event {
        id
        htmlBlock
        viewerHasBookmarked
        viewerHasRead
        rated
        ...ReactionFragment
        tags {
            id
            name
        }
        insertedAt
        predictedImpacts {
            id
            key
            message
            yourValue
            statistics {
                littlePercentage
                mediumPercentage
                strongPercentage
            }
            
        }
        commentConnection {
            nodes {
                id
                ...CommentFragment
            }
        }
        ...RelatedContentFragment
    }
`;

export const PREDICTION_GRAPH_FIELDS = gql`
    fragment PredictionsGraphFragment on Event {
    id
    happenedInYear
    viewerLatestPrediction {
        year
    }
    predictionAggregate {
        consensusYear
        chartData {
            count
            year
        }
        neverCount
        totalCount
    }
    
}
`;


export const EXPERT_CARD_FIELDS = gql`
    ${IMAGE_FIELDS}
    fragment ExpertCellFragment on User {
        id
        avatar {
            id
            ...ImageFragment
        }
        firstName
        lastName
        biographyBlock {
            plainText
        }
        followingConnection{
            totalCount
        }
    }

`;


export const EXPERT_DETAIL_FIELDS = gql`
    fragment InnovatorDetailsFragment on Innovator {
        id
        avatar {
            originalUrl
            thumbnailUrl # 150 x 150
            mediumUrl # 300 x 300
            largeUrl # 1024 x 1024
        }
        name
        position
        descriptionBlock {
            plainText
        }
        company {
            id
            name
            images {
                id
                url
            }
            tags {
                id
                name
            }
        }
        viewerHasBookmarked
    }
`;

export const COMPANY_DETAIL_FIELDS = gql`
    ${RELATED_CONTENT_FIELDS}
    fragment CompanyDetailsFragment on Company {
        id
        featuredImage {
            originalUrl
        }
        tags {
            id
            name
        }
        name
        descriptionBlock {
            plainText
        }
        tableEntries {
            isConsideredNew
            key
            value
        }
        viewerHasBookmarked
        rated
        ...RelatedContentFragment
    }
`;

export const BOOKMARK_UPDATE_FIELDS = gql`
    fragment BookmarkUpdateFragment on Bookmarkable {
        id
        viewerHasBookmarked
    }
`;



export const EVENT_IMPACT_FIELDS = gql`
    fragment EventImpactFragment on EventImpact {
        id
        statistics {
            littlePercentage
            mediumPercentage
            strongPercentage
        }
        yourValue
    }
`;

export const NOTIFICATION_CARD_FIELDS = gql`
    ${IMAGE_FIELDS}
    fragment NotificationCellFragment on Notification {
        id
        body
        insertedAt
        isRead
        sender {
            id
            avatar {
                id
                ...ImageFragment
            }
        }
        title
        triggerObject {
            id
            type
        }
    }
`;