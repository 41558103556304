import React, { useContext, useState, useRef, useEffect } from "react";
import { authContext } from "../../contexts/AuthContext";
import { dataContext } from "../../contexts/DataContext";
import './animation.css';
import bgFlyover from "../../assets/images/bg-flyover.png";
import fsButton from "../../assets/images/icon-fullscreen.png";
import Card from "./Card";
import Logo from '../../assets/images/logo.png';
import Play from '../../assets/images/play.png';
import Pause from '../../assets/images/pause.png';
import { gql } from "@apollo/client";
import { EVENT_CARD_FIELDS } from "graphql/fragments";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import Tags from "./Tags";
import { TAGS } from "graphql/queries";
import loadingImg from '../../assets/images/favicon.png';

const Flyover = () => {
    // const {selectedOptions, sYear, eYear , background, autoPlay , loop} = useContext(dataContext)
    const { data } = useContext(dataContext)
    const { auth, getClient } = useContext(authContext);

    const client = getClient();

    const [events, setEvents] = useState([]);
    // const [showControls, setshowControls] = useState(false);

    const [tagsShow, setTagsShow] = useState(true);
    const [tags, setTags] = useState(null);

    const [selectedTags, setSelectedTags] = useState({
        lane1: null,
        lane2: null,
        lane3: null,
        lane4: null,
        lane5: null,
    });

    const [startYear, setStartYear] = useState(new Date().getFullYear());
    const [endYear, setEndYear] = useState(null);

    const [loading, setLoading] = useState(true);
    const handleTagsClose = () => setTagsShow(false);
    const handleTagsShow = () => setTagsShow(true);

    const scrubber = useRef(null);
    const timeElapsed = useRef(null);
    const timeRemaining = useRef(null);
    const animationContainer = useRef(null);
    //const labels = data.interests.slice(0,5).map(tag => tag.name);
    //const tags = selectedOptions.reduce((tags, el, i) => el != null ? tags + el.value + ":" + (i + 1) + "," : tags, "").slice(0, -1);
    const backgroundImg = null;

    /*const onHandleSelectedTag = (lane,id) => {
        selectedTags[lane] = id;
        setSelectedTags({...selectedTags});
    }*/

    useEffect(() => {
        if (tags == null) {
            client.query({
                query: TAGS
            }).then(res => {
                setTags(res.data.tags)
                setLoading(false)
            }).catch(err => {
                setLoading(false)
                console.log(err)
            })
        }
        else {
            setLoading(true)
        }
    }, [])

    const adjustScrubber = () => {
        //console.log(timeElapsed.current);
        if (document.querySelector(".anim-content") == null) {
            return;
        }
        if (!animationContainer.current.classList.contains("pause")) {
            var nodes = document.querySelectorAll(".anim-content");
            var lastNode = nodes[nodes.length - 1];
            var time = lastNode.getAnimations()[0].currentTime;

            //console.log(lastNode.getAnimations()[0].effect.getTiming());
            var date = new Date(0);
            date.setSeconds(parseInt(time / 1000)); // specify value for SECONDS here

            scrubber.current.value = time;

            timeElapsed.current.textContent = date.toISOString().substr(11, 8);

            // var remaining = ((events.length-1) * 3 + 10) - time/1000;
            var remaining = (lastNode.getAnimations()[0].effect.getTiming().delay / 1000 + 10 - time / 1000);

            // console.log(parseInt(remaining));
            //date.setSeconds(parseInt(((events.length-1) * 3 + 10) - time/1000)); // specify value for SECONDS here

            timeRemaining.current.textContent = `${parseInt(remaining / 60) < 10 ? 0 : ""}${parseInt(remaining / 60)}:${parseInt(remaining % 60) < 10 ? 0 : ""}${parseInt(remaining % 60)}`;

            requestAnimationFrame(adjustScrubber);
        }

    }

    const finishAll = () => {
        document.querySelectorAll(".anim-content").forEach((n, i) => {
            //console.log(n);
            var anim = n.getAnimations();
            //var timing = anim[0].effect.getTiming();
            //timing.endDelay = (((events.length - 1)*3 + 10) - ((i*3) + 10)) * 1000;
            anim[0].finish();
        });
    }

    const toggleFullscreen = () => {
        if (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement
        ) {
            // console.log(document.exitFullscreen);
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        } else {
            if (animationContainer.current.requestFullscreen) {
                animationContainer.current.requestFullscreen();
            } else if (animationContainer.current.mozRequestFullScreen) {
                animationContainer.current.mozRequestFullScreen();
            } else if (animationContainer.current.webkitRequestFullscreen) {
                animationContainer.current.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            } else if (animationContainer.current.msRequestFullscreen) {
                animationContainer.current.msRequestFullscreen();
            }
        }

        addDelay();
    }

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    };

    const initTimeline = (timelineTags, sYear, eYear) => {

        //if(!!data) return;

        let timelineQuery = []
        let laneMap = {};

        console.log(timelineTags);
        for (let [lane, tagId] of Object.entries(timelineTags)) {
            if (tagId == null) continue;
            let tagID = tagId.split(":");
            let params = "";
            if (sYear) {
                params += ",startYear:" + parseInt(sYear)
            }
            if (eYear) {
                params += ",endYear:" + parseInt(eYear)
            }
            timelineQuery.push(`${tagID[0]} : flyoverConnection(tags:["${tagID[0]}"]${params}){
                ...EventCellFragment
            }`)
            laneMap[tagID[0]] = lane;
        };

        timelineQuery = timelineQuery.join("\n");

        console.log(timelineQuery);
        let query = gql`
            
            ${EVENT_CARD_FIELDS}
            
            query Flyover {
                viewer{
                    ${timelineQuery}
                }
            }
        `;

        console.log(query);

        setLoading(true);
        client.query({
            query: query
        }).then(res => {
            let yearData = {};
            delete (res.data.viewer["__typename"]);
            for (const [tagID, tagData] of Object.entries(res.data.viewer)) {
                //let cards = [];
                console.log(tagID);
                if (tagData == null) continue;

                for (let event of tagData) {
                    let year = event.consensusYear != null ? event.consensusYear : event.happenedInYear;
                    if (!yearData.hasOwnProperty(`y${year}`)) {
                        yearData[`y${year}`] = [];
                    }
                    yearData[`y${year}`].push({
                        ...event,
                        trend: laneMap[tagID].replace("lane", "")
                    })
                };
            };

            const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

            const ordered = Object.keys(yearData).sort(collator.compare);

            let shuffled = [];

            for (let year of ordered) {
                //console.log(this.shuffleArray(yearData[k]));

                shuffleArray(yearData[year]);
                shuffled = shuffled.concat(yearData[year])
                //console.log(shuffled[k]);
            }

            setStartYear(sYear);
            setEndYear(eYear);
            setSelectedTags(timelineTags);
            setEvents([...shuffled]);
            setLoading(false);
        }).catch(e => {
            console.log(e)
        })


        // api.get(Url).then(res => {
        //     setEvents(res.data.data);
        // })


    }

    useEffect(() => {
        //console.log(animationContainer.current);
        //console.log(animationContainer.current.childNodes);
        scrubber.current.addEventListener('input', e => {
            var time = e.currentTarget.value; //The range's value will be: 0 <= val <= 16000
            animationContainer.current.classList.add("pause");
            document.querySelectorAll(".anim-content").forEach((n, i) => {
                var anim = n.getAnimations();
                anim[0].currentTime = time;
            })
        })

        scrubber.current.addEventListener('change', e => {
            console.log(e.target.value)
            if (document.querySelector(".anim-content").getAnimations()[0].currentTime >= e.currentTarget.getAttribute('max')) {
                finishAll();
                return false;
            }
            requestAnimationFrame(adjustScrubber);
            animationContainer.current.classList.remove("pause")
        });

        addDelay();
        adjustScrubber();
    }, [events])

    const addDelay = () => {
        const animatedCards = document.querySelectorAll(".anim-content"); //.forEach((n, i) => {

        for (let [i, n] of animatedCards.entries()) {

            console.log(n);
            let anim = n.getAnimations();
            console.log(anim);
            //var timing = anim[0].effect.getTiming();
            //timing.endDelay = (((events.length - 1)*3 + 10) - ((i*3) + 10)) * 1000;
            anim[0].effect.updateTiming({

                endDelay: (((events.length - 1) * 3 + 10) - ((i * 3) + 10)) * 1000
            })
        }
        //});
    }

    // useEffect(() => {
    //     if(autoPlay == false){
    //         animationContainer.current.classList.toggle("pause");
    //         requestAnimationFrame(adjustScrubber);
    //     }
    //     if(loop == true){
    //         animationContainer.current.classList.toggle("loop");
    //         requestAnimationFrame(adjustScrubber);
    //     }
    // }, [autoPlay , loop])

    const togglePlayPause = () => {
        animationContainer.current.classList.toggle("pause");
        requestAnimationFrame(adjustScrubber);
    }

    return (
        <div className="flyover-wrapper">
            <div id="wrapper" className="my-3 my-md-5">
                {/* {console.log(backgroundImg)} */}
                <div className="anim-container bg-black" ref={animationContainer} style={{ backgroundImage: `url(${loading ? "" : bgFlyover})` }}>
                    {loading ? <div className="loading-flyover"><img src={loadingImg} alt="Loading" /></div> : ''}
                    {
                        events.length > 0 && events.map((e, i) => {
                            return (

                                <Card lane={`lane${e.trend}`} delay={`${i * 3}s`}
                                    year={e.consensusYear != null ? e.consensusYear : e.happenedInYear}
                                    title={e.title}
                                    image={e.featuredImage != null ? e.featuredImage.thumbnailUrl != null ? e.featuredImage.thumbnailUrl : "" : ""} />
                            )
                        })


                    }


                    <ul className="color-labels">
                        {
                            Object.keys(selectedTags).map((l) => {
                                //const [labelText, labelPos] = l.split(":");
                                //let label = l.replaceAll("Supertrend:", "").replaceAll("Theme:", "");
                                if (selectedTags[l]) {

                                    return (

                                        <li className={`${l}`}>{selectedTags[l].split(":")[1]}</li>
                                    )
                                }
                            })
                        }
                        {/* <li className="lane2">Orange</li>
                            <li className="lane3">Yellow</li>
                            <li className="lane4">Green</li>
                            <li className="lane5">Blue</li> */}
                    </ul>
                    <img className="logo-image" src={Logo} alt="Super Trends" />
                    {/* <button onClick={togglePlayPause} className="media-btn"><img className="play" src={Play} alt="Play" /><img className="pause" src={Pause} alt="Pause" /></button> */}
                    <div className="fullscreen-btn-container">
                        <button className="btn-fullscreen" onClick={togglePlayPause}><img className="play-icon" src={Play} alt="Play" /><img className="pause-icon" src={Pause} alt="Pause" /></button>
                        <span ref={timeElapsed} >00:00</span>
                        <input ref={scrubber} type="range" step={100} min={0} max={((events.length - 1) * 3 + 10) * 1000} />
                        <span ref={timeRemaining}>00:00</span>
                        <button className="btn-fullscreen" onClick={toggleFullscreen}><img src={fsButton} alt="Full Screen" /></button>
                        {/* <button className="btn-fullscreen" onClick={toggleFullscreen}><img src={fsButton} alt="Full Screen" /><span>Full
                            Screen</span></button> */}
                    </div>
                    <div className="overlay-layer" onClick={togglePlayPause}></div>
                    <button onClick={() => handleTagsShow()} className="tags-toggler" type="button"><FontAwesomeIcon icon={faGears} /></button>
                    <Modal show={tagsShow} onHide={handleTagsClose} size="xl" centered className="tags-modal">
                        <Tags tags={tags ? tags.filter(el => el.name.includes("Supertrend:")) : []} selectedTags={selectedTags} handleSubmit={initTimeline} handleClose={handleTagsClose} startYear={startYear} endYear={endYear} />
                    </Modal>
                </div>


                <p className="warning-msg">Best view is available in Landscape mode</p>
            </div>
        </div>
    )
}

export default Flyover;