import React, { useContext, useState } from "react";
import { Card, Form, Button, Spinner, Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { authContext } from "../contexts/AuthContext";
import { RESET_PASSWORD } from "../graphql/mutations";
import bgLogin from '../assets/images/bg-body-login.svg';
import Logo from '../assets/images/logo.png';

const ResetPassword = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { getClient } = useContext(authContext);
    const [loading, setLoading] = useState(false)

    let client = getClient();
    let navigate = useNavigate()

    const onSubmit = (data) => {
        setLoading(true);
        client.mutate({
            mutation: RESET_PASSWORD,
            variables: {
                email: data.email,
            }
        }).then(res => {
            setLoading(false);
            toast.success(res.data.requestPasswordReset, {
                position: toast.POSITION.TOP_RIGHT,

            });
            navigate("/")
        }).catch(err => {
            setLoading(false);
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,

            });
        })
    }


    return (
        <Container fluid style={{ backgroundImage: `url(${bgLogin})`, backgroundAttachment: 'scroll', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: '50% 100%' }}>
            <Row className="justify-content-center align-items-center mh-100vh login-area">
                <Col md={8} lg={6} xl={4} xxl={3}>
                    <div className="logo"><img src={Logo} alt="Supertrends" /></div>
                    <Card className="border-0 shadow-lg">
                        <Card.Header className="p-0 border-0 bg-transparent">
                            Reset Password
                        </Card.Header>
                        <Card.Body
                            className="p-0">
                            <Form
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Form.Group
                                    className="mb-4"
                                    controlId="formBasicEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        {...register("email", { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                                        className={`${errors.email ? "border border-danger" : ""}`}
                                        type="text"
                                        placeholder="Enter email" />
                                    {errors.email?.type == "required" && <p className="mt-2 ml-2 fs-6 text-danger">** required</p>}
                                    {errors.email?.type == "pattern" && <p className="mt-2 ml-2 fs-6 text-danger">** invalid email</p>}

                                </Form.Group>
                                <Button
                                    variant="primary"
                                    type="submit">
                                    {!loading ? "Reset" : <Spinner animation="grow" size="sm" variant="light" />}
                                </Button>
                            </Form>
                            <p
                                className="text-center mt-4 mb-0 register-note">
                                Back to &nbsp;
                                <Link
                                    className="text-decoration-none p-0 m-0"
                                    to="/login">
                                    Login
                                </Link></p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default ResetPassword;
