import { Alert } from 'react-bootstrap';
import { dataContext } from 'contexts/DataContext';
import React, { useContext } from 'react';
import UserImage from '../../assets/images/user-img.jpg';
import './followuser.scss';

const FollowUser=(props)=>{

    const {type} = props;
    const {data} = useContext(dataContext);

    let usersData = type == "FOLLOWERS" ? data.followersConnection?.nodes ?? [] : type == "FOLLOWING" ? data.followingConnection?.nodes ?? [] : data.blockedUsers; 
    
    if(usersData.length < 1){
        return(
            <Alert variant="dark">{
                type == "FOLLOWERS" ? "You don't have any followers" : type == "FOLLOWING" ? "You are not following anyone" : "You haven't blocked any users"
            }</Alert>
        )
    }
    return(
        <>
            { usersData.map(user=>{
                return (

                    <div className='mx-md-5 bg-white follow'>
                        <div className='image-holder'><img src={user.avatar.url} alt={user.firstName+" "+user.lastName} /></div>
                        <div>
                            <h3>{user.firstName+" "+user.lastName}</h3>
                            <p>Followers ({user.followersConnection.totalCount})</p>
                        </div>
                    </div>
                );
            })}
        </>
    );
}

export default FollowUser;