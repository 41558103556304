import TabsSidebar from 'components/TabsSidebar/TabsSidebar';
import React, { useContext } from 'react';
import {  Col, Container, Row, Tab } from 'react-bootstrap';
import './bookmarks.scss';
import BookmarkedEvents from 'components/BookmarkedEvents/BookmarkedEvents';
import BookmarkedArticles from 'components/BookmarkedArticles/BookmarkedArticles';
import BookmarkedCompanies from 'components/BookmarkedCompanies/BookmarkedCompanies';
import { authContext } from 'contexts/AuthContext';
import { DELETE_BOOKMARK } from 'graphql/mutations';

const Bookmarks=()=>{

    const { getClient } = useContext(authContext);

    const client = getClient();

    const deleteBookmark = (id) => {
        return client.mutate({
            mutation: DELETE_BOOKMARK,
            variables:{
                id: id
            }
        });
    }

    const sidebarData=[
        {
            title: "Future Milestones",
            event: "bookmarkMilestones"
        },
        {
            title: "Articles",
            event: "bookmarkArticles"
        },
        {
            title: "Companies",
            event: "bookmarkCompanies"
        }
    ]

    return(
        <Tab.Container id="left-tabs-example" defaultActiveKey="bookmarkMilestones">
            <Container className='my-account'>
                <Row>
                    <Col sm={3}>
                        <TabsSidebar sidebarOptions={sidebarData} title="Bookmarks" />
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="bookmarkMilestones">
                                <div className='px-lg-5 milestones-tab'>
                                    <BookmarkedEvents onDelete={deleteBookmark}/>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="bookmarkArticles">
                                <div className='px-lg-5 milestones-tab articles-tab'>
                                    <BookmarkedArticles onDelete={deleteBookmark}/>                                    
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="bookmarkCompanies">
                                <div className='px-lg-5 milestones-tab companies-tab'>
                                    <BookmarkedCompanies onDelete={deleteBookmark}/>                                    
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Container>
        </Tab.Container>
    );
}
export default Bookmarks;