import { toast } from "react-toastify";

export function GRAPHQL_ERROR(err) {
    return err.graphQLErrors.forEach(el => {
        el.extensions.details.forEach((error) => {
            toast.error(error , {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    });
}