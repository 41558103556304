import React, { createContext, useEffect, useState } from 'react';

export const dataContext = createContext({});

const DataProvider = ({ children }) => {
    const [ registerUserData , setRegisterUserData ] = useState(null)
    const [ tags , setTags ] = useState(null)
    const [ data , setData ] = useState(null)

    return (
        <dataContext.Provider value={{ registerUserData , setRegisterUserData , data, setData , tags , setTags }}>
            {children}
        </dataContext.Provider>
    );
};

export default DataProvider;