import React, { createContext, useEffect, useState } from 'react';
import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { GRAPHQL_PASS, GRAPHQL_URL, GRAPHQL_USER } from '../utils/constants/constants';
import { REFRESH_TOKEN } from 'graphql/mutations';
import { createLink } from 'apollo-absinthe-upload-link';

export const authContext = createContext({});

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({ loading: true, data: null });

    const httpLink = createHttpLink({
        uri: GRAPHQL_URL
    });

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        let data = JSON.parse(window.localStorage.getItem('authData'))

        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: 'Basic ' + btoa(`${GRAPHQL_USER}:${GRAPHQL_PASS}`),
                "X-user-auth": data != null && data.accessToken ? `Bearer ${data.accessToken}` : "",
            }
        }
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({ code, message }) => {
                if (code == "auth_token_invalid") {
                    getRefreshToken().then(data => {
                        let newAuth = {
                            loading: false,
                            data: { ...auth.data }
                        };

                        newAuth.data.accessToken = data.data.exchangeRefresh.accessToken;
                        newAuth.data.refreshToken = data.data.exchangeRefresh.refreshToken;

                        setAuth({ ...newAuth });

                    }).catch(err => {
                        setAuth({ loading: false, data: null });
                        console.log(err)
                    });
                }
            })
        }
    })

    const getRefreshToken = () => {
        var refClient = new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache(),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore',
                },
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
            }
        });

        return refClient.mutate({
            mutation: REFRESH_TOKEN,
            variables: {
                refreshToken: auth?.data?.refreshToken
            }
        })

    }

    const getClient = (upload=false) => {
        let link;
        if(upload){
            link = createLink({
                uri: GRAPHQL_URL
            })
        }else{
            link = httpLink
        }

        return new ApolloClient({
            link: from([authLink, errorLink, link]),//authLink.concat(httpLink),
            cache: new InMemoryCache(),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore',
                },
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
            }
        });
    }

    const setAuthData = (data) => {
        setAuth({ data: data });
    };
    // a function that will help us to add the user data in the auth;

    useEffect(() => {
        setAuth({ loading: false, data: JSON.parse(window.localStorage.getItem('authData')) });
    }, []);
    //2. if object with key 'authData' exists in localStorage, we are putting its value in auth.data and we set loading to false. 
    //This function will be executed every time component is mounted (every time the user refresh the page);

    useEffect(() => {
        window.localStorage.setItem('authData', JSON.stringify(auth.data))
    }, [auth.data]);
    // 1. when **auth.data** changes we are setting **auth.data** in localStorage with the key 'authData'.

    return (
        <authContext.Provider value={{ auth, setAuthData, getClient }}>
            {children}
        </authContext.Provider>
    );
};

export default AuthProvider;