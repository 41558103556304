import React from 'react';
import { Button, Card } from 'react-bootstrap';
import './user.scss';
import { Link } from 'react-router-dom';

const User = (props)=>{
    const { data } = props;

    return(
        <Card className='user-card'>
            <div className='d-flex flex-wrap align-items-center p-4 details-area'>
                <div className='image-holder'><img src={data.avatar.url} alt={data.firstName} /></div>
                <div className='content-holder'>
                    <h2>{data.firstName} {data.lastName}</h2>
                    <p>Followers ({data.followersConnection.totalCount})</p>
                </div>
            </div>
            <div className='btn-holder p-4'>
                <Button variant='primary' as={Link} to={"../users/" + data.id}>Learn More</Button>
            </div>
        </Card>
    );
}

export default User;