import { authContext } from 'contexts/AuthContext';
import { UPDATE_IMPACT } from 'graphql/mutations';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import './importancerange.scss';

const ImportanceRange = (props) => {
    const [stats , setStats] = useState(null)
    const { getClient } = useContext(authContext);
    let client = getClient();
    const defaultValues = {
        "LITTLE": 1,
        "MEDIUM": 2,
        "STRONG": 3
    }

    const updateImpact = (i) => {
        let options = {
            0: null,
            1: "LITTLE",
            2: "MEDIUM",
            3: "STRONG"
        }

        client.mutate({
            mutation: UPDATE_IMPACT,
            variables: {
                id: props.eventId,
                impactKey: props.eventKey,
                impactValue: options[i]
            }
        }).then(res => {
            setStats(res.data.updateImpact.statistics)
            // toast.success("Impact Updated!", {
            //     position: toast.POSITION.TOP_RIGHT,
            // });
        }).catch(err => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }

    useEffect(() => {
        setStats(props.stats)
    },[])

    return (
        <Form className="opinion-form mb-4">
            <header>
                <h2>{props.heading}</h2>
                <p>What people think:</p>
            </header>
            <div className="opinion-range d-flex flex-wrap justify-content-between align-items-center">
                <Form.Check
                    inline
                    type="radio"
                >
                    <Form.Check.Input defaultChecked={props.userValue == null} type="radio" name={props.eventKey} onChange={() => updateImpact(0)} id={props.heading.replace(" ","") + "_no_" + props.eventId} />
                    <Form.Check.Label for={props.heading.replace(" ","") + "_no_" + props.eventId}>No Opinion</Form.Check.Label>
                    {/* <span className='value'>25%</span> */}
                </Form.Check>
                <Form.Check
                    inline
                    type="radio"
                >
                    <Form.Check.Input defaultChecked={props.userValue == "LITTLE"}  type="radio" name={props.eventKey} onChange={() => updateImpact(1)} id={props.heading.replace(" ","") + "_small_" + props.eventId} />
                    <Form.Check.Label for={props.heading.replace(" ","") + "_small_" + props.eventId}>Small</Form.Check.Label>
                    <span className='value'>{stats?.littlePercentage ? stats.littlePercentage.toFixed(0) : "0"}%</span>
                    </Form.Check>
                <Form.Check
                    inline
                    type="radio"
                >
                    <Form.Check.Input type="radio" defaultChecked={props.userValue == "MEDIUM"} name={props.eventKey} onChange={() => updateImpact(2)} id={props.heading.replace(" ","") + "_medium_" + props.eventId} />
                    <Form.Check.Label for={props.heading.replace(" ","") + "_medium_" + props.eventId}>Medium</Form.Check.Label>
                    <span className='value'>{stats?.mediumPercentage ? stats.mediumPercentage.toFixed(0) : "0"}%</span>
                    </Form.Check>
                <Form.Check
                    inline
                    type="radio"
                >
                    <Form.Check.Input type="radio"  defaultChecked={props.userValue == "STRONG"} name={props.eventKey} onChange={() => updateImpact(3)} id={props.heading.replace(" ","") + "_large_" + props.eventId} />
                    <Form.Check.Label for={props.heading.replace(" ","") + "_large_" + props.eventId}>Large</Form.Check.Label>
                    <span className='value'>{stats?.strongPercentage ? stats.strongPercentage.toFixed(0) : "0"}%</span>
                    </Form.Check>
            </div>
        </Form>
    );
}

export default ImportanceRange;