import TrendingCompany from 'components/trendingCompany/TrendingCompany';
import { authContext } from 'contexts/AuthContext';
import { COMPANIES } from 'graphql/queries';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import './companies.scss';

const Companies = () => {
    const [data, setData] = useState(null)
    const { getClient } = useContext(authContext);
    let client = getClient();

    useEffect(() => {
        client.query({
            query: COMPANIES
        }).then(res => {
            setData(res.data.companiesConnection.nodes)
        }).catch(err => {
            console.log(err.message)
        })
    }, [])

    const MyLoader = (props) => (
        <ContentLoader
            speed={2}
            width="100%"
            height="32vh"
            viewBox="0 0 100% 1080"
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
            {...props}
        >
            <rect x="36%" y="4vh" rx="0" ry="0" width="4.625rem" height="3.625rem" />
            <rect x="0" y="15vh" rx="0" ry="0" width="80%" height="26" />
            <rect x="0" y="21vh" rx="0" ry="0" width="90%" height="20" />
            <rect x="0" y="24vh" rx="0" ry="0" width="50%" height="20" />
            <rect x="0" y="28vh" rx=".25rem" ry=".25rem" width="100%" height="32" />

        </ContentLoader>
    )

    const TabLoader = (props) => (
        <ContentLoader
            speed={2}
            width="100%"
            height="30vh"
            viewBox="0 0 100% 1080"
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
            {...props}
        >
            <rect x="32%" y="3.2vh" rx="0" ry="0" width="4.625rem" height="3.625rem" />
            <rect x="0" y="14vh" rx="0" ry="0" width="80%" height="20" />
            <rect x="0" y="20vh" rx="0" ry="0" width="90%" height="14" />
            <rect x="0" y="23vh" rx="0" ry="0" width="50%" height="14" />
            <rect x="0" y="26vh" rx=".25rem" ry=".25rem" width="100%" height="32" />

        </ContentLoader>
    )

    const MobLoader = (props) => (
        <ContentLoader
            speed={2}
            width="100%"
            height="35vh"
            viewBox="0 0 100% 1080"
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
            {...props}
        >
            <rect x="30%" y="2.3vh" rx="0" ry="0" width="4.625rem" height="3.625rem" />
            <rect x="0" y="14vh" rx="0" ry="0" width="80%" height="20" />
            <rect x="0" y="22vh" rx="0" ry="0" width="90%" height="14" />
            <rect x="0" y="26vh" rx="0" ry="0" width="50%" height="14" />
            <rect x="0" y="30vh" rx=".25rem" ry=".25rem" width="100%" height="32" />

        </ContentLoader>
    )

    return (
        <Container fluid>
            <Row className='py-4 py-md-5'>
                <Col>
                    <h1>Trending Companies</h1>
                </Col>
            </Row>
            <Row>
                <div className="companies-grid pb-5">
                    {data ? data.map((el, i) => {
                        return (
                            <TrendingCompany key={i} data={el} />
                        )
                    }) :
                        new Array(12).fill(0).map(el =>
                            <div className='column companies-loader'>
                                <div className='image-holder'></div>
                                <div className='position-relative zIndex-2 loader-area d-none d-lg-block'>
                                    <MyLoader />
                                </div>
                                <div className='position-relative zIndex-2 loader-area d-none d-md-block d-lg-none'>
                                    <TabLoader />
                                </div>
                                <div className='position-relative zIndex-2 loader-area d-md-none'>
                                    <MobLoader />
                                </div>
                            </div>)}
                </div>
            </Row>
        </Container>

    );
}

export default Companies;