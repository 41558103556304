import "./timeline.scss";
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from 'contexts/AuthContext';
import { EVENTS_BY_YEAR, TIMELINE } from 'graphql/queries';
import { toast } from 'react-toastify';
import Milestones from 'components/milestones/Milestones';
import { Button, Col, Container, Row } from 'react-bootstrap';
import iconBar from '../../assets/images/ico-bar.svg';
import iconSlide from '../../assets/images/ico-slide.svg';
import iconNum from '../../assets/images/ico-num.svg';
import iconShare from '../../assets/images/ico-share.svg';
import iconBarHover from '../../assets/images/ico-bar-hover.svg';
import iconSlideHover from '../../assets/images/ico-slide-hover.svg';
import iconNumHover from '../../assets/images/ico-num-hover.svg';
import iconShareHover from '../../assets/images/ico-share-hover.svg';
import LightspeedTimeline from 'components/lightspeedTimeline/LightspeedTimeline';
import LudicrousTimeLine from 'components/ludicrousTimeLine/LudicrousTimeLine';
import moment from 'moment';
import RidiculousTimeline from 'components/ridiculousTimeline/RidiculousTimeline';
import ContentLoader from "react-content-loader";

const Timeline = () => {
    const [timelineData, setTimelineData] = useState(null)
    const [selectedYear, setSelectedYear] = useState(null)
    const [variant, setVariant] = useState("Ridiculous")
    const [events, setEvents] = useState(null)
    const { getClient } = useContext(authContext);
    let client = getClient();

    useEffect(() => {
        if (selectedYear != null) {
            client.query({
                query: EVENTS_BY_YEAR,
                variables: {
                    year: selectedYear
                }
            }).then(res => {
                setEvents(res.data.eventsConnection.nodes)
            }).catch(err => {
                toast.error(err.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
        }
    }, [selectedYear])

    useEffect(() => {
        client.query({
            query: TIMELINE
        }).then(res => {
            if (res.data.viewer.timeline.years[0].year == -1) {
                let tempData = res.data.viewer.timeline.years[0];
                res.data.viewer.timeline.years.shift();
                res.data.viewer.timeline.years.push(tempData)
            }
            setTimelineData(res.data.viewer.timeline.years)
            if (res.data.viewer.timeline.years.some(el => el.year == moment().year())) {
                setSelectedYear(moment().year())
            }
            else {
                setSelectedYear(moment().add(1, 'y').year())
            }
        }).catch(err => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            })
        })
    }, [variant])

    const handleSetSelectedYear = year => {
        setEvents(null);
        setSelectedYear(year);
    }

    const MyLoader = () => {
        return (
            <Row>
                {
                    Array(9).fill(0).map(el=>{
                        return(

                            <Col md={6} lg={4} className="mb-4">
                                <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0' }}>
                                    <ContentLoader viewBox="0 0 500 150" >
                                        <rect x="45%" y="38" rx="2" ry="2" width="234" height="12" />
                                        <rect x="0" y="0" rx="10" ry="10" width="40%" height="100%" />
                                        <rect x="45%" y="64" rx="2" ry="2" width="200" height="12" />
                                        <rect x="45%" y="91" rx="2" ry="2" width="160" height="12" />
                                    </ContentLoader>
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>
        )
    }

    return (
        <>
            {/* <h1>Timeline</h1> */}
            <Container fluid>
                <div className="timelineHolder pt-5">
                    <header className='d-flex flex-wrap justify-content-between align-items-center timelineHeader'>
                        <h1>{variant} Speed Timeline</h1>
                        <div className='btn-holder'>
                            <Button style={{ backgroundColor: variant == "Ludicrous" ? "#679CF6" : "#ffffff" }} onClick={() => setVariant("Ludicrous")} variant="link">
                                <img className='icon' src={iconBar} alt="Bar" />
                                <img className='icon-hover' src={iconBarHover} alt="Bar" />
                            </Button>
                            <Button style={{ backgroundColor: variant == "Ridiculous" ? "#679CF6" : "#ffffff" }} onClick={() => setVariant("Ridiculous")} variant="link">
                                <img className='icon' src={iconSlide} alt="Slider" />
                                <img className='icon-hover' src={iconSlideHover} alt="Slider" />
                            </Button>
                            <Button style={{ backgroundColor: variant == "Light" ? "#679CF6" : "#ffffff" }} onClick={() => setVariant("Light")} variant="link">
                                <img className='icon' src={iconNum} alt="Num" />
                                <img className='icon-hover' src={iconNumHover} alt="Num" />
                            </Button>
                            <Button variant="link">
                                <img className='icon' src={iconShare} alt="Share" />
                                <img className='icon-hover' src={iconShareHover} alt="Share" />
                            </Button>
                        </div>
                    </header>
                    {
                        timelineData ? (

                            variant == "Ridiculous" ? <RidiculousTimeline data={timelineData} selectedYear={selectedYear} handleSelectedYear={handleSetSelectedYear} />
                                : variant == "Light" ? <LightspeedTimeline data={timelineData} selectedYear={selectedYear} handleSelectedYear={handleSetSelectedYear} />
                                    : variant == "Ludicrous" ? <LudicrousTimeLine data={timelineData} selectedYear={selectedYear} handleSelectedYear={handleSetSelectedYear} /> :
                                        <div class="loading">
                                            <div class="loading-1"></div>
                                            <div class="loading-2"></div>
                                            <div class="loading-3"></div>
                                            <div class="loading-4"></div>
                                        </div>
                        ) : (

                            <div class="loading">
                                <div class="obj"></div>
                                <div class="obj"></div>
                                <div class="obj"></div>
                                <div class="obj"></div>
                                <div class="obj"></div>
                                <div class="obj"></div>
                                <div class="obj"></div>
                                <div class="obj"></div>
                                <div class="obj"></div>
                                <div class="obj"></div>
                                <div class="obj"></div>
                                <div class="obj"></div>
                                <div class="obj"></div>
                                <div class="obj"></div>
                                <div class="obj"></div>
                                <div class="obj"></div>
                            </div>
                        )
                    }
                </div>
                <div className="my-5">
                    {events ?
                        <Milestones heading={`Predicted for ${selectedYear ? selectedYear == -1 ? "Never" : selectedYear : ""}`} data={events} /> :
                        <MyLoader />}
                </div>
            </Container>
        </>
    );
}

export default Timeline; 