import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import FollowerModal from 'components/FollowerModal/FollowerModal';
import { USER } from 'graphql/queries';
import { authContext } from 'contexts/AuthContext';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import './userdetails.scss';
import { FOLLOW_USER, UNFOLLOW_USER } from 'graphql/mutations';
import FollowingModal from 'components/FollowingModal/FollowingModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';

const UserDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const { getClient } = useContext(authContext);
    let client = getClient();

    const followUser = () => {
        client.mutate({
            mutation: FOLLOW_USER,
            variables: {
                userID: data.id
            }
        }).then(res => {
            let tempData = { ...data };
            tempData.viewerIsFollowingUser = res.data.followUser.viewerIsFollowingUser;
            tempData.followersConnection.totalCount++;
            setData(tempData);
        }).catch(err => {
            console.log(err.message)
        })
    }

    const unFollowUser = () => {
        client.mutate({
            mutation: UNFOLLOW_USER,
            variables: {
                userID: data.id
            }
        }).then(res => {
            let tempData = { ...data };
            tempData.viewerIsFollowingUser = res.data.unfollowUser.viewerIsFollowingUser;
            tempData.followersConnection.totalCount--;
            setData(tempData);
        }).catch(err => {
            console.log(err.message)
        })
    }

    useEffect(() => {
        client.query({
            query: USER,
            variables: {
                userID: id
            }
        }).then(res => {
            setData(res.data.user)
        }).catch(err => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }, [id])

    return (
        <Container>
            <div style={{ maxWidth: "908px", margin: "0 auto", overflow: "hidden", padding: "1rem 0 0" }}>
                <Button className="btn-back float-left" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Go Back</Button>
            </div>
            <div className='user-details-holder'>
                {data ? <div className='user-details-frame'>
                    <div className='image-holder'><img src={data.avatar.url} alt="UserName" /></div>
                    <div className='details-area'>
                        <h2>{data.firstName} {data.lastName}</h2>
                        <p>{data.biographyBlock.plainText}</p>
                        <div className='d-flex justify-content-center align-items-center mb-4'>
                            <FollowerModal title="Followers" count={data.followersConnection.totalCount} />
                            <FollowingModal title="Following" count={data.followingConnection.totalCount} />
                            {/* <p className='p-0'>Followers ({data.followersConnection.totalCount})</p>
                            <p className='p-0'>Following ({data.followingConnection.totalCount})</p> */}
                        </div>
                        {data.viewerIsFollowingUser ?
                            <Button className='unfollow-btn ' variant='secondary' onClick={() => unFollowUser()}>Unfollow</Button>
                            : <Button className='follow-btn ' variant='primary' onClick={() => followUser()}>Follow</Button>}
                    </div>
                </div> : "Loading..."
                }
            </div>
        </Container>
    );
}

export default UserDetails;