import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import './companydetails.scss';
import CompanySlider from "components/company/CompanySlider";
import Milestones from 'components/milestones/Milestones';
import NewsFeed from 'components/newsFeed/NewsFeed';
import { useNavigate, useParams } from 'react-router-dom';
import { authContext } from 'contexts/AuthContext';
import { COMPANY } from 'graphql/queries';
import Reactions from 'components/reactions/Reactions';
import placeholder from "assets/images/placeholder.png"
import ContentLoader from 'react-content-loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';

const CompanyDetails = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [data, setData] = useState(null)
    const { getClient } = useContext(authContext);
    let client = getClient();

    useEffect(() => {
        client.query({
            query: COMPANY,
            variables: {
                id: id
            }
        }).then(res => {
            setData(res.data.company)
        }).catch(err => {
            console.log(err.message)
        })
    }, [id])

    const MyLoader = (props) => (
        <ContentLoader
            speed={2}
            width="100%"
            height="1080"
            viewBox="0 0 100% 1080"
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
            {...props}
        >
            <rect x="0" y="0" rx="1.275rem" ry="1.275rem" width="100%" height="50vh" />
            <rect x="0" y="54vh" rx="0" ry="0" width="40%" height="40" />
            <rect x="0" y="62vh" rx="0" ry="0" width="100%" height="20" />
            <rect x="0" y="66vh" rx="0" ry="0" width="100%" height="20" />
            <rect x="0" y="70vh" rx="0" ry="0" width="100%" height="20" />
            <rect x="0" y="74vh" rx="0" ry="0" width="50%" height="20" />
            <rect x="0" y="78vh" rx="0" ry="0" width="100%" height="20" />
            <rect x="0" y="82vh" rx="0" ry="0" width="100%" height="20" />
            <rect x="0" y="86vh" rx="0" ry="0" width="100%" height="20" />
            <rect x="0" y="90vh" rx="0" ry="0" width="100%" height="20" />
            <rect x="0" y="94vh" rx="0" ry="0" width="30%" height="20" />
        </ContentLoader>
    )

    return (
        <Container fluid className='py-5'>
            <div className='company-details-holder'>
                <Button className="btn-back" onClick={ ()=> navigate(-1) }><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Go Back</Button>
            </div>
            {data ?
                <div className='company-details-holder'>
                    <div className="img-holder">
                        <img className="articleImg mx-auto" src={data?.featuredImage?.originalUrl ? data?.featuredImage?.originalUrl : placeholder} alt="" />
                    </div>
                    <header>
                        <h1>{data?.name}</h1>
                        <p>{data?.descriptionBlock?.plainText}</p>
                    </header>
                    <ul className='details-area'>
                        {data?.tableEntries && data?.tableEntries.map((el, i) =>
                            <li key={i}>
                                <span>{el.key}</span>
                                <span>{el.value}</span>
                            </li>
                        )}
                    </ul>
                    <hr />
                    <Row className="articleNavigation align-items-center">
                        <Col className="d-flex justify-content-start">
                            {/* <p className="d-flex align-items-center m-0"><img src={IconArrowBack} alt="" />&nbsp; Back</p> */}
                        </Col>
                        <Col className="d-flex justify-content-center">
                            <Reactions bookmark gap="gap-3" width="1.25rem" height="1.25rem" id={id} rated={data?.rated} bookmarked={data?.viewerHasBookmarked} />
                        </Col>
                        <Col className="d-flex justify-content-end">
                            {/* <p className="d-flex align-items-center m-0">Next Article &nbsp;<img src={Rocket} alt="" /></p> */}
                        </Col>
                    </Row>
                    <hr />
                    <Row className='mt-5'>
                        <Milestones cols={6} heading={"Related Milestones"} data={data?.related?.events} />
                    </Row>
                    <Row className='mt-5'>
                        <NewsFeed cols={4} heading={"Related Articles"} data={data?.related?.articles} />
                    </Row>
                    <Row>
                        <CompanySlider data={data?.related?.companies} />
                    </Row>
                </div>
                :
                <div className='company-details-holder'>
                    <MyLoader />
                </div>}
        </Container>
    );
}

export default CompanyDetails;