import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Card, Spinner, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { useForm } from "react-hook-form";
import { LOGIN } from "graphql/mutations";
import { authContext } from "contexts/AuthContext";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import bgLogin from 'assets/images/bg-body-login.svg';
import Logo from 'assets/images/logo.png';
import { dataContext } from "contexts/DataContext";

const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { getClient, auth, setAuthData } = useContext(authContext);
    const { setData } = useContext(dataContext);
    const [passwordShow, setPasswordShow] = useState(false);
    const [loading, setLoading] = useState(false);
    let client = getClient();
    let navigate = useNavigate();

    const showPassword = () => {
        setPasswordShow(!passwordShow);
    }

    const onSubmit = (data) => {
        setLoading(true);
        client.mutate({
            mutation: LOGIN,
            variables: {
                email: data.email,
                password: data.password
            }
        }).then(res => {
            setLoading(false);
            toast.success("Logged in!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setAuthData({
                accessToken: res.data.login.accessToken,
                refreshToken: res.data.login.refreshToken,
                email: res.data.login.viewer.email,
                id: res.data.login.viewer.id
            })
            setData(res.data.login.viewer)
            navigate("/");
        }).catch(err => {
            setLoading(false);
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }

    useEffect(() => {
        if(auth.data != null){
            navigate("/")
        }
    }, [auth.data])

    return (
        <>
            <Container fluid style={{ backgroundImage: `url(${bgLogin})`, backgroundAttachment: 'scroll', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: '50% 100%' }}>
                <Row className="justify-content-center align-items-center mh-100vh login-area">
                    <Col md={8} lg={6} xl={4} xxl={3}>
                        <div className="logo"><img src={Logo} alt="Supertrends" /></div>
                        <Card className="border-0 shadow-lg">
                            <Card.Header
                                className="p-0 border-0 bg-transparent">
                                Login
                            </Card.Header>
                            <Card.Body
                                className="p-0">
                                <Form
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <Form.Group
                                        className="mb-4" 
                                        controlId="formBasicEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            {...register("email", { required: true , pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                                            className={`${errors.email ? "border border-danger" : ""}`}
                                            type="text"
                                            placeholder="Enter email" />
                                        {errors.email?.type == "required" && <p className="mt-2 ml-2 fs-6 text-danger">** required</p>}
                                        {errors.email?.type == "pattern" && <p className="mt-2 ml-2 fs-6 text-danger">** invalid email</p>}
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-4 mb-md-2 position-relative"
                                        controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            {...register("password", { required: true })}
                                            className={`${errors.password ? "border border-danger " : ""}`}
                                            type={passwordShow ? 'text' : 'password'}
                                            placeholder="Password"
                                        />
                                        {errors.password?.type == 'required' && <p className="mt-2 ml-2 fs-6 text-danger">** required</p>}
                                        <Button
                                            onClick={showPassword}
                                            className={`text-decoration-none text-color-default p-0 m-0 position-absolute ${errors.password ? 'top-50' : 'bottom-0'} end-0 translate-middle`}
                                            variant="link"
                                            type="button">
                                            {passwordShow ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                                        </Button>
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-4 d-flex flex-wrap justify-content-between align-items-center"
                                        controlId="formBasicCheckbox">
                                        <Form.Check className="flex-wrap align-items-center d-flex"
                                            type="checkbox"
                                            label="Remember me" />
                                        <Link
                                            to="/resetpassword"
                                            className="text-decoration-none text-color-default p-0 m-0 forgot-pw"
                                        >Forgot Password ?</Link>
                                    </Form.Group>
                                    <Button
                                        disabled={loading}
                                        variant="primary"
                                        type="submit">
                                        {!loading ? "Login" : <Spinner animation="grow" size="sm" variant="light" />}
                                    </Button>
                                    <p className="text-center mt-4 mb-0 register-note">
                                        Don&#8217;t have an Account?&nbsp;
                                        <Link
                                            className="text-decoration-none p-0 m-0"
                                            to="/register">
                                            Register here
                                        </Link>
                                    </p>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Login;
