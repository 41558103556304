import "./timelineslider.scss";
import 'swiper/css/navigation';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Button } from 'react-bootstrap';
import icoStopWatch from '../../assets/images/ico-stopwatch.svg';
import RidiculousSlide from 'components/ridiculousSlide/RidiculousSlide';

const RidiculousTimeline = (props) => {
    const {
        data,
        selectedYear,
        handleSelectedYear
    } = props;
    const [max , setMax] = useState(null)
    const [swiper , setSwiper ] = useState({})

    const swiperSettings = {
        slidesPerView: 6,
        autoplay: true,
        centeredSlides: true,
        spaceBetween: 16,
        breakpoints: {
            768: {
                slidesPerView: 10
            },
            1024: {
                slidesPerView: 14
            },
            1200: {
                slidesPerView: 16
            },
            1360: {
                slidesPerView: 18
            },
            1600: {
                slidesPerView: 20
            },
            1920: {
                slidesPerView: 22
            }
        }
    }

    const handleClick = (year , i) => {
        handleSelectedYear(year);
        swiper.slideTo(i);
    }

    const handleSlideChange = (i) => {
        handleSelectedYear(data[i].year)
    }

    useEffect(() => {
        if(data != null){
            let maxCount = data[0].unlockedEventsCount;
            data.forEach(el => {
                if(el.unlockedEventsCount > maxCount){
                    maxCount = el.unlockedEventsCount
                }
            });
            setMax(maxCount)
        }
    }, [data]) // finding max events

    useEffect(() => {
        if(data != null && swiper?.slideTo && selectedYear){
            swiper.slideTo(data?.findIndex(el => el.year == selectedYear))
        }
    },[swiper , selectedYear])


    return (
        <>
        <Swiper
            onInit={(ev) => {setSwiper(ev)}}
            onSlideChange={(e) => handleSlideChange(e.activeIndex)}
            className='rediculous-timeline'
            {...swiperSettings}
        >
            {data && data.map((el,i) => {
                return (
                    <SwiperSlide key={i} onClick={() => handleClick(el.year , i)} >
                        <RidiculousSlide data={el} max={max} />
                    </SwiperSlide>
                )
            })}
        </Swiper>
        <div className='nav-btns'>
            <Button onClick={() => swiper.slidePrev()} variant='link'><img className='me-2' src={icoStopWatch} alt="Prev" /> TRAVEL TO {data && data[swiper?.activeIndex - 1] && data[swiper?.activeIndex - 1]?.year}</Button>
            <Button onClick={() => swiper.slideNext()} variant='link'>TRAVEL TO {data && data[swiper?.activeIndex + 1] && data[swiper?.activeIndex + 1]?.year} <img className='ms-2' src={icoStopWatch} alt="Prev" /></Button>
        </div>
        </>
    );
}

export default RidiculousTimeline;