import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AuthProvider from 'contexts/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import DataProvider from 'contexts/DataContext';

ReactDOM.render(
  <React.StrictMode>
      <AuthProvider>
        <DataProvider>
          <App />
        </DataProvider>
      </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
