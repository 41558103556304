import RegisterTags from 'pages/RegisterTags';
import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import './interestsmodal.scss';

const InterestsModal=(props)=>{
    const modalRef = useRef({});

    useEffect(() => {
        console.log(modalRef.current)
    } , [modalRef.current])

    return(
        <Modal
            ref={modalRef}
            show={true}
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='interests-modal'
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Change your interests</Modal.Title>
                <p>Recalibrate recommendation system to make it purpose milestones and articles according to your interests.</p>
            </Modal.Header>
            <Modal.Body>
                <p>Choose at least one new interest:</p>
                <RegisterTags standalone setModalShow={props.setModalShow} userInterests />
            </Modal.Body>
        </Modal>
    );
}

export default InterestsModal;