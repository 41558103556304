import React from "react";
import { Col, Row } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';

const NewsfeedLoader = () => {
    return (
        <>
            <Row className="latest-newsfeeds mb-5">
                <Col md={4} lg={3} xl={2}>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0.75rem' }}>
                        <ContentLoader viewBox="0 0 500 500" width="100%">
                            <rect x="5" y="17" rx="0" ry="0" width="100%" height="300" />
                            <rect x="20" y="350" rx="2" ry="2" width="90%" height="30" />
                            <rect x="20" y="400" rx="2" ry="2" width="90%" height="30" />
                            <circle cx="35" cy="470" r="20" />
                            <rect x="69" y="462" rx="2" ry="2" width="50%" height="20" />
                        </ContentLoader>
                    </div>
                </Col>
                <Col md={4} lg={3} xl={2}>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0.75rem' }}>
                        <ContentLoader viewBox="0 0 500 500" width="100%">
                            <rect x="5" y="17" rx="0" ry="0" width="100%" height="300" />
                            <rect x="20" y="350" rx="2" ry="2" width="90%" height="30" />
                            <rect x="20" y="400" rx="2" ry="2" width="90%" height="30" />
                            <circle cx="35" cy="470" r="20" />
                            <rect x="69" y="462" rx="2" ry="2" width="50%" height="20" />
                        </ContentLoader>
                    </div>
                </Col>
                <Col md={4} lg={3} xl={2}>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0.75rem' }}>
                        <ContentLoader viewBox="0 0 500 500" width="100%">
                            <rect x="5" y="17" rx="0" ry="0" width="100%" height="300" />
                            <rect x="20" y="350" rx="2" ry="2" width="90%" height="30" />
                            <rect x="20" y="400" rx="2" ry="2" width="90%" height="30" />
                            <circle cx="35" cy="470" r="20" />
                            <rect x="69" y="462" rx="2" ry="2" width="50%" height="20" />
                        </ContentLoader>
                    </div>
                </Col>
                <Col md={4} lg={3} xl={2}>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0.75rem' }}>
                        <ContentLoader viewBox="0 0 500 500" width="100%">
                            <rect x="5" y="17" rx="0" ry="0" width="100%" height="300" />
                            <rect x="20" y="350" rx="2" ry="2" width="90%" height="30" />
                            <rect x="20" y="400" rx="2" ry="2" width="90%" height="30" />
                            <circle cx="35" cy="470" r="20" />
                            <rect x="69" y="462" rx="2" ry="2" width="50%" height="20" />
                        </ContentLoader>
                    </div>
                </Col>
                <Col md={4} lg={3} xl={2}>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0.75rem' }}>
                        <ContentLoader viewBox="0 0 500 500" width="100%">
                            <rect x="5" y="17" rx="0" ry="0" width="100%" height="300" />
                            <rect x="20" y="350" rx="2" ry="2" width="90%" height="30" />
                            <rect x="20" y="400" rx="2" ry="2" width="90%" height="30" />
                            <circle cx="35" cy="470" r="20" />
                            <rect x="69" y="462" rx="2" ry="2" width="50%" height="20" />
                        </ContentLoader>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default NewsfeedLoader;
