import { useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Button, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Logo from 'assets/images/logo.png';
import { authContext } from 'contexts/AuthContext';
import { dataContext } from 'contexts/DataContext';
import { REGISTER, UPDATE_INTERESTS } from 'graphql/mutations';
import { TAGS } from 'graphql/queries';
import { GRAPHQL_ERROR } from 'utils/helpers/helpers';

const RegisterTags = (props) => {
    const { getClient } = useContext(authContext);
    const { registerUserData, data, setData, tags , setTags, setRegisterUserData } = useContext(dataContext);
    const [loader, setLoader] = useState(true);
    const [selectedTags, setSelectedTags] = useState([])
    let client = getClient();
    let navigate = useNavigate();

    const handleSelectedTags = (target, i) => {
        if (target.checked) {
            let tempTags = selectedTags;
            tempTags.push(target.value)
            setSelectedTags(tempTags);
        }
        else {
            let tempTags = selectedTags.filter(el => el != target.value);
            setSelectedTags(tempTags);
        }
    }

    const registerUser = () => {
        client.mutate({
            mutation: REGISTER,
            variables: {
                mergeAnonymousProfile: false,
                input: {
                    firstName: registerUserData.firstName,
                    lastName: registerUserData.lastName,
                    email: registerUserData.email,
                    credential: {
                        password: registerUserData.password,
                        passwordConfirmation: registerUserData.password,
                    }
                },
                tagIDs: selectedTags
            }
        }).then(res => {
            setLoader(false);
            toast.success("User registered successfully!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setRegisterUserData(null)
            navigate("/login");
        }).catch(err => {
            setLoader(false);
            GRAPHQL_ERROR(err);
            navigate("/register")
        })
    }

    const updateUserInterest = () => {
        console.log("user interst")
        client.mutate({
            mutation: UPDATE_INTERESTS,
            variables: {
                tagIDs: selectedTags
            }
        }).then(res => {
            setLoader(false);
            let tempData = data;
            tempData.interests = res.data.resetInterests.interests;
            setData(tempData)
            toast.success("Interests Updated successfully!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            props.setModalShow(false);
        }).catch(err => {
            setLoader(false);
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }

    const submit = (e) => {
        e.preventDefault();
        setLoader(true);
        if (selectedTags.length < 1) {
            toast.error("Select atleast one tag!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setLoader(false);
        }
        else {
            props.userInterests ? updateUserInterest() : registerUser();
        }
    }

    useEffect(() => {
        console.log(registerUserData)
        if(registerUserData == null && !props.standalone){
            navigate("/register");
        }
        if(tags == null){
            client.query({
                query:TAGS
            }).then(res => {
                setTags(res.data.tags)
                setLoader(false)
            }).catch(err => {
                setLoader(false)
                console.log(err)
            })
        }
        else{
            setLoader(false)
        }
    }, [])

    return (
        <>
            <Container fluid>
                <Row className="justify-content-center align-items-center mh-100vh login-area">
                    <Col lg={10} xxl={7}>
                        <div className="logo"><img src={Logo} alt="Supertrends" /></div>
                        <div className='tags-header'>
                            <h2>Awesome that we get to know each other!</h2>
                            <p>You are only one step away from the app! Before we can start, we need to know what you are interested in so that we can adapt our content.</p>
                        </div>
                        <Form className='tags-form' onSubmit={submit}>
                            <h3>Choose atleast one Supertrend</h3>
                            <div className="mb-3 d-flex flex-wrap justify-content-center">
                                {!loader ? tags.filter(el => el.name.includes("Supertrend:")).map((el, index) => {
                                    return (
                                        <Form.Check
                                            key={el.id}
                                            inline
                                            label={el.name.substr(11)}
                                            value={el.id}
                                            onChange={(e) => handleSelectedTags(e.target, index)}
                                            name="tag"
                                            type="checkbox"
                                            id={"tag-" + el.id}
                                        />
                                    )
                                }) : <Spinner animation="grow" size="xl" variant="primary" />}
                            </div>
                            <div>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={loader}
                                >
                                    {!loader ? (props.userInterests ? "Update New Interests" : "Register") : <Spinner animation="grow" size="sm" variant="light" />}
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default RegisterTags;