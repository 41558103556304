import React, { useContext, useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import './expertdetails.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { authContext } from 'contexts/AuthContext';
import { USER } from 'graphql/queries';
import { toast } from 'react-toastify';
import placeholder from "assets/images/placeholder.png"
import ContentLoader from 'react-content-loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';

const ExpertDetails = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null)
    const { getClient } = useContext(authContext);
    let client = getClient();

    useEffect(() => {
        client.query({
            query: USER,
            variables: {
                userID: id
            }
        }).then(res => {
            setData(res.data.user)
        }).catch(err => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }, [])

    const MyLoader = (props) => (
        <ContentLoader
            speed={2}
            width="100%"
            height="1080"
            viewBox="0 0 100% 1080"
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
            {...props}
        >
            <rect x="0" y="0" rx="1.275rem" ry="1.275rem" width="100%" height="50vh" />
            <rect x="0" y="54vh" rx="0" ry="0" width="40%" height="40" />
            <rect x="0" y="62vh" rx="0" ry="0" width="100%" height="20" />
            <rect x="0" y="66vh" rx="0" ry="0" width="100%" height="20" />
            <rect x="0" y="70vh" rx="0" ry="0" width="100%" height="20" />
            <rect x="0" y="74vh" rx="0" ry="0" width="50%" height="20" />
            <rect x="0" y="78vh" rx="0" ry="0" width="100%" height="20" />
            <rect x="0" y="82vh" rx="0" ry="0" width="100%" height="20" />
            <rect x="0" y="86vh" rx="0" ry="0" width="100%" height="20" />
            <rect x="0" y="90vh" rx="0" ry="0" width="100%" height="20" />
            <rect x="0" y="94vh" rx="0" ry="0" width="30%" height="20" />
        </ContentLoader>
    )

    return (
        <Container fluid className='py-5'>
            <div className='company-details-holder expert-details-holder'>
                <Button className="btn-back" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Go Back</Button>
            </div>
            {data ?
                <div className='company-details-holder expert-details-holder'>
                    <div className="img-holder">
                        <img className="articleImg mx-auto" src={data?.avatar?.url ? data?.avatar?.url : placeholder} alt="" />
                    </div>
                    <header>
                        <h1>{data?.firstName} {data?.lastName}</h1>
                        <p>{data?.biographyBlock?.plainText}</p>
                    </header>
                </div>
                :
                <div className='company-details-holder expert-details-holder'>
                    <MyLoader />
                </div>
            }

        </Container>
    );
}

export default ExpertDetails;