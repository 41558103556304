import { useEffect, useRef } from "react";

export default function Card(props) {

    const output = useRef(null);
    const outputContainer = useRef(null);

    function resize_to_fit() {
        if (output.current == null || output.current == undefined) {
            return;
        }
         
        let fontSize = output.current.style.fontSize;
        output.current.style.fontSize = (parseFloat(fontSize) - 0.05 + "rem");

        if (output.current.clientHeight >= outputContainer.current.clientHeight) {
            resize_to_fit();
        }
    }

    useEffect(() => {

        resize_to_fit();
    }, [])

    return (
        <div className={`anim-content ${props.lane}`} style={{ animationDelay: props.delay }}>
            <div className="content-holder">
                <div className="image-holder"><img src={props.image} alt={props.title} /></div>
                <div className="content-frame">
                    <strong>{props.year}</strong>
                    <div className="outer" ref={outputContainer}>
                        <p ref={output} style={{ fontSize: "5rem" }} className="output" > {props.title}</p>
                    </div>
                </div>
            </div>
            <div className="circle"></div>
        </div>
    )
}