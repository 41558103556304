import React from 'react';
import { Nav } from 'react-bootstrap';
import './tabssidebar.scss';

const TabsSidebar=(props)=>{
    return(
        <div className='tab-sidebar'>
            <h2 className='mb-3 mb-md-5'>{props.title}</h2>
            <Nav variant="pills" className="justify-content-between flex-md-column">
                {props.sidebarOptions.map((item, index)=>
                    <Nav.Item key={index}>
                        <Nav.Link eventKey={item.event}>{item.title}</Nav.Link>
                    </Nav.Item>
                )}
            </Nav>
        </div>
    );
}

export default TabsSidebar;