import InterestsModal from 'components/InterestsModal/InterestsModal';
import { authContext } from 'contexts/AuthContext';
import { dataContext } from 'contexts/DataContext';
import { DELETE_INTEREST } from 'graphql/mutations';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import './interests.scss';

const Interests = () => {
    const { data, setData } = useContext(dataContext)
    const { getClient } = useContext(authContext)
    const [loading, setLoading] = useState(true);
    const [selectedTags, setSelectedTags] = useState(null)
    const [modalShow, setModalShow] = useState(false);
    let client = getClient();

    const handleSelectedTags = (id) => {
        client.query({
            query: DELETE_INTEREST,
            variables: {
                ids: id
            }
        }).then(res => {
            setSelectedTags(res.data.deleteInterests.interests);
            let tempData = data;
            tempData.interests = res.data.deleteInterests.interests;
            setData(tempData)
            toast.success("Interest Updated!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (data != null) {
            setSelectedTags(data?.interests)
            setLoading(false);
        }
    }, [data])

    return (
        <div className='md-mx-5 interests-section'>
            <div className='text-md-end mb-4 mb-md-5'>
                <Button variant="Link" className="btn-reset" onClick={() => setModalShow(true)}>Reset Interests</Button></div>
            <InterestsModal
                show={modalShow}
                setModalShow={setModalShow}
                onHide={() => setModalShow(false)}
            />
            <div>
                {!loading ? (selectedTags && selectedTags.length > 0 ? selectedTags.filter(el => el.name.includes("Supertrend:")).map(tag => {
                    return (
                        <div key={tag.id} className="form-check form-switch">
                            <label
                                className="form-check-label"
                                htmlFor={tag.id}
                            >
                                {tag.name.substr(11)}
                            </label>
                            <input
                                className="form-check-input"
                                onChange={(e) => handleSelectedTags(e.target.value)}
                                defaultChecked={true}
                                type="checkbox"
                                role="switch"
                                defaultValue={tag.id}
                                id={"interest-" + tag.id} />
                        </div>
                    )
                }) : <p>No Interests!</p>) : "Loading.."}
            </div>
            {/* <div className="form-check form-switch look-email">
                <label className="form-check-label" htmlFor="lookUpEmail">Let others look me up by email</label>
                <input className="form-check-input" type="checkbox" role="switch" id="lookUpEmail" />
            </div> */}
        </div>
    );
}

export default Interests;