import { Button, Modal, Dropdown, Spinner } from 'react-bootstrap';
import { useState, useContext } from 'react';
import { authContext } from 'contexts/AuthContext';
import { DELETE_COMMENT } from 'graphql/mutations';
import './deletecommentalertmodal.scss';
import { toast } from 'react-toastify';

const DeleteCommentAlertModal = (props) => {
    
    const { getClient } = useContext(authContext);

    const [loading, setLoading] = useState(false);
    
    const [modalShow, setModalShow] = useState(false);
    const handleModalShow = () => setModalShow(true);
    const handleModalClose = () => setModalShow(false);

    const client = getClient();

    const handleDelete = () => {
        setLoading(true);

        client.mutate({
            mutation: DELETE_COMMENT,
            variables:{
                id: props.resourceID,
            }
        }).then(res=>{
            setLoading(false);
            setModalShow(false);
            props?.onDeleteCallback?.();
            toast.success(`Comment deleted successfully`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }).catch(err=>{
            setLoading(false);
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }

    return (
        <>
            <Dropdown.Item onClick={() => { handleModalShow(true) }} href="#/action-2">Delete</Dropdown.Item>
            <Modal className='comments-modals' show={modalShow} onHide={handleModalClose} animation={false} centered>
                <Modal.Header>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>You are about to delete a comment. <br />This action cannot be undone!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={()=>handleDelete()} disabled={loading}>
                        {!loading ? "Delete" : <Spinner animation="grow" size="sm" variant="light" />}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DeleteCommentAlertModal;