import { authContext } from "contexts/AuthContext"
import { BOOKMARKED_ARTICLES } from "graphql/queries";
import moment from "moment";
import { useContext, useEffect, useState } from "react"
import { Alert } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { toast } from "react-toastify";
import SingleBookmarkedArticles from "./SingleBookmarkedArticles";
import placeholder from "../../assets/images/placeholder.png";

export default function BookmarkedArticles(props) {

    const { getClient } = useContext(authContext);

    const [bookmarks, setBookmarks] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleOnDelete = (id,index) => {

        props.onDelete(id).then(res=>{

            bookmarks.splice(index,1);
            setBookmarks([...bookmarks]);
        }).catch(err=>{
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }

    const client = getClient();

    useEffect(() => {
        client.query({
            query: BOOKMARKED_ARTICLES
        }).then(res => {
            setLoading(false);
            setBookmarks(res.data.viewer.bookmarkedArticlesConnection.nodes);
        }).catch(err => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        });
    }, [])

    const MyLoader = (props) => (
        <ContentLoader
            speed={2}
            width="100%"
            height="17vh"
            viewBox="0 0 100% 1080"
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
            {...props}
        >
            <rect x="0" y="0" rx=".5rem" ry=".5rem" width="8.6rem" height="100%" />
            <rect x="10.6rem" y="3.8vh" rx="0" ry="0" width="14%" height="18" />
            <rect x="10.6rem" y="7.5vh" rx="0" ry="0" width="60%" height="22" />
            <rect x="10.6rem" y="12.1vh" rx="50%" ry="50%" width="1.375rem" height="1.375rem" />
            <rect x="12.5rem" y="12.5vh" rx="0" ry="0" width="10%" height="14" />

        </ContentLoader>
    )

    return (
        <div className='px-lg-5 milestones-tab articles-tab'>
            { loading ? 
            
                Array(5).fill(0).map(()=><div className="milestone-single d-flex flex-wrap justify-content-between align-items-lg-center"><MyLoader /></div>)
            : bookmarks.length > 0 ? bookmarks.map((data, index) =>
                <>
                    <SingleBookmarkedArticles key={data.id} link={data.id} image={data?.featuredImage?.thumbnailUrl ?? placeholder} date={moment.unix(data.insertedAt).format("MMM DD, YYYY")} title={data.title} tagLine={data.tagLine} authorImage={data.createdBy.avatar.url} authorName={data.createdBy.firstName + " " + data.createdBy.lastName} onDeleteCallback={()=>handleOnDelete(data.id,index)}/>
                </>
            ) : (<Alert variant="dark">You have not bookmarked any Articles!</Alert>)}

        </div>
    )
}