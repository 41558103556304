import { faEllipsis, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { MARK_NOTIFICATION_AS_READ } from 'graphql/mutations';
import { useNavigate } from 'react-router-dom';
import { authContext } from 'contexts/AuthContext';

const SingleNotification = (props) => {


    const {getClient} = useContext(authContext);
    const [triggerBookmark, setTriggerBookmark] = useState();
    let navigate = useNavigate();

    const client = getClient();
    const handleClick = (type,resourceID,notificationID) => {
        markNotificationAsRead(notificationID);
        
        navigate("../"+type.toLowerCase()+"/"+resourceID);
    }
    const markNotificationAsRead = (notificationID) => {
        client.mutate({
            mutation:MARK_NOTIFICATION_AS_READ,
            variables:{
                id: notificationID
            }
        })
    }
    return (
        <div className={`position-relative ${triggerBookmark ? 'dargbookmark' : ''}`}>
            <div onClick={() => handleClick(props.clickfn)} key={props.key} className="d-flex flex-wrap align-items-md-center milestone-single">
                <div className='image-holder'><img src={props.avatar} alt={props.title} /></div>
                <div className='milestone-content'>
                    <h2>{props.title}</h2>
                    <p>{props.body}</p>
                    <time>{props.date}</time>
                </div>
                {/* <Button onClick={() => { setTriggerBookmark(!triggerBookmark) }} className="trigger-btn" variant="link"><FontAwesomeIcon icon={faEllipsis} /></Button> */}
            </div>
            {/* <Button className="btn-delete text-white" variant='danger'><FontAwesomeIcon icon={faTrash} /></Button> */}
        </div>
    );
}

export default SingleNotification;