import { dataContext } from 'contexts/DataContext';
import React, { useContext } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './accountsidebar.scss';

const AccountSidebar=(props)=>{

    const {data} = useContext(dataContext);

    return(
        <div className='profile-sidebar'>
            <h2>{props.Heading}</h2>
            <Nav defaultActiveKey="profile" className="flex-md-column">
                
                <Nav.Link as={Link} eventKey="profile" to="">View Profile</Nav.Link>
                <Nav.Link as={Link} eventKey="edit" to="edit">Edit Profile</Nav.Link>
                <Nav.Link as={Link} eventKey="followers" to="followers">Followers ({data?.followersConnection?.totalCount ?? 0})</Nav.Link>
                <Nav.Link as={Link} eventKey="following" to="following">Following ({data?.followingConnection?.totalCount ?? 0})</Nav.Link>
                <Nav.Link as={Link} eventKey="blocked" to="blocked">Blocked ({data?.blockedCount ?? 0})</Nav.Link>
                <Nav.Link as={Link} eventKey="interests" to="interests">Interests</Nav.Link>
            </Nav>
        </div>
    );
}

export default AccountSidebar;