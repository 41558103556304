import AddComment from 'components/addComment/AddComment';
import CommentBlock from 'components/commentBlock/CommentBlock';
import { authContext } from 'contexts/AuthContext';
import React, { useContext, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';

const CommentSection=(props)=>{
    const commentAreaRef = useRef(null);

    const { auth } = useContext(authContext);
    const [commentsData, setCommentsData] = useState(props.commentsData?.nodes ?? []);

    const handleOnAddComment = newComment =>{
        commentsData.splice(0,0,newComment);
        setCommentsData([...commentsData]);
    }
    
    const handleOnEditComment = (editedComment,index) =>{
        commentsData[index] = editedComment;
        setCommentsData([...commentsData]);
    }
    
    const handleOnDelete = (index) => {
        commentsData.splice(index,1);
        setCommentsData([...commentsData]);

    }

    const toggleCommentSection=()=>{
        commentAreaRef.current.classList.toggle("d-none");
    }

    return(
        <>
            <Row className="mt-4 mb-4">
                <Col className="d-flex justify-content-start">
                    <h4>Comments</h4>
                </Col>
                <Col className="d-flex justify-content-end">
                    <a onClick={toggleCommentSection} className="text-decoration-none link-discuss">Start discussion</a>    
                </Col>
            </Row>
            <Row ref={commentAreaRef} className="d-none">
                <AddComment resourceID={props.resourceID} onAddCallback={handleOnAddComment}/>
                { 
                    commentsData.length > 0 ? (
                    
                        commentsData.map((comment,index) => {
                            return (
                                <div className="mb-4" key={comment.id}>

                                    <CommentBlock commentData={comment} authData={auth.data} onEditCallback={(editedComment)=>handleOnEditComment(editedComment,index)} onDeleteCallback={()=>handleOnDelete(index)}/>
                                </div>
                            )
                        })
                    ) : (<></>)
                }
            </Row>
        </>
    );
}

export default CommentSection;