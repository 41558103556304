import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import "./ludicroustimeline.scss";
import 'swiper/css/navigation';
import { Button } from 'react-bootstrap';
import icoStopWatch from '../../assets/images/ico-stopwatch.svg';
import LudicrousTimeLineSlide from 'components/LudicrousTimeLineSlide/LudicrousTimeLineSlide';

const LudicrousTimeLine = (props) => {
    const {
        data,
        selectedYear,
        handleSelectedYear
    } = props;
    const [max, setMax] = useState(null)
    const [swiper, setSwiper] = useState({})

    const swiperSettings = {
        slidesPerView: 12,
        centeredSlides: true,
        spaceBetween: 0,
        breakpoints: {
            768: {
                slidesPerView: 24
            },
            1024: {
                slidesPerView: 34
            },
            1200: {
                slidesPerView: 44
            },
            1360: {
                slidesPerView: 50
            },
            1440: {
                slidesPerView: 59
            },
            1920: {
                slidesPerView: 70
            }
        }
    }

    const handleClick = (year, i) => {
        handleSelectedYear(year);
        swiper.slideTo(i);
    }

    const handleSlideChange = (i) => {
        handleSelectedYear(data[i].year)
    }

    useEffect(() => {
        if (data != null) {
            let maxCount = data[0].unlockedEventsCount;
            data.forEach(el => {
                if (el.unlockedEventsCount > maxCount) {
                    maxCount = el.unlockedEventsCount
                }
            });
            setMax(maxCount)
        }
    }, [data]) // finding max events

    useEffect(() => {
        if(swiper?.slideTo && selectedYear){
            swiper.slideTo(data.findIndex(el => el.year == selectedYear))
        }
    },[swiper])

    return (
        <>
            <Swiper
                onInit={(ev) => { setSwiper(ev) }}
                onSlideChange={(e) => handleSlideChange(e.activeIndex)}
                className='ludicrous-timeline'
                {...swiperSettings}
            >
                {data && data.map((el, i) => {
                    return (
                        <SwiperSlide key={i} onClick={() => handleClick(el.year, i)}>
                            <LudicrousTimeLineSlide data={el} max={max} />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <div className='nav-btns'>
                <Button onClick={() => swiper.slidePrev()} variant='link'><img className='me-2' src={icoStopWatch} alt="Prev" /> TRAVEL TO {data && data[swiper?.activeIndex - 1] && data[swiper?.activeIndex - 1]?.year}</Button>
                <Button onClick={() => swiper.slideNext()} variant='link'>TRAVEL TO {data && data[swiper?.activeIndex + 1] && data[swiper?.activeIndex + 1]?.year} <img className='ms-2' src={icoStopWatch} alt="Prev" /></Button>
            </div>
        </>
    );
}

export default LudicrousTimeLine;