import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './myaccount.scss';
import AccountSidebar from 'components/AccountSidebar/AccountSidebar';
import { Outlet } from 'react-router-dom';

const MyAccount = () => {
    return (
        <Container className='my-account overflow-hidden'>
            <Row className='align-items-start'>
                <Col md={4} lg={3}>
                    <AccountSidebar Heading="My Account" />
                </Col>
                <Col md={8} lg={9}>
                    <Outlet/>
                </Col>
            </Row>
        </Container>
    );
}

export default MyAccount;