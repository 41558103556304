import { faComment, faEllipsis, faMessage, faReply, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddComment from 'components/addComment/AddComment';
import DeleteCommentAlertModal from 'components/DeleteCommentAlertModal/DeleteCommentAlertModal';
import Reactions from 'components/reactions/Reactions';
import ReportCommentAlertModal from 'components/ReportCommentAlertModal/ReportCommentAlertModal';
import SubCommentBlock from 'components/subCommentBlock/SubCommentBlock';
import moment from 'moment';
import { useRef, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import Logo from '../../assets/images/logo.png';
import './commentblock.scss';

const CommentBlock = (props) => {

    const [editing, setEditing] = useState(false);

    const [subCommentsData, setSubCommentsData] = useState(props.commentData?.commentConnection?.nodes ?? []);

    const handleOnAddSubComment = newSubComment =>{
        subCommentsData.splice(0,0,newSubComment);
        setSubCommentsData([...subCommentsData]);
    }
    
    const handleOnEditSubComment = (editedSubComment,index) =>{
        subCommentsData[index]=editedSubComment;
        setSubCommentsData([...subCommentsData]);
    }
    
    const handleOnDelete = (index) => {
        subCommentsData.splice(index,1);
        setSubCommentsData([...subCommentsData]);

    }

    const commentOptionsRef = useRef(null);
    const toggleCommentOptions = () => {
        commentOptionsRef.current.classList.toggle("d-none");
    }

    const replyComment = useRef(null);
    const togglereplyComment = () => {
        console.log("called");
        replyComment.current.classList.toggle("d-none");
    }

    const commentThreadRef = useRef(null);
    const togglecommentThreadRef = () => {
        commentThreadRef.current.classList.toggle("d-none");
    }

    const MyLoader = (props) => (
        <ContentLoader
            speed={2}
            width="100%"
            height="12vh"
            viewBox="0 0 100% 1080"
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
            {...props}
        >
            <rect x="0" y="0" rx="50%" ry="50%" width="2rem" height="2rem" />
            <rect x="6%" y="6" rx="0" ry="0" width="15%" height="20" />
            <rect x="25%" y="7" rx="0" ry="0" width="12%" height="18" />
            <rect x="0" y="5vh" rx="0" ry="0" width="50%" height="18" />
            <rect x="0" y="9.4vh" rx="50%" ry="50%" width="1.5rem" height="1.5rem" />
            <rect x="6%" y="9.4vh" rx="50%" ry="50%" width="1.5rem" height="1.5rem" />
            <rect x="12%" y="9.4vh" rx="50%" ry="50%" width="1.5rem" height="1.5rem" />
            <rect x="97%" y="9.4vh" rx="50%" ry="50%" width="1.5rem" height="1.5rem" />
        </ContentLoader>
    )

    return (
        <>
            <div className="comment-area">
                {editing ? (<AddComment resourceID={props.commentData.id} update={true} value={props.commentData.text} cancelCallback={() => setEditing(false)} onAddCallback={props.onEditCallback}/>) : (
                    <>
                        <div className="comment-header d-flex flex-wrap justify-content-between align-items-center">
                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                <div className="image-holder"><img src={props.commentData.createdBy.avatar.url} alt={props.commentData.createdBy.firstName + " " + props.commentData.createdBy.lastName} /></div>
                                <strong>{props.commentData.createdBy.firstName + " " + props.commentData.createdBy.lastName}</strong>
                                <time>{moment.unix(props.commentData.insertedAt).fromNow()}</time>
                            </div>
                            <div className='position-relative'>
                                <Dropdown>
                                    <Dropdown.Toggle className='p-0' variant="link" id="dropdown-basic">
                                        <FontAwesomeIcon icon={faEllipsis} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {props.authData.id == props.commentData.createdBy.id && <>
                                            <Dropdown.Item onClick={() => { setEditing(true) }} >Edit</Dropdown.Item>
                                            <DeleteCommentAlertModal resourceID={props.commentData.id} onDeleteCallback={props.onDeleteCallback}/>
                                        </>}
                                        <ReportCommentAlertModal resourceID={props.commentData.id} />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <p>{props.commentData.text}</p>
                    </>
                )}
                <div ref={commentThreadRef} className="d-none">
                    {
                        subCommentsData.length > 0 ? (

                            subCommentsData.map((subCommentData,index) => {
                                return (

                                    <SubCommentBlock key={subCommentData.id} commentData={subCommentData} authData={props.authData} onEditCallback={(editedComment)=>handleOnEditSubComment(editedComment,index)} onDeleteCallback={()=>handleOnDelete(index)}/>
                                )
                            })

                        ) : (<></>)
                    }
                </div>
                <div className="comment-footer d-flex flex-wrap justify-content-between align-items-center">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <Reactions gap="gap-3" width="1rem" height="1rem" rated={props.commentData.rated} id={props.commentData.id} reactionCounters={{dislike: props.commentData.reactionCounters.dislike , like: props.commentData.reactionCounters.like}} />
                        {/* <Button variant="link"><FontAwesomeIcon icon={faThumbsUp} />{props.commentData.reactionCounters.like} </Button>
                        <Button variant="link"><FontAwesomeIcon icon={faThumbsDown} /> {props.commentData.reactionCounters.dislike}</Button> */}
                        <Button onClick={togglereplyComment} className='reply-btn' variant="link"><FontAwesomeIcon icon={faReply} /> Reply</Button>
                    </div>
                    <Button onClick={togglecommentThreadRef} variant="link"><FontAwesomeIcon icon={faComment} /> {props.commentData.commentConnection.totalCount}</Button>
                </div>
                <div ref={replyComment} className="d-none">
                    <AddComment resourceID={props.commentData.id} cancelCallback={togglereplyComment} onAddCallback={handleOnAddSubComment} />
                </div>
            </div>
            {/* <div className="comment-area mb-5">
                <MyLoader />
            </div> */}
        </>

    );
}

export default CommentBlock;