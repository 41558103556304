import React, { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './followingmodal.scss';
import userImage from '../../assets/images/user-img.jpg';
import { authContext } from 'contexts/AuthContext';
import { FOLLOWING } from 'graphql/queries';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const FollowingModal = (props) => {
    const [data, setData] = useState(null);
    const { getClient } = useContext(authContext);
    let client = getClient();
    const { id } = useParams();
    const [showFollowing, setShowFollowing] = useState(false);
    const handleFollowingClose = () => setShowFollowing(false);
    const handleFollowingShow = () => {
        setShowFollowing(true);
        client.query({
            query: FOLLOWING,
            variables: {
                userID: id
            }
        }).then(res => {
            setData(res.data.user.followingConnection.nodes);
        }).catch(err => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        },[id])
    }
    const {
        title,
        count
    } = props
    return (
        <div className='follower-modal mx-2 mx-sm-4 mx-md-5'>
            <Button className='p-0' variant="link" onClick={handleFollowingShow}>
                {title} ({count})
            </Button>
            <Modal centered show={showFollowing} onHide={handleFollowingClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Following</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className='followers-modal-list'>
                        {data && data.map((following, index) => {
                            return (
                                <li key={index} className={`d-flex flex-wrap justify-content-between align-items-center ${data.length > 1 ? 'mb-3' : ''}`}>
                                    <div className='content-area'>
                                        <div className='image-holder'><img src={following.avatar.url} alt={following.firstName + " " + following.lastName} /></div>
                                        <div className='info-area'>
                                            <strong>{following.firstName + " " + following.lastName}</strong>
                                            <p>Followers ({following.followersConnection.totalCount})</p>
                                        </div>
                                    </div>
                                    <Button as={Link} to={"../users/" + following.id} className='follow-btn' variant='primary' onClick={handleFollowingClose}>View</Button>
                                </li>
                            )
                        })}
                    </ul>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default FollowingModal;