import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Milestones from "components/milestones/Milestones";
import NewsFeed from "components/newsFeed/NewsFeed";
import Slider from "components/slider/Slider";
import Wrapper from "components/wrapper/Wrapper";
import { dataContext } from "contexts/DataContext";
import { authContext } from "contexts/AuthContext";
import { ARTICLES, EVENTS } from "graphql/queries";
import ContentLoader from 'react-content-loader'

const Home = () => {
    const { data } = useContext(dataContext);
    const { getClient } = useContext(authContext);
    const [articles,setArticles] = useState([]);
    const [events,setEvents] = useState([]);
    const [isLoadingArticles,setIsLoadingArticles] = useState(true);
    const [isLoadingEvents,setIsLoadingEvents] = useState(true);

    let client = getClient()

    useEffect(() => {
        
            client.query({
                query: ARTICLES
            }).then(res => {
                setIsLoadingArticles(false);
                setArticles(res.data.articlesConnection.nodes)
            }).catch(err => {
                console.log(err)
            })
            
            client.query({
                query: EVENTS
            }).then(res => {
                setIsLoadingEvents(false);
                setEvents(res.data.eventsConnection.nodes)
            }).catch(err => {
                console.log(err)
            })
        
    }, [])

    const SliderLoader = () => {
        return (
            <>
                <Row className="d-none d-lg-block">
                    <Col>
                        <ContentLoader viewBox="0 0 500 200" width="100%">
                            <rect x="3.5%" y="40" rx=".75rem" ry=".75rem" width="30%" height="110" />
                            <rect x="8.5%" y="25" rx=".75rem" ry=".75rem" width="30%" height="140" />
                            <rect x="20%" y="10" rx=".75rem" ry=".75rem" width="60%" height="170" />
                            <rect x="60.5%" y="25" rx=".75rem" ry=".75rem" width="30%" height="140" />
                            <rect x="75.5%" y="40" rx=".75rem" ry=".75rem" width="20%" height="110" />
                        </ContentLoader>
                    </Col>
                </Row>
                <Row className="d-none d-md-block d-lg-none">
                    <Col>
                        <ContentLoader viewBox="0 0 500 270" width="100%">
                            <rect x="3.5%" y="40" rx=".75rem" ry=".75rem" width="30%" height="188" />
                            <rect x="8.5%" y="25" rx=".75rem" ry=".75rem" width="30%" height="220" />
                            <rect x="20%" y="10" rx=".75rem" ry=".75rem" width="60%" height="250" />
                            <rect x="60.5%" y="25" rx=".75rem" ry=".75rem" width="30%" height="220" />
                            <rect x="75.5%" y="40" rx=".75rem" ry=".75rem" width="20%" height="188" />
                        </ContentLoader>
                    </Col>
                </Row>
                <Row className="d-md-none">
                    <Col>
                        <ContentLoader viewBox="0 0 500 500" width="100%">
                            <rect x="10" y="40" rx="3rem" ry="3rem" width="96.5%" height="460" />
                        </ContentLoader>
                    </Col>
                </Row>



            </>
        )
    }

    const ArticleLoader = () => {
        <>
            <h1 className="sec-heading">Latest NewsFeed</h1>
            <Row className="latest-newsfeeds mb-5">
                <Col md={4} lg={3} xl={2}>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0.75rem' }}>
                        <ContentLoader viewBox="0 0 500 500" width="100%">
                            <rect x="5" y="17" rx="0" ry="0" width="100%" height="300" />
                            <rect x="20" y="350" rx="2" ry="2" width="90%" height="30" />
                            <rect x="20" y="400" rx="2" ry="2" width="90%" height="30" />
                            <circle cx="35" cy="470" r="20" />
                            <rect x="69" y="462" rx="2" ry="2" width="50%" height="20" />
                        </ContentLoader>
                    </div>
                </Col>
                <Col md={4} lg={3} xl={2}>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0.75rem' }}>
                        <ContentLoader viewBox="0 0 500 500" width="100%">
                            <rect x="5" y="17" rx="0" ry="0" width="100%" height="300" />
                            <rect x="20" y="350" rx="2" ry="2" width="90%" height="30" />
                            <rect x="20" y="400" rx="2" ry="2" width="90%" height="30" />
                            <circle cx="35" cy="470" r="20" />
                            <rect x="69" y="462" rx="2" ry="2" width="50%" height="20" />
                        </ContentLoader>
                    </div>
                </Col>
                <Col md={4} lg={3} xl={2}>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0.75rem' }}>
                        <ContentLoader viewBox="0 0 500 500" width="100%">
                            <rect x="5" y="17" rx="0" ry="0" width="100%" height="300" />
                            <rect x="20" y="350" rx="2" ry="2" width="90%" height="30" />
                            <rect x="20" y="400" rx="2" ry="2" width="90%" height="30" />
                            <circle cx="35" cy="470" r="20" />
                            <rect x="69" y="462" rx="2" ry="2" width="50%" height="20" />
                        </ContentLoader>
                    </div>
                </Col>
                <Col md={4} lg={3} xl={2}>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0.75rem' }}>
                        <ContentLoader viewBox="0 0 500 500" width="100%">
                            <rect x="5" y="17" rx="0" ry="0" width="100%" height="300" />
                            <rect x="20" y="350" rx="2" ry="2" width="90%" height="30" />
                            <rect x="20" y="400" rx="2" ry="2" width="90%" height="30" />
                            <circle cx="35" cy="470" r="20" />
                            <rect x="69" y="462" rx="2" ry="2" width="50%" height="20" />
                        </ContentLoader>
                    </div>
                </Col>
                <Col md={4} lg={3} xl={2}>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0.75rem' }}>
                        <ContentLoader viewBox="0 0 500 500" width="100%">
                            <rect x="5" y="17" rx="0" ry="0" width="100%" height="300" />
                            <rect x="20" y="350" rx="2" ry="2" width="90%" height="30" />
                            <rect x="20" y="400" rx="2" ry="2" width="90%" height="30" />
                            <circle cx="35" cy="470" r="20" />
                            <rect x="69" y="462" rx="2" ry="2" width="50%" height="20" />
                        </ContentLoader>
                    </div>
                </Col>
            </Row>
        </>
    }

    const EventLoader = () => {
        <>
            <h1 className="sec-heading">Related Milestones</h1>
            <Row>
                <Col md={6} lg={4}>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0' }}>
                        <ContentLoader viewBox="0 0 500 150" >
                            <rect x="45%" y="38" rx="2" ry="2" width="234" height="12" />
                            <rect x="0" y="0" rx="10" ry="10" width="40%" height="100%" />
                            <rect x="45%" y="64" rx="2" ry="2" width="200" height="12" />
                            <rect x="45%" y="91" rx="2" ry="2" width="160" height="12" />
                        </ContentLoader>
                    </div>
                </Col>
                <Col md={6} lg={4}>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0' }}>
                        <ContentLoader viewBox="0 0 500 150" >
                            <rect x="45%" y="38" rx="2" ry="2" width="234" height="12" />
                            <rect x="0" y="0" rx="10" ry="10" width="40%" height="100%" />
                            <rect x="45%" y="64" rx="2" ry="2" width="200" height="12" />
                            <rect x="45%" y="91" rx="2" ry="2" width="160" height="12" />
                        </ContentLoader>
                    </div>
                </Col>
                <Col md={6} lg={4}>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0' }}>
                        <ContentLoader viewBox="0 0 500 150" >
                            <rect x="45%" y="38" rx="2" ry="2" width="234" height="12" />
                            <rect x="0" y="0" rx="10" ry="10" width="40%" height="100%" />
                            <rect x="45%" y="64" rx="2" ry="2" width="200" height="12" />
                            <rect x="45%" y="91" rx="2" ry="2" width="160" height="12" />
                        </ContentLoader>
                    </div>
                </Col>
            </Row>
        </>
    }
    return (
        <>
            <Wrapper>
                <Container fluid>
                    {data ?
                            <div className="my-5">
                                <Slider data={data?.recommendedArticlesConnection?.nodes ?? []} />
                            </div>
                        :
                        <SliderLoader />
                    }
                    {   isLoadingArticles ? <ArticleLoader/> :
                            <div className="my-5">
                                <NewsFeed heading="Latest Newsfeed" data={articles} />
                            </div>
                    }
                    {     isLoadingEvents ? <EventLoader/> : <div className="my-5">
                                <Milestones moreBtn heading={"Related Milestones"} data={events} />
                            </div>
                        
                    }
                </Container>
            </Wrapper>
        </>
    )
}

export default Home;