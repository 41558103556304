import React, { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './followermodal.scss';
import userImage from '../../assets/images/user-img.jpg';
import { authContext } from 'contexts/AuthContext';
import { FOLLOWERS } from 'graphql/queries';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const FollowerModal = (props) => {
    const [data, setData] = useState(null);
    const { getClient } = useContext(authContext);
    let client = getClient();
    const { id } = useParams();
    const [showFollowes, setShowFollowes] = useState(false);
    const handleFollowersClose = () => setShowFollowes(false);
    const handleFollowersShow = () => {
        setShowFollowes(true);
        client.query({
            query: FOLLOWERS,
            variables: {
                userID: id
            }
        }).then(res => {
            setData(res.data.user.followersConnection.nodes);
        }).catch(err => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        },[id])
    }
    const {
        title,
        count
    } = props
    return (
        <div className='follower-modal mx-2 mx-sm-4 mx-md-5'>
            <Button className='p-0' variant="link" onClick={handleFollowersShow}>
                {title} ({count})
            </Button>
            <Modal centered show={showFollowes} onHide={handleFollowersClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Followers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className='followers-modal-list'>
                        {data && data.map((follower, index) => {
                            return (
                                <li key={index} className={`d-flex flex-wrap justify-content-between align-items-center ${data.length > 1 ? 'mb-3' : ''}`}>
                                    <div className='content-area'>
                                        <div className='image-holder'><img src={follower.avatar.url} alt={follower.firstName + " " + follower.lastName} /></div>
                                        <div className='info-area'>
                                            <strong>{follower.firstName + " " + follower.lastName}</strong>
                                            <p>Followers ({follower.followersConnection.totalCount})</p>
                                        </div>
                                    </div>
                                    <Button as={Link} to={"../users/" + follower.id} className='follow-btn' variant='primary' onClick={handleFollowersClose}>View</Button>
                                </li>
                            )
                        })}
                    </ul>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default FollowerModal;