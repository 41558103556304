
import React, { useContext } from "react"
import { Button, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Logo from 'assets/images/logo.png';
import { authContext } from "contexts/AuthContext";
import { dataContext } from "contexts/DataContext";

const ProfileDropdown = () => {
    const { data } = useContext(dataContext);
    const { setAuthData } = useContext(authContext);
    let navigate = useNavigate()

    const signout = () => {
        setAuthData(null);
        navigate("/login");
    }

    return (
        <Dropdown className="profile-widget">
            <Dropdown.Toggle className="user-icons" id="dropdown-basic">
                <img src={data?.avatar?.url ? data?.avatar?.url : Logo} alt="User" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/myaccount">Profile</Dropdown.Item>
                <Dropdown.Item  onClick={signout}>Signout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ProfileDropdown;
