import React from "react"
import "./milestone.scss"
import placeholder from "assets/images/placeholder.png"
import ArrowIcon from "assets/images/icon-arrow.svg"

const Milestone = (props) => {
    const {
        data
    } = props;

    return(
        <div className="milestone d-flex flex-wrap justify-content-between align-items-center">
            <div className="image-holder">
                <img width={100} src={data?.featuredImage?.thumbnailUrl ? data?.featuredImage?.thumbnailUrl : placeholder} alt="" />
            </div>
            <div className="content">
                <h1>{data.title}</h1>
                <div className="d-flex justify-content-between align-items-center mt-2 mt-lg-4">
                    <p className="mb-0">{data?.happenedInYear ? data?.happenedInYear : data?.consensusYear}</p>
                    <img src={ArrowIcon} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Milestone
