import React, { useContext, useEffect, useState } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import Milestone from "./Milestone"

const Milestones = (props) => {
    const [listItems, setListItems] = useState(6);

    return (
        <Container fluid>
            <h1 className="sec-heading">{props?.heading}</h1>
            <Row>
                {props?.data ? props?.data.length > 0 ? props?.data.filter((el, i) => (!props.moreBtn || i < listItems)).map(el => {
                    return (
                        <Col key={el.id} sm={6} lg={props?.cols ? props.cols : 4}>
                            <Link className="milestones-holder" to={`/event/${el.id}`}>
                                <Milestone data={el} />
                            </Link>
                        </Col>
                    )
                }) : <p>No Events Found!!</p> : <p>Loading...</p>}
            </Row>
            {props.moreBtn &&
                <div className="text-end">
                    <Button hidden={listItems == props?.data?.length + 1} onClick={() => setListItems(props?.data?.length + 1)} style={{ 'color': '#679CF6' }} className="text-decoration-none" variant="link">More</Button>
                </div>
            }
        </Container>
    )
}

export default Milestones
