import React, { useEffect } from "react"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./slider.scss"

// import required modules
import { EffectCoverflow, Navigation, Pagination } from "swiper";
import { Container } from "react-bootstrap";

import placeholder from "assets/images/placeholder.png"
import moment from "moment";
import { Link } from "react-router-dom";

const Slider = (props) => {

    return (
        <Container className="customSliderContainer" fluid>
            {props.data.length > 0 &&
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={"1"}
                    loop={true}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 200,
                        modifier: 1,
                        slideShadows: false,
                    }}
                    autoHeight={true}
                    pagination={true}
                    navigation={true}
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    breakpoints={{
                    768: {
                        slidesPerView: 4,
                    },
                    }}
                >
                    {props.data.map((el,i) => {
                        return (
                            <SwiperSlide key={i}>
                                <Link to={`/article/${el.id}`}>

                                    <div className="sliderItem d-flex align-items-end" style={{ backgroundImage: `url(${el.featuredImage?.largeUrl ? el.featuredImage.largeUrl : placeholder ?? placeholder })` }} >
                                        <div className="px-4 py-3 slider-content">
                                            <h1>{el.title}</h1>
                                            <p>Last updated on {moment.unix(el?.insertedAt).format("MMM DD, YYYY")}</p>
                                        </div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        )
                    })
                    }

                </Swiper>
            }
        </Container>
    )
}

export default Slider