import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import Company from "./Company"

// Import Swiper styles
import "swiper/css";
import TrendingCompany from "components/trendingCompany/TrendingCompany";

const CompanySlider = (props) => {
    const { data } = props;

    const swiperSettings = {
        slidesPerView: 2,
        autoplay: true,
        loop:true,
        spaceBetween: 20,
        breakpoints: {
            768: {
                slidesPerView: 3
            }
        }
    }

    return (
        <div className="companySlider container-fluid">
            <h1 className="sec-heading">Related Companies</h1>
            <Swiper {...swiperSettings}>
            {data && data.length > 0 ? data.map((el,i) => {
                return(
                    <SwiperSlide key={i}>
                        {/* <Company data={el}/> */}
                        <TrendingCompany data={el} />
                    </SwiperSlide>
                )
            }) : <p>No Company Found!</p>}
            
            </Swiper>
        </div>
    )
}

export default CompanySlider
