import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import icoStopWatch from '../../assets/images/ico-stopwatch.svg';
import LightspeedSlide from 'components/lightspeedSlide/LightspeedSlide';


const LightspeedTimeline = (props) => {
    const {
        data,
        selectedYear,
        handleSelectedYear
    } = props;
    const [swiper , setSwiper ] = useState({})


    const swiperSettings = {
        slidesPerView: 2,
        autoplay: true,
        // loop:true,
        centeredSlides: true,
        spaceBetween: 24,
        breakpoints: {
            768: {
                slidesPerView: 4
            },
            992: {
                slidesPerView: 6
            },
            1600: {
                slidesPerView: 9
            }
        }
    }

    const handleClick = (year , i) => {
        handleSelectedYear(year);
        swiper.slideTo(i);
    }

    const handleSlideChange = (i) => {
        handleSelectedYear(data[i].year)
    }

    useEffect(() => {
        if(swiper?.slideTo && selectedYear){
            swiper.slideTo(data.findIndex(el => el.year == selectedYear))
        }
    },[swiper])
    
    return (
        <Swiper
            onInit={(ev) => { setSwiper(ev) }}
            onSlideChange={(e) => handleSlideChange(e.activeIndex)}
            
            className='lightspeed-slider'
            {...swiperSettings}
        >
            {data && data.map((el, i) => {
                return (
                    <SwiperSlide onClick={() => handleClick(el.year , i)} key={i}>
                        <LightspeedSlide data={el} />
                    </SwiperSlide>
                )
            })}
            <div className='nav-btns'>
            <Button onClick={() => swiper.slidePrev()} variant='link'><img className='me-2' src={icoStopWatch} alt="Prev" /> TRAVEL TO {data && data[swiper?.activeIndex - 1] && data[swiper?.activeIndex - 1]?.year}</Button>
            <Button onClick={() => swiper.slideNext()} variant='link'>TRAVEL TO {data && data[swiper?.activeIndex + 1] && data[swiper?.activeIndex + 1]?.year} <img className='ms-2' src={icoStopWatch} alt="Prev" /></Button>
            </div>
        </Swiper>
    );
}

export default LightspeedTimeline;