import { useContext, useRef, useState } from 'react';
import { Form , Button, Spinner } from 'react-bootstrap';
import './addcomment.scss';
import Logo from '../../assets/images/logo.png';
import { CREATE_COMMENT, UPDATE_COMMENT } from 'graphql/mutations';
import {v4 as uuidv4 } from 'uuid';
import { useForm } from 'react-hook-form';
import { authContext } from 'contexts/AuthContext';
import { toast } from 'react-toastify';


const AddComment = (props) => {
    const { getClient } = useContext(authContext);
    const {register, handleSubmit,formState:{errors}, watch, reset } = useForm();
    const commentValue = watch("comment","");
    const [loading, setLoading] = useState(false);
    let commentInputRef = useRef({});
    const { ref, ...rest } = register("comment", {required:true,minLength:4,maxLength:100,pattern:/^[^\s]+(\s+[^\s]+)*$/});

    const cancel = ()=>{
        props?.cancelCallback?.();
        reset({
            comment:""
        });
    } 
    
    let client = getClient();
    
    const onSubmit = data => {
        setLoading(true);

        client.mutate({
            mutation: props?.update ? UPDATE_COMMENT : CREATE_COMMENT  ?? CREATE_COMMENT,
            variables:{
                id: props.resourceID,
                text: data.comment,
                requestID: uuidv4()   
            }
        }).then(res=>{
            setLoading(false);
            cancel();
            props?.onAddCallback?.(res.data?.createComment ?? res.data?.updateComment);
            toast.success(`Comment ${ props?.update ? "updated" : "posted" ?? "posted" } successfully`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }).catch(err=>{
            setLoading(false);
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }
    
    return(
        <Form onSubmit={handleSubmit(onSubmit)} className="comment-form bg-white d-flex flex-wrap justify-content-between align-items-start mb-4">
            <div className="image-holder"><img src={Logo} alt="supertrends" /></div>
            <Form.Group className="comment-field" >
                <Form.Control as="textarea" defaultValue={props?.value ?? ""} rows={3} placeholder="Add a comment…" {...rest} ref={(e) => {
                    ref(e)
                    commentInputRef.current = e;
                }}
                className={`${errors.comment ? "border border-danger" : ""}`}    
                />
                { errors.comment?.type === 'required' && <p className='text-danger'>{`Please write something before submitting.`}</p>}
                { errors.comment?.type === 'minLength' && <p className='text-danger'>{`Too few characters.`}</p>}
                { errors.comment?.type === 'maxLength' && <p className='text-danger'>{`Too many characters.`}</p>}
                { errors.comment?.type === 'pattern' && <p className='text-danger'>{`No spaces allowed at start or end`}</p>}
            </Form.Group>
            <div className='btn-holder pt-3 pt-md-0'>
                <Button type="submit" variant="primary" className='mb-md-2 mx-2 mx-md-0' disabled={loading}>
                    {!loading ? "Send" : <Spinner animation="grow" size="sm" variant="light" />}
                </Button>
                <Button onClick={()=>{cancel();}}  disabled={commentValue === "" && !props.hasOwnProperty("cancelCallback") } variant={commentValue === "" ? "secondary" : "primary"} className='mx-2 mx-md-0'>Cancel</Button>
            </div>
        </Form>
    );
}

export default AddComment;