import { gql } from '@apollo/client';
import { AUTH_FIELDS, BOOKMARK_UPDATE_FIELDS, COMMENT_FIELDS, EVENT_IMPACT_FIELDS, IMAGE_FIELDS, PREDICTION_GRAPH_FIELDS, INTERESTS_FIELDS } from './fragments';

export const LOGIN = gql`
    ${AUTH_FIELDS}
    mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            ...AuthenticationFragment
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation ResetPassword($email: String!) {
        requestPasswordReset(email: $email)
    }
`;

export const REGISTER = gql`
    mutation RegisterUser($input: UserInput!, $tagIDs: [String!], $mergeAnonymousProfile: Boolean!) {
        registerUser(
            mergeAnonymousProfile: $mergeAnonymousProfile
            userRegistration: $input
            interestIds: $tagIDs
        ) {
            ...AuthenticationFragment
        }
    }
    ${AUTH_FIELDS}
`;


export const REFRESH_TOKEN = gql`
    mutation ExchangeRefreshToken($refreshToken: String!) {
        exchangeRefresh(refreshToken: $refreshToken) {
            accessToken
            refreshToken
        }
    }

`;

export const UPDATE_REACTIONS = gql`
    mutation UpdateReaction($id: ID!, $value: Reaction) {
        updateReaction(reaction: { id: $id, value: $value }) {
            ... on Comment {
                id
                reactionCounters {
                    dislike
                    like
                }
                rated
            }
            ... on Event {
                id
                reactionCounters {
                    dislike
                    like
                }
                rated
            }
            ... on Article {
                id
                reactionCounters {
                    dislike
                    like
                }
                rated
            }
        }
    }
`;

export const CREATE_BOOKMARK = gql`

    ${BOOKMARK_UPDATE_FIELDS}

    mutation CreateBookmark($id: ID!) {
        createBookmark(id: $id) {
            id
            ...BookmarkUpdateFragment
        }
    }
`;

export const DELETE_BOOKMARK = gql`

    ${BOOKMARK_UPDATE_FIELDS}

    mutation DeleteBookmark($id: ID!) {
        deleteBookmark(id: $id) {
            id
            ...BookmarkUpdateFragment
        }
}
`;

export const CREATE_COMMENT = gql`

    ${COMMENT_FIELDS}

    mutation CreateComment($id: ID!, $text: String!, $requestID: String!) {
        createComment(input: { id: $id, text: $text, requestId: $requestID }) {
            id
            ...CommentFragment
        }
    }
`;

export const UPDATE_COMMENT = gql`

    ${COMMENT_FIELDS}

    mutation UpdateComment($id: ID!, $text: String!, $requestID: String!) {
        updateComment(
            input: { id: $id, text: $text, requestId: $requestID }
        ) {
            id
            ...CommentFragment
        }
    }
`;


export const DELETE_COMMENT = gql`
    mutation DeleteComment($id: ID!) {
        deleteComment(id: $id) {
            id # There is no need to return any information back
        }
    }
`;

export const CREATE_PREDICTION = gql`
    
    ${PREDICTION_GRAPH_FIELDS}
    
    mutation CreatePrediction($eventId: ID!, $requestId: String!, $year: Int!) {
    createPrediction(
        input: { id: $eventId, requestId: $requestId, year: $year }
    ) {
        event {
            id
            ...PredictionsGraphFragment
        }
    }
}`;

export const UPDATE_IMPACT = gql`

    ${EVENT_IMPACT_FIELDS}

    mutation UpdateImpact(
        $id: ID!
        $impactKey: EventImpactKey!
        $impactValue: EventImpactValue
    ) {
        updateImpact(id: $id, impact: { key: $impactKey, value: $impactValue }) {
            ...EventImpactFragment
        }
    }
`;

export const UPDATE_USER = gql`
    
    ${IMAGE_FIELDS}
    
    mutation UpdateUser($input: UserEdit!) {
        updateUserProfile(input: $input) {
            id
            firstName
            lastName
            biographyBlock {
                plainText
            }
            avatar {
                id
                ...ImageFragment
            }
            allowsSearchByEmail
        }
    }
`;

export const UPDATE_INTERESTS = gql`
    mutation ResetInterests($tagIDs: [String!]!) {
    resetInterests(interestIds: $tagIDs) {
        id
        interests {
            id
            name
        }
    }
}`;

export const DELETE_INTEREST = gql`
    
    ${INTERESTS_FIELDS}

    fragment InterestViewerFragment on Viewer {
  
        ...InterestsFragment
    }
    
    mutation DeleteInterests($ids: [String!]!) {
        deleteInterests(interestIds: $ids) {
            id
            ...InterestViewerFragment
        }
    }
`

export const MARK_NOTIFICATION_AS_READ = gql`
    mutation MarkNotificationAsRead($id: ID!) {
        markNotificationAsRead(id: $id) {
            id
            isRead
        }
    }

`;

export const CREATE_FEEDBACK = gql`
    mutation Report(
        $objectId: String!
        $title: String!
        $body: String!
        $requestId: String!
    ) {
        createFeedback(
            input: {
                objectId: $objectId
                title: $title
                body: $body
                requestId: $requestId
                type: REPORT
            }
        )
    }
`;

export const FOLLOW_USER = gql`
    mutation FollowUser($userID: ID!) {
        followUser(id: $userID) {
            id
            viewerIsFollowingUser
        }
    }
`;

export const UNFOLLOW_USER = gql`
    mutation UnfollowUser($userID: ID!) {
        unfollowUser(id: $userID) {
            id
            viewerIsFollowingUser
        }
    }

`;