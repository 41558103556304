import { gql } from "@apollo/client";
import { ARTICLE_CARD_FIELDS, ARTICLE_DETAIL_FIELDS, COMPANY_CARD_FIELDS, COMPANY_DETAIL_FIELDS, EVENT_CARD_FIELDS, EVENT_DETAIL_FIELDS, EXPERT_CARD_FIELDS, EXPERT_DETAIL_FIELDS, NOTIFICATION_CARD_FIELDS, PREDICTION_GRAPH_FIELDS, TAG_FIELDS, USER_CARD_FIELDS, USER_FIELDS, VIEWER_FIELDS } from "./fragments";

export const TAGS = gql`
    ${TAG_FIELDS}
    query Tags {
        tags {
            id
            ...TagFragment
        }
    }
`;

export const ARTICLE = gql`
    ${ARTICLE_CARD_FIELDS}
    ${ARTICLE_DETAIL_FIELDS}
        
    query Article($id: ID!) {
        article(id: $id) {
            id
            ...ArticleDetailsFragment
            ...ArticleCardFragment
        }
    }
`;

export const ARTICLES = gql`
    
    ${ARTICLE_CARD_FIELDS}
    
    query ArticlesBatch($after: ID, $textSearch: String) {
        articlesConnection(first: 12, after: $after, textSearch: $textSearch) {
            nodes {
                id
                ...ArticleCardFragment
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;

export const EVENT = gql`
    

    ${EVENT_DETAIL_FIELDS}
    ${EVENT_CARD_FIELDS}
    ${PREDICTION_GRAPH_FIELDS}
    
    query Event($id: ID!) {
        event(id: $id) {
            id
            ...EventDetailsFragment
            ...PredictionsGraphFragment
            ...EventCellFragment
        }
    }
`;

export const EVENTS = gql`
    
    ${EVENT_CARD_FIELDS}

    query EventsBatch($after: ID, $textSearch: String) {
        eventsConnection(
            first: 12
            after: $after
            textSearch: $textSearch
            includeNever: true
        ) {
            nodes {
                id
                ...EventCellFragment
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;

export const VIEWER = gql`
    ${VIEWER_FIELDS}
    query Viewer {
        viewer {
            id
            ...ViewerFragment
        }
    }
`;

export const TIMELINE = gql`
    query Timeline($textSearch: String) {
        viewer {
            id
            timeline(includeNever: true, textSearch: $textSearch) {
                years {
                    year
                    featuredEventsCount
                    unlockedEventsCount
                    lockedEventsCount
                    historicEventsCount
                    imageUrl
                    title
                }
            }
        }
    }
`;

export const EVENTS_BY_YEAR = gql`
    ${EVENT_CARD_FIELDS}
    query YearEventsBatch($after: ID, $year: Int!, $textSearch: String) {
        eventsConnection(
            first: 12
            after: $after
            year: $year
            textSearch: $textSearch
            includeNever: true
            lockUnpredictedEvents: true
            orderBy: { direction: DESC, field: FEATURED }
        ) {
            nodes {
                id
                ...EventCellFragment
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;

export const EXPERTS = gql`
    ${EXPERT_CARD_FIELDS}
    query expertsConnection($after: ID, $textSearch: String) {
        expertsConnection(after: $after, textSearch: $textSearch) {
            nodes {
                id
                ...ExpertCellFragment
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
  }
`;

export const COMPANIES = gql`
    ${COMPANY_CARD_FIELDS}
    query CompaniesBatch($count: Int ,$after: ID, $textSearch: String) {
        companiesConnection(first: $count, after: $after, textSearch: $textSearch) {
            nodes {
                id
                ...CompanyCellFragment
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }

`

export const COMPANY = gql`
    ${COMPANY_DETAIL_FIELDS}
    query Company($id: ID!) {
        company(id: $id) {
            id
            ...CompanyDetailsFragment
        }
    }
`;

export const EXPERT = gql`
    ${EXPERT_DETAIL_FIELDS}
    query Innovator($id: ID!) {
        innovator(id: $id) {
            id
            ...InnovatorDetailsFragment
        }
    }
`;

export const NOTIFICATIONS = gql`

    ${NOTIFICATION_CARD_FIELDS}

    query NotificationsBatch($after: ID) {
        viewer {
            id
            notificationsConnection(
                first: 12
                after: $after
                isRead: null
                orderBy: { field: ID, direction: DESC }
            ) {
                nodes {
                    id
                    ...NotificationCellFragment
                }
                pageInfo {
                    endCursor
                    hasNextPage
                }
                totalCount
            }
        }
    }
`;

export const BOOKMARKED_ARTICLES = gql`

    ${ARTICLE_CARD_FIELDS}

    query BookmarkedArticlesBatch($after: ID, $textSearch: String) {
        viewer {
            id
            bookmarkedArticlesConnection(
                after: $after
                first: 12
                orderBy: { direction: DESC, field: ID }
                textSearch: $textSearch
            ) {
                nodes {
                    id
                    ...ArticleCardFragment
                }
                pageInfo {
                    endCursor
                    hasNextPage
                }
            }
        }
    }
`;

export const BOOKMARKED_COMPANIES = gql`

    ${COMPANY_CARD_FIELDS}

    query BookmarkedCompaniesBatch($after: ID, $textSearch: String) {
        viewer {
            id
            bookmarkedCompaniesConnection(
                after: $after
                first: 8
                orderBy: { direction: DESC, field: ID }
                textSearch: $textSearch
            ) {
                nodes {
                    id
                    ...CompanyCellFragment
                }
                pageInfo {
                    endCursor
                    hasNextPage
                }
            }
        }
    }
`;

export const BOOKMARKED_EVENTS = gql`

    ${EVENT_CARD_FIELDS}

    query BookmarkedEventsBatch($after: ID, $textSearch: String) {
        viewer {
            id
            bookmarkedEventsConnection(
                after: $after
                first: 12
                includeNever: true
                isAuthorFollowingViewer: null
                isViewerFollowingAuthor: null
                orderBy: { direction: DESC, field: ID }
                textSearch: $textSearch
            ) {
                nodes {
                    id
                    ...EventCellFragment
                }
                pageInfo {
                    endCursor
                    hasNextPage
                }
            }
        }
    }
`;

export const BOOKMARKED_EXPERTS = gql`

    ${EXPERT_CARD_FIELDS}

    query BookmarkedInnovatorsBatch($after: ID, $textSearch: String) {
        viewer {
            id
            bookmarkedInnovatorsConnection(
                after: $after
                first: 8
                orderBy: { direction: DESC, field: ID }
                textSearch: $textSearch
            ) {
                nodes {
                    id
                    ...InnovatorCellFragment
                }
                pageInfo {
                    endCursor
                    hasNextPage
                }
            }
        }
    }
`;


export const USERS = gql`
    
    ${USER_CARD_FIELDS}


    query RegisteredUsersBatch($after: ID, $textSearch: String!) {
        registeredUsersConnection(after: $after, textSearch: $textSearch) {
            nodes {
                id
                ...UserCellFragment
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
`; 

export const USER = gql`
    
    ${USER_FIELDS}
    
    query User($userID: ID!) {
        user(id: $userID) {
            id
            ...UserProfileFragment
        }
    }
`;

export const FOLLOWERS = gql`

    ${USER_CARD_FIELDS}

    query FollowersBatch($userID: ID!, $after: ID) {
        user(id: $userID) {
            id
            followersConnection(first: 24, after: $after) {
                totalCount
                pageInfo {
                    endCursor
                    hasNextPage
                }
                nodes {
                    id
                    ...UserCellFragment
                }
            }
        }
    }
`;

export const FOLLOWING = gql`
    
    ${USER_CARD_FIELDS}

    query FollowingBatch($userID: ID!, $after: ID) {
        user(id: $userID) {
            id
            followingConnection(first: 24, after: $after) {
                totalCount
                pageInfo {
                    endCursor
                    hasNextPage
                }
                nodes {
                    id
                    ...UserCellFragment
                }
            }
        }
    }
`;