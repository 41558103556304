import { faEllipsis, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SingleBookmarkedCompanies = (props) => {

    const [ triggerBookmark, setTriggerBookmark ] = useState();

    const handleOnClick = () => {
        props.onDeleteCallback();
    }
    return (
        <div className={`position-relative ${triggerBookmark ? 'dargbookmark' : ''}`}>
            <div className="d-flex flex-wrap justify-content-between align-items-center milestone-single">
                <Link className="milestones-holder" to={`../companies/${props.link}`}>
                    <div className='image-holder'><img src={props.image} alt="Description" /></div>
                    <div className='milestone-content'>
                        <h2>{props.name}</h2>
                        <time>{props.location}</time>
                    </div>
                </Link>
                <Button onClick={() => { setTriggerBookmark(!triggerBookmark) }} className="trigger-btn" variant="link"><FontAwesomeIcon icon={faEllipsis} /></Button>
            </div>
            <Button className="btn-delete text-white" variant='danger' onClick={handleOnClick}><FontAwesomeIcon icon={faTrash} /></Button>
        </div>
    );
}

export default SingleBookmarkedCompanies;