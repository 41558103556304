import React, { useContext, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ResetPassword from "pages/ResetPassword";
import { authContext } from "contexts/AuthContext";
import Home from "pages/Home";
import Login from "pages/Login";
import Register from "pages/Register";
import RegisterTags from "pages/RegisterTags";
import ArticleDetail from "pages/articles/ArticleDetails";
import Timeline from "pages/Timeline/Timeline";
import Companies from "pages/Companies/Companies";
import Experts from "pages/Experts/Experts";
import EventDetail from "pages/events/EventDetail";
import CompanyDetails from "pages/CompanyDetails/CompanyDetails";
import ExpertDetails from "pages/ExpertDetails/ExpertDetails";
import MyAccount from "pages/MyAccount/MyAccount";
import Bookmarks from "pages/Bookmarks/Bookmarks";
import Notifications from "pages/Notifications/Notifications";
import Settings from "pages/Settings/Settings";
import Profile from "components/Profile/Profile";
import EditProfile from "components/EditProfile/EditProfile";
import FollowUser from "components/FollowUser/FollowUser";
import Interests from "components/Interests/Interests";
import { dataContext } from "contexts/DataContext";
import { VIEWER } from "graphql/queries";
import PrivateRoute from "./PrivateRoute";
import Search from "pages/Search/Search";
import UserSearch from "pages/UserSearch/UserSearch";
import UserDetails from "pages/UserDetails/UserDetails";
import Flyover from "pages/Flyover/Flyover";


const Routing = () => {
    const { auth, getClient } = useContext(authContext);
    let location = useLocation()
    const { data, setData } = useContext(dataContext);
    let client = getClient()

    useEffect(() => {
        if (data == null) {
            client.query({
                query: VIEWER
            }).then(res => {
                setData(res.data.viewer)
            }).catch(err => {
                console.log(err)
            })
        }
    }, [auth.data, data])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [location.pathname]) // back to top after route change

    return (
        <Routes>
            <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/search" element={<PrivateRoute><Search /></PrivateRoute>} />
            <Route path="/users" element={<PrivateRoute><UserSearch /></PrivateRoute>} />
            <Route path="/users/:id" element={<PrivateRoute><UserDetails /></PrivateRoute>} />
            <Route path="/article/:id" element={<PrivateRoute><ArticleDetail /></PrivateRoute>} />
            <Route path="/timeline" element={<PrivateRoute><Timeline /></PrivateRoute>} />
            <Route path="/companies" element={<PrivateRoute><Companies /></PrivateRoute>} />
            <Route path="/companies/:id" element={<PrivateRoute><CompanyDetails /></PrivateRoute>} />
            <Route path="/experts" element={<PrivateRoute><Experts /></PrivateRoute>} />
            <Route path="/experts/:id" element={<PrivateRoute><ExpertDetails /></PrivateRoute>} />
            <Route path="/event/:id" element={<PrivateRoute><EventDetail /></PrivateRoute>} />
            <Route path="/myaccount" element={<PrivateRoute><MyAccount /></PrivateRoute>}>
                <Route index element={<PrivateRoute><Profile /></PrivateRoute>} />
                <Route path="edit" element={<PrivateRoute><EditProfile /></PrivateRoute>} />
                <Route path="followers" element={<PrivateRoute><FollowUser type="FOLLOWERS" /></PrivateRoute>} />
                <Route path="following" element={<PrivateRoute><FollowUser type="FOLLOWING" /></PrivateRoute>} />
                <Route path="blocked" element={<PrivateRoute><FollowUser type="BLOCKED" /></PrivateRoute>} />
                <Route path="interests" element={<PrivateRoute><Interests /></PrivateRoute>} />
            </Route>
            <Route path="/bookmarks" element={<PrivateRoute><Bookmarks /></PrivateRoute>} />
            <Route path="/notifications" element={<PrivateRoute><Notifications /></PrivateRoute>} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route path="/flyover" element={<PrivateRoute><Flyover /></PrivateRoute>} />
            <Route exact path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="/tags" element={<RegisterTags />} />
            <Route path="/login" element={<Login />} />
        </Routes>
    )
}

export default Routing;
