import React, { useContext, useEffect, useState } from "react"
import { ReactComponent as BookmarkIcon } from "assets/images/bookmark.svg"
import { authContext } from "contexts/AuthContext"
import { CREATE_BOOKMARK, DELETE_BOOKMARK } from "graphql/mutations";

const Bookmark = (props) => {
    const [bookmark, setBookmark] = useState(false);
    const { getClient } = useContext(authContext);
    let client = getClient();
 
    const updateBookmark = () => {
        bookmark ? setBookmark(false) : setBookmark(true);
        props.updateBookmark(bookmark ? false : true)
        client.mutate({
            mutation:bookmark ? DELETE_BOOKMARK : CREATE_BOOKMARK,
            variables:{
                id:props.id
            }
        }).then(res => {
        }).catch(err => {
            console.log(err.message)
        })
    }

    useEffect(() => {
        setBookmark(props.checked)
    },[props])

    return (
        <span role='button' className="bg-transparent border-0">
            <BookmarkIcon width={props.width} height={props.height} style={{ fill: bookmark ? '#679CF6' : 'transparent' }} onClick={() => updateBookmark()} />
        </span>
    )
}

export default Bookmark;
