import React from "react";
import { Col, Row } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';

const SearchLoader = () => {
    return (
        <>
            <Row className="mb-5">
                <Col>
                    <div style={{ 'boxShadow': '0px 0px 7px 0px rgb(0 0 0 / 20%)', 'borderRadius': '8px', 'padding': '0.75rem' }}>
                        <ContentLoader viewBox="0 0 500 20" width="100%">
                            <rect x="0" y="0" rx="50%" ry="50%" width="1.25rem" height="1.25rem" />
                            <rect x="1.75rem" y="0" rx="2" ry="2" width="94%" height="1.25rem" />
                        </ContentLoader>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default SearchLoader;
