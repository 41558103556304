import React, { useContext, useState } from "react";
import { Form, Button, Card, Spinner, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { useForm } from "react-hook-form";
import bgLogin from 'assets/images/bg-body-login.svg';
import Logo from 'assets/images/logo.png';
import { Link, useNavigate } from "react-router-dom";
import { dataContext } from "contexts/DataContext";

const Register = () => {
    const { registerUserData, setRegisterUserData } = useContext(dataContext);
    const { register , handleSubmit , formState : {errors} } = useForm() 
    const [ passwordShow, setPasswordShow ] = useState(false);
    let navigate = useNavigate()

    const showPassword = () => {
        setPasswordShow(!passwordShow);
    }

    const submit = (data) => {
        setRegisterUserData(data)
        navigate("/tags")
    }

    return (
        <>
            <Container fluid style={{ backgroundImage: `url(${bgLogin})`, backgroundAttachment: 'scroll', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: '50% 100%' }}>
                <Row className="justify-content-center align-items-center mh-100vh login-area">
                    <Col md={8} lg={6} xl={4} xxl={3}>
                        <div className="logo"><img src={Logo} alt="Supertrends" /></div>
                        <Card className="border-0 shadow-lg">
                            <Card.Header
                                className="p-0 border-0 bg-transparent">
                                Register
                            </Card.Header>
                            <Card.Body
                                className="p-0">
                                <Form onSubmit={handleSubmit(submit)}>
                                    <Form.Group
                                        className="mb-4"
                                        controlId="formFirstName">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            {...register("firstName", { required: true , pattern: /^(?!\s)[\w\s-]*$/ })}
                                            className={`${errors.firstName ? "border border-danger" : ""}`}
                                            type="text"
                                            defaultValue={registerUserData?.firstName}
                                            placeholder="Enter your first name" />
                                        {errors.firstName?.type =="required" && <p className="mt-2 ml-2 fs-6 text-danger">** required</p>}
                                        {errors.firstName?.type =="pattern" && <p className="mt-2 ml-2 fs-6 text-danger">** invalid name</p>}
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-4"
                                        controlId="formLastName">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            {...register("lastName", { required: true,  pattern: /^(?!\s)[\w\s-]*$/  })}
                                            className={`${errors.lastName ? "border border-danger" : ""}`}
                                            type="text"
                                            defaultValue={registerUserData?.lastName}
                                            placeholder="Enter your last name" />
                                        {errors.lastName?.type =="required" && <p className="mt-2 ml-2 fs-6 text-danger">** required</p>}
                                        {errors.lastName?.type =="pattern" && <p className="mt-2 ml-2 fs-6 text-danger">** invalid name</p>}
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-4"
                                        controlId="formBasicEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            {...register("email", { required: true , pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                                            className={`${errors.email ? "border border-danger" : ""}`}
                                            type="text"
                                            defaultValue={registerUserData?.email}
                                            placeholder="Enter your email" />
                                        {errors.email?.type == "required" && <p className="mt-2 ml-2 fs-6 text-danger">** required</p>}
                                        {errors.email?.type == "pattern" && <p className="mt-2 ml-2 fs-6 text-danger">** invalid email</p>}
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-4 position-relative"
                                        controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            {...register("password", { required: true , minLength:8 })}
                                            className={`${errors.email ? "border border-danger " : ""}`}
                                            type={passwordShow ? 'text' : 'password'}
                                            defaultValue={registerUserData?.password}
                                            placeholder="Enter your Password"
                                        />
                                        {errors.password?.type == "required" && <p className="mt-2 ml-2 fs-6 text-danger">** required</p>}
                                        {errors.password?.type == "minLength" && <p className="mt-2 ml-2 fs-6 text-danger">** min 8 characters</p>}
                                        <Button
                                            onClick={showPassword}
                                            className={`text-decoration-none text-color-default p-0 m-0 position-absolute ${errors.password ? 'top-50' : 'bottom-0'} end-0 translate-middle`}
                                            variant="link"
                                            type="button">
                                            {passwordShow ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                                        </Button>
                                    </Form.Group>
                                    <Button
                                        variant="primary"
                                        type="submit">
                                        Next
                                        {/* {!loading ? "Login" : <Spinner animation="grow" size="sm" variant="light" />} */}
                                    </Button>
                                    <p
                                        className="text-center mt-4 mb-0 register-note">
                                        Already have an Account? &nbsp;
                                        <Link
                                            className="text-decoration-none p-0 m-0"
                                            to="/login">
                                            Login here
                                        </Link></p>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Register;
