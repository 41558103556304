import React from 'react';
import placeholder from "assets/images/placeholder.png"
import './lightspeedslide.scss';

const LightspeedSlide = (props) => {
    return(
        <div className='lightspeed-widget'>
            <div className='image-holder'><img src={props.data.imageUrl ? props.data.imageUrl : placeholder} alt="slide image" /></div>
            <div className='content-holder text-center'>
                <h2 className='text-center'>in {props.data.year}</h2>
                <span>Most anticipated</span>
                <p>{props.data.title}</p>
            </div>
        </div>
    );
}

export default LightspeedSlide;