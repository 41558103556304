import { authContext } from "contexts/AuthContext";
import React, { useContext } from "react"
import { Navigate } from "react-router-dom";

const PrivateRoute = ({children}) => {
    const { auth } = useContext(authContext);
    //const navigate = useNavigate();

    if(!auth.loading){
        if (auth.data != null) {
            return children
        }
        else{
            return <Navigate to="/login" replace="true" />
        }
    }

}

export default PrivateRoute;
