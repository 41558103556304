import React, { useEffect, useRef, useState } from "react"
import "./wrapper.scss"
import Wrapper from "components/wrapper/Wrapper"
import { Button, Container } from "react-bootstrap";
import CloseIcon from "assets/images/icon-close.svg"
import { useLocation } from "react-router-dom";

const MenuWrapper = ({ show, handleClose, children }) => {
    const [open, setOpen] = useState(false);
    let menuRef = useRef()
    let location = useLocation();

    useEffect(() => {
        setOpen(show)
    }, [show])

    useEffect(() => {
        let handler = (event) => {
            if (!menuRef.current?.contains(event.target)) {
                handleClose(false)
            }
        }
        document.addEventListener("mouseup", handler)

        return (() => {
            document.removeEventListener("mouseup", handler)
        })
    }) // detecting clickoutside


    useEffect(() => {
        handleClose(false)
    }, [location.pathname]) // autoclose menu on route change

    return (
        <>
            {open &&
                <div ref={menuRef} className="menuWrapper">
                    <div className="menuContentWrapper">
                        <Wrapper>
                            <Container fluid>
                                {children}
                            </Container>
                        </Wrapper>
                        <Button
                            type="button"
                            className="menuCloseBtn"
                            onClick={() => handleClose(!show)}
                        >
                            <img src={CloseIcon} alt="" />
                        </Button>
                    </div>
                </div>
            }
        </>
    )
}

export default MenuWrapper
