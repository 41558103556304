import { dataContext } from 'contexts/DataContext';
import React, { useContext, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import placeholder from "assets/images/placeholder.png"
import './profile.scss';
import { useNavigate } from 'react-router-dom';
import { authContext } from 'contexts/AuthContext';


const Profile=()=>{
    const {data} = useContext(dataContext);
    const { setAuthData } = useContext(authContext);
    const navigate = useNavigate();

    const logout = () => {
        setAuthData(null);
        navigate("/login");
    }

    if(data == null || data == undefined){
        return (
            <></>
        )
    } else return(
        <div className="account-details-block">
            <div className='mx-md-5'>
                <div className='account-header'>
                    <div className='image-holder'><img src={data.avatar.url ? data.avatar.url : placeholder} alt="Description" /></div>
                    <strong>{data.firstName} {data.lastName}</strong>
                </div>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridFirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder={data.firstName} defaultValue={data.firstName} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridLastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder={data.lastName} defaultValue={data.lastName}/>
                    </Form.Group>
                </Row>

                <Form.Group className="mb-3" controlId="formGridEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder={data.email} defaultValue={data.email}/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGridBio">
                    <Form.Label>Bio</Form.Label>
                    <Form.Control as="textarea" placeholder={data.biographyBlock.plainText} defaultValue={data.biographyBlock.plainText}/>
                </Form.Group>

                <Button variant="primary" onClick={logout}>
                    Logout
                </Button>
            </div>
        </div>
    );
}

export default Profile;