import React from "react";
import "./news.scss"
import moment from "moment";
import Reactions from "components/reactions/Reactions";
import { Link } from "react-router-dom";
import placeholder from "assets/images/placeholder.png"

const News = (props) => {
    const { data } = props;
    
    return(
        <div className="news">
            <Link to={props.to}>
                <div className="content">
                    <img width="100%" src={data.featuredImage?.thumbnailUrl ?? placeholder} alt="" />
                    <time>{moment.unix(data.insertedAt).format("MMM DD, YYYY")}</time>
                    <h1>{data.title}</h1>
                </div>
            </Link>
            <hr />
            <div className="content d-flex justify-content-between">
                <div className="d-flex align-items-center gap-2">
                <img className="authorImg" src={data.createdBy.avatar.url} alt="" />
                    <p className="m-0 authur-name">{data.createdBy.firstName + " " + data.createdBy.lastName}</p>
                </div>
                <div className="d-flex">
                    <Reactions bookmark gap="gap-2" id={data.id} rated={data.rated} bookmarked={data.viewerHasBookmarked} width=".8125rem" height=".8125rem" />
                </div>
            </div>
        </div>
    )
}

export default News