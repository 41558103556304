import { Button, Modal, Dropdown, Form, Spinner } from 'react-bootstrap';
import { useContext, useState } from 'react';
import { authContext } from 'contexts/AuthContext';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { toast } from 'react-toastify';
import { CREATE_FEEDBACK } from 'graphql/mutations';
import {v4 as uuidv4 } from 'uuid';

const ReportCommentAlertModal = (props) => {
    
    
    const { getClient } = useContext(authContext);
    const [loading, setLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const handleModalShow = () => setModalShow(true);
    const handleModalClose = () => setModalShow(false);
    
    const schema = yup.object({
        title:yup.string().required(),
        body:yup.string().required()
    })
    
    const { register, handleSubmit, formState:{ errors },reset } = useForm({
        resolver: yupResolver(schema)
    });

    const cancel = ()=>{
        reset({
            title:"",
            body:"",
        });
    } 
    
    let client = getClient();
    
    const onSubmit = data => {
        setLoading(true);

        client.mutate({
            mutation: CREATE_FEEDBACK,
            variables:{
                objectId: props.resourceID,
                title: data.title,
                body: data.body,
                requestId: uuidv4()   
            }
        }).then(res=>{
            setLoading(false);
            cancel();
            setModalShow(false);
            toast.success(`Thank you for reporting. Sent Successfully!`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }).catch(err=>{
            setLoading(false);
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }
    return (
        <>
            <Dropdown.Item onClick={() => { handleModalShow(true) }} href="#/action-3">Report</Dropdown.Item>
            <Modal className='comments-modals' show={modalShow} onHide={handleModalClose} animation={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-3" controlId="titleControl">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="What is your concern about?" {...register("title")}
                                className={`${errors.title ? "border border-danger" : ""}`}
                            />
                            <p className='text-danger'>{errors.title?.message}</p>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="messageControl">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3} {...register("body")}
                                className={`${errors.body ? "border border-danger" : ""}`}
                            />
                            <p className='text-danger'>{errors.body?.message}</p>
                        </Form.Group>
                        <div className='text-center'>
                            <Button className='px-4' variant="primary" type="submit" disabled={loading}>
                                {!loading ? "Report" : <Spinner animation="grow" size="sm" variant="light" />}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ReportCommentAlertModal;