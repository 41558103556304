import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CompanySlider from 'components/company/CompanySlider';
import Milestones from 'components/milestones/Milestones';
import NewsFeed from 'components/newsFeed/NewsFeed';
import Wrapper from 'components/wrapper/Wrapper';
import { authContext } from 'contexts/AuthContext';
import { dataContext } from 'contexts/DataContext';
import { ARTICLES, COMPANIES, EVENTS } from 'graphql/queries';
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Button, Col, Container, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { useForm } from 'react-hook-form';
import Spinner from 'react-bootstrap/Spinner';
import './search.scss';
import NewsfeedLoader from 'components/loaders/NewsfeedLoader';
import EventLoader from 'components/loaders/EventsLoader';
import SearchLoader from 'components/loaders/SearchLoader';

const reducer = (loading, action) => {
    if (action == "articles") {
        return {
            "articles": !loading.articles,
            "events": loading.events,
            "companies": loading.companies
        }
    }
    else if (action == "events") {
        return {
            "articles": loading.articles,
            "events": !loading.events,
            "companies": loading.companies
        }
    }
    else if (action == "companies") {
        return {
            "articles": loading.articles,
            "events": loading.events,
            "companies": !loading.companies
        }
    }
}

const Search = () => {
    const { data } = useContext(dataContext);
    const [articles, setArticles] = useState([]);
    const [events, setEvents] = useState([]);
    const [companies, setCompanies] = useState([]);
    const { getClient } = useContext(authContext);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, dispatchLoading] = useReducer(reducer, { articles: false, events: false, companies: false });
    let client = getClient();
    const searchRef = useRef({});

    const search = (data) => {
        dispatchLoading("articles");
        dispatchLoading("events");
        dispatchLoading("companies");

        //Artilces
        client.query({
            query: ARTICLES,
            variables: {
                textSearch: data.search,
            }
        }).then(res => {
            setArticles(res.data.articlesConnection);
            dispatchLoading("articles");
        }).catch(err => {
            console.log(err);
            dispatchLoading("articles");
        })

        // Events
        client.query({
            query: EVENTS,
            variables: {
                textSearch: data.search,
            }
        }).then(res => {
            setEvents(res.data.eventsConnection);
            dispatchLoading("events");

        }).catch(err => {
            console.log(err);
            dispatchLoading("companies");
        })

        // Companies
        client.query({
            query: COMPANIES,
            variables: {
                textSearch: data.search,
            }
        }).then(res => {
            setCompanies(res.data.companiesConnection);
            dispatchLoading("companies");
        }).catch(err => {
            console.log(err);
            dispatchLoading("companies");
        })
    }

    const loadArticles = () => {
        dispatchLoading("articles");
        client.query({
            query: ARTICLES,
            variables: {
                after: articles.pageInfo.endCursor
            }
        }).then(res => {
            let tempData = articles;
            tempData.nodes.push(...res.data.articlesConnection.nodes);
            tempData.pageInfo = res.data.articlesConnection.pageInfo;

            setArticles({ ...tempData });
            dispatchLoading("articles");
        }).catch(err => {
            console.log(err)
            dispatchLoading("articles");
        })
    }

    const loadEvents = () => {
        dispatchLoading("events");
        client.query({
            query: EVENTS,
            variables: {
                after: events.pageInfo.endCursor
            }
        }).then(res => {
            let tempData = events;

            tempData.nodes.push(...res.data.eventsConnection.nodes);
            tempData.pageInfo = res.data.eventsConnection.pageInfo;

            setEvents({ ...tempData });
            dispatchLoading("events")
        }).catch(err => {
            console.log(err);
            dispatchLoading("events")
        })
    }

    const loadComapanies = () => {
        dispatchLoading("companies");
        client.query({
            query: COMPANIES,
            variables: {
                after: companies.pageInfo.endCursor
            }
        }).then(res => {
            let tempData = companies;

            tempData.nodes.push(...res.data.companiesConnection.nodes);
            tempData.pageInfo = res.data.companiesConnection.pageInfo;

            setCompanies({ ...tempData });
            dispatchLoading("companies");
        }).catch(err => {
            console.log(err);
            dispatchLoading("companies");
        })
    }

    const clearSearch = () => {
        searchRef.current.value = "";
    }

    useEffect(() => {
        setArticles(data?.recommendedArticlesConnection);
        setEvents(data?.futureEventsConnection == (undefined || null) ? { nodes: [] } : data?.futureEventsConnection);
        setCompanies(data?.related?.companies);
    }, [data])

    const Loader = () => {
        return (
            <>
                <h1 className='sec-heading'>Search</h1>
                <SearchLoader />
                <h1 className="sec-heading">Latest NewsFeed</h1>
                <NewsfeedLoader />
                <h1 className="sec-heading">Related Milestones</h1>
                <EventLoader />
            </>
        )
    }

    return (
        <Wrapper>
            <Container fluid className='pt-5 search-page'>
                {data ?
                    <>
                        <Container fluid>
                            <h1 className='sec-heading'>Search</h1>
                            <div className="mb-3 search-area">
                                <Form onSubmit={handleSubmit(search)}>
                                    <Form.Control
                                        {...register("search", { required: true })}
                                        placeholder="Search"
                                        aria-label="Search"
                                        ref={searchRef}
                                    />
                                    <Button type='submit' variant="link" className='btn-search' onClick={search}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Button>
                                    {loading.articles || loading.events || loading.companies ?
                                        <Button variant="link" className='btn-remove'>
                                            <Spinner className='btn-remove' size='sm' animation="grow" role="status" variant="primary"></Spinner>
                                        </Button>
                                        :
                                        <Button variant="link" className='btn-remove' onClick={() => clearSearch()}>
                                            <FontAwesomeIcon icon={faClose} />
                                        </Button>
                                    }
                                </Form>
                            </div>
                        </Container>
                        <div className="my-5">
                            <NewsFeed heading="Latest Newsfeed" data={articles?.nodes} />
                            <div className='container-fluid'>
                                {loading.articles && <NewsfeedLoader />}
                                {articles?.pageInfo?.hasNextPage && !loading.articles && <span className='text-primary pointer' onClick={() => loadArticles()}>Load More</span>}
                            </div>
                        </div>
                        <div className="my-5">
                            <Milestones heading={"Related Milestones"} data={events?.nodes} />
                            <div className='container-fluid'>
                                {loading.events && <EventLoader /> }
                                {events?.pageInfo?.hasNextPage && !loading.events && <span className='text-primary pointer' onClick={() => loadEvents()}>Load More</span>}
                            </div>
                        </div>
                        <div className="my-5">
                            <CompanySlider data={companies?.nodes} />
                            <div className='container-fluid'>
                                {loading.companies && <Spinner animation="border" role="status" variant="primary" ></Spinner>}
                                {companies?.pageInfo?.hasNextPage && !loading.companies && <span className='text-primary pointer' onClick={() => loadComapanies()}>Load More</span>}
                            </div>
                        </div>
                    </> :
                    <Loader />
                }
            </Container>
        </Wrapper>
    );
}

export default Search;