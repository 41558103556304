import React, { useContext, useEffect, useState } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import News from "./News"

const NewsFeed = (props) => {
    const [listItems, setListItems] = useState(6);

    return (
        <Container fluid className="latest-newsfeeds">
            <h1 className="sec-heading">{props.heading}</h1>
            <Row>
                {props.data ?
                    props.data.length > 0 ?
                        props.data.filter((el, i) => (!props.moreBtn || i < listItems)).map(el => {
                            return (
                                <Col key={el.id} sm={6} md={4} lg={3} xl={props?.cols ? props.col : 2}>
                                    <div className="link-holder">
                                        <News to={`../article/${el.id}`} data={el} />
                                    </div>
                                </Col>
                            )
                        }) : <p>No Articles Found!</p> : <p>Loading...</p>}
            </Row>
            {props.moreBtn && 
                <div className="text-end">
                    <Button hidden={listItems == props.data?.length + 1 || props.data?.length < 6} onClick={() => setListItems(props.data.length + 1)} style={{ 'color': '#679CF6' }} className="text-decoration-none" variant="link">More</Button>
                </div>
            }
        </Container>
    )
}

export default NewsFeed
