import React, { useContext, useEffect, useRef, useState } from 'react';
import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import User from 'components/User/User';
import Wrapper from 'components/wrapper/Wrapper';
import { authContext } from 'contexts/AuthContext';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { USERS } from "graphql/queries";
import { toast } from 'react-toastify';
import './usersearch.scss';

const UserSearch = () => {
    const [data, setData] = useState({});
    const [removeShow, setRemoveShow] = useState();
    const { getClient } = useContext(authContext);
    const [loading, setLoading] = useState(false)
    const searchRef = useRef();
    let client = getClient();

    
    const debounceFunc = (func, delay) => {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, delay)
        }
    }
    
    const searchUser = (e) => {
        setLoading(true);
        
        client.query({
            query: USERS,
            variables: {
                textSearch: e.target.value
            }
        }).then(res => {
            setLoading(false)
            setData(res.data.registeredUsersConnection)
        }).catch(err => {
            setLoading(false)
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }

    const loadMoreUsers = () => {
        setLoading(true);
        
        client.query({
            query: USERS,
            variables: {
                textSearch: "",
                after: data.pageInfo.endCursor
            }
        }).then(res => {
            setLoading(false)
            let tempData = data;
            tempData.nodes.push(...res.data.registeredUsersConnection.nodes);
            tempData.pageInfo = res.data.registeredUsersConnection.pageInfo;
            setData({...tempData})
        }).catch(err => {
            setLoading(false)
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }
    
    const optimisedSearchHandler = debounceFunc(searchUser, 500);

    const clearSearch = () => {
        searchRef.current.value = "";
    }

    useEffect(() => {
        searchUser({target:{value:""}});
    },[])
    
    return (
        <Wrapper>
            <Container fluid className='pt-5 search-page'>
                <Container fluid>
                    <h1 className='sec-heading'>Users</h1>
                    <div className="mb-3 search-area">
                        <Form.Control
                            placeholder="Search user by name"
                            aria-label="User Search"
                            aria-describedby="basic-addon2"
                            onClick={() => { setRemoveShow(!removeShow) }}
                            onChange={(e) => optimisedSearchHandler(e)}
                            ref={searchRef}
                        />
                        <Button variant="link" className='btn-search'>
                            <FontAwesomeIcon icon={faSearch} />
                        </Button>
                        <Button variant="link" className='btn-remove' onClick={() => clearSearch()} >
                            <FontAwesomeIcon icon={faClose} />
                        </Button>
                    </div>
                </Container>
                <Container fluid className='mt-5'>
                    <Row>
                        {data?.nodes?.length > 0 ? data?.nodes.map((user, i) => {
                            return (
                                <Col md={6} lg={4} xl={3}>
                                    <User key={i} data={user} />
                                </Col>
                            )
                        }) : <p>No user found!</p>}
                        {loading &&
                            <div className='d-flex align-items-center justify-content-center'>
                                <Spinner className='mb-4' animation="grow" size="md" variant="primary" />
                            </div>
                        }
                        {!loading && data?.pageInfo?.hasNextPage && <span className='text-primary pointer mb-4 text-center' onClick={() => loadMoreUsers()}>Load More</span>}
                    </Row>
                </Container>
            </Container>
        </Wrapper>
    );
}

export default UserSearch;