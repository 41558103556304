import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as Like } from "assets/images/like.svg"
import { ReactComponent as Dislike } from "assets/images/dislike.svg"
import { authContext } from "contexts/AuthContext";
import { UPDATE_REACTIONS } from "graphql/mutations";
import Bookmark from "./Bookmark";

const Reactions = (props) => {
    const { getClient } = useContext(authContext);
    const [like, setLike] = useState(false);
    const [dislike, setDislike] = useState(false);
    const [bookmark , setBookmark] = useState(false)
    const [ reactionCounters , setReactionCounters ] = useState({like:0,dislike:0})
    let client = getClient();

    const updateReaction = (value, key) => {
        if (key == "LIKE" && value == "LIKE") {
            setLike(true)
            setDislike(false)
        }
        else if (key == "LIKE" && value == null) {
            setLike(false)
        }
        
        else if (key == "DISLIKE" && value == "DISLIKE") {
            setDislike(true)
            setLike(false)
        }
        else if (key == "DISLIKE" && value == null) {
            setDislike(false)
        }

        client.mutate({
            mutation: UPDATE_REACTIONS,
            variables: {
                id: props.id,
                value: value
            }
        }).then(res => {
            if(res.data?.updateReaction?.reactionCounters){
                setReactionCounters({...res.data?.updateReaction?.reactionCounters})
            }
        }).catch(err => {
            console.log(err.message)
        })
    }

    useEffect(() => {
        if (props.rated == "LIKE") {
            setLike(true)
        }
        else if(props.rated == "DISLIKE") {
            setDislike(true)
        }
        setBookmark(props.bookmarked)
        if(props.reactionCounters){
            setReactionCounters(props.reactionCounters)
        }
    }, [props])

    return (
        <div className={`d-flex ${props.gap}`}>
            <span role='button' className="bg-transparent border-0" onClick={() => updateReaction(like ? null : "LIKE", "LIKE")}>
                <Like width={props.width} height={props.height} style={{ fill: like ? '#679CF6' : 'transparent' }} />
                {props.reactionCounters && <span style={{marginLeft:"7px",fontSize:"0.9em"}}>{reactionCounters.like}</span>}
            </span>
            {props.bookmark && <Bookmark width={props.width} height={props.height} id={props.id} checked={bookmark} updateBookmark={setBookmark} />}
            <span role='button' className="bg-transparent border-0" onClick={() => updateReaction(dislike ? null : "DISLIKE", "DISLIKE")}>
                <Dislike  width={props.width} height={props.height} style={{ fill: dislike ? '#679CF6' : 'transparent' }} />
                {props.reactionCounters && <span style={{marginLeft:"7px",fontSize:"0.9em"}}>{reactionCounters.dislike}</span>}
            </span>
        </div>
    )
}

export default Reactions;
