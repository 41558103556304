import { faComment, faEllipsis, faMessage, faReply, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddComment from 'components/addComment/AddComment';
import DeleteCommentAlertModal from 'components/DeleteCommentAlertModal/DeleteCommentAlertModal';
import ReportCommentAlertModal from 'components/ReportCommentAlertModal/ReportCommentAlertModal';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import Logo from '../../assets/images/logo.png';
import './subcommentblock.scss';

const SubCommentBlock = (props) => {

    const [editing, setEditing] = useState(false);

    const commentOptionsRef = useRef(null);
    const toggleCommentOptions = () => {
        commentOptionsRef.current.classList.toggle("d-none");
    }

    const replyComment = useRef(null);
    const togglereplyComment = () => {
        replyComment.current.classList.toggle("d-none");
    }

    const MyLoader = (props) => (
        <ContentLoader
            speed={2}
            width="100%"
            height="12vh"
            viewBox="0 0 100% 1080"
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
            {...props}
        >
            <rect x="0" y="0" rx="50%" ry="50%" width="2rem" height="2rem" />
            <rect x="6%" y="6" rx="0" ry="0" width="15%" height="20" />
            <rect x="25%" y="7" rx="0" ry="0" width="12%" height="18" />
            <rect x="0" y="5vh" rx="0" ry="0" width="50%" height="18" />
            <rect x="0" y="9.4vh" rx="50%" ry="50%" width="1.5rem" height="1.5rem" />
            <rect x="6%" y="9.4vh" rx="50%" ry="50%" width="1.5rem" height="1.5rem" />
            <rect x="12%" y="9.4vh" rx="50%" ry="50%" width="1.5rem" height="1.5rem" />
            <rect x="96.5%" y="9.4vh" rx="50%" ry="50%" width="1.5rem" height="1.5rem" />
        </ContentLoader>
    )

    return (
        <>
            {/* <div className="comment-area py-3 px-3 px-md-5">
                <MyLoader />
            </div> */}
            {editing ? (<AddComment resourceID={props.commentData.id} update={true} value={props.commentData.text} cancelCallback={() => setEditing(false)} onAddCallback={props.onEditCallback} />) : (
                <div className="comment-area py-3 px-3 px-md-5">
                    <div className="comment-header d-flex flex-wrap justify-content-between align-items-center">
                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                            <div className="image-holder"><img src={props.commentData.createdBy.avatar.url} alt={props.commentData.createdBy.firstName + " " + props.commentData.createdBy.lastName} /></div>
                            <strong>{props.commentData.createdBy.firstName + " " + props.commentData.createdBy.lastName}</strong>
                            <time>{moment.unix(props.commentData.insertedAt).fromNow()}</time>
                        </div>
                        <div className='position-relative'>
                            <Dropdown>
                                <Dropdown.Toggle className='p-0' variant="link" id="dropdown-basic">
                                    <FontAwesomeIcon icon={faEllipsis} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {props.authData.id == props.commentData.createdBy.id && <>
                                        <Dropdown.Item onClick={()=>setEditing(true)}>Edit</Dropdown.Item>
                                        <DeleteCommentAlertModal resourceID={props.commentData.id} onDeleteCallback={props.onDeleteCallback}/>
                                    </>}
                                    <ReportCommentAlertModal resourceID={props.commentData.id} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <p>{props.commentData.text}</p>
                </div>
            )}
        </>
    );
}

export default SubCommentBlock;