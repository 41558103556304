import { authContext } from "contexts/AuthContext"
import { BOOKMARKED_COMPANIES } from "graphql/queries";
import { useContext, useEffect, useState } from "react"
import { Alert } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { toast } from "react-toastify";
import SingleBookmarkedCompanies from "./SingleBookmarkedCompanies";
import placeholder from "assets/images/placeholder.png";

export default function BookmarkedCompanies(props) {

    const { getClient } = useContext(authContext);

    const [bookmarks, setBookmarks] = useState([]);
    const [loading, setLoading] = useState(true);

    const client = getClient();

    useEffect(() => {
        client.query({
            query: BOOKMARKED_COMPANIES
        }).then(res => {
            setLoading(false);
            setBookmarks(res.data.viewer.bookmarkedCompaniesConnection.nodes);
        }).catch(err => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        });
    }, [])

    const handleOnDelete = (id,index) => {

        props.onDelete(id).then(res=>{

            bookmarks.splice(index,1);
            setBookmarks([...bookmarks]);
        }).catch(err=>{
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }
    const MyLoader = (props) => (
        <ContentLoader
            speed={2}
            width="100%"
            height="10vh"
            viewBox="0 0 100% 1080"
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
            {...props}
        >
            <rect x="0" y="0" rx=".5rem" ry=".5rem" width="5.65rem" height="100%" />
            <rect x="6.8rem" y="4vh" rx="0" ry="0" width="16%" height="20" />

        </ContentLoader>
    )

    return (
        <div className='px-lg-5 milestones-tab companies-tab'>
            { loading ? 
                
                Array(5).fill(0).map(()=><div className="d-flex flex-wrap justify-content-between align-items-center milestone-single"><MyLoader /></div>)
            : bookmarks.length > 0 ? bookmarks.map((data, index) =>
                <SingleBookmarkedCompanies key={data.id} link={data.id} image={data?.featuredImage?.originalUrl ?? placeholder} name={data?.name} location={data?.location} onDeleteCallback={()=>handleOnDelete(data.id,index)}/>)
             : (<Alert variant="dark">No Bookmarked Companies were Found!</Alert>)}

        </div>
    )
}