import OurExpert from 'components/OurExpert/OurExpert';
import { authContext } from 'contexts/AuthContext';
import { EXPERTS } from 'graphql/queries';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import './experts.scss';

const Experts = () => {
    const [data, setData] = useState(null)
    const { getClient } = useContext(authContext);
    let client = getClient();

    useEffect(() => {
        client.query({
            query: EXPERTS
        }).then(res => {
            setData(res.data.expertsConnection.nodes)
        }).catch(err => {
            console.log(err.message)
        })
    }, [])

    const MyLoader = (props) => (
        <ContentLoader
            speed={2}
            width="100%"
            height="23vh"
            viewBox="0 0 100% 1080"
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
            {...props}
        >
            <rect x="36%" y="0" rx="50%" ry="50%" width="4.625rem" height="4.625rem" />
            <rect x="16%" y="11vh" rx="0" ry="0" width="66%" height="20" />
            <rect x="14" y="18.5vh" rx=".25rem" ry=".25rem" width="90%" height="36" />

        </ContentLoader>
    )
    const MobLoader = (props) => (
        <ContentLoader
            speed={2}
            width="100%"
            height="26vh"
            viewBox="0 0 100% 1080"
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
            {...props}
        >
            <rect x="36%" y="0" rx="50%" ry="50%" width="3.625rem" height="3.625rem" />
            <rect x="17%" y="11vh" rx="0" ry="0" width="66%" height="20" />
            <rect x="10" y="20vh" rx=".25rem" ry=".25rem" width="88%" height="32" />

        </ContentLoader>
    )

    return (
        <Container fluid>
            <Row className='py-4 py-md-5'>
                <Col>
                    <h1>Supertrends Experts</h1>
                </Col>
            </Row>
            <div className="companies-grid pb-5">
                {
                    data != null ? ( data.length > 0 ? data.map((el, i) => <OurExpert key={i} data={el} />) : <p>No Experts!</p> ) : ( 
                        new Array(12).fill(0).map(el => <div className='column expert content-loader position-relative'>
                            <div className='image-holder mb-0'></div>
                            <div className='position-relative zIndex-2 loader-area d-none d-md-block'>
                                <MyLoader />
                            </div>
                            <div className='position-relative zIndex-2 loader-area-mob d-md-none'>
                                <MobLoader />
                            </div>
                        </div>)
                    )
                }
            </div>
        </Container>
    );
}

export default Experts;