import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { Button, Col, Container, Row } from "react-bootstrap";
import Wrapper from "components/wrapper/Wrapper";
import "./event.scss"
import CompanySlider from "components/company/CompanySlider";
import { useNavigate, useParams } from "react-router-dom";
import { authContext } from "contexts/AuthContext";
import { toast } from "react-toastify";
import { EVENT } from "graphql/queries";
import CommentSection from "components/commentSection/CommentSection";
import YearChart from "pages/YearChart/YearChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import ImportanceRange from "components/importanceRange/ImportanceRange";
import Milestones from "components/milestones/Milestones";
import NewsFeed from "components/newsFeed/NewsFeed";
import ReactPinField from "react-pin-field";
import { CREATE_PREDICTION } from "graphql/mutations";
import { v4 as uuidv4 } from 'uuid';
import Reactions from "components/reactions/Reactions";
import placeholder from "assets/images/placeholder.png"
import ContentLoader from "react-content-loader";

const EventDetail = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const { getClient } = useContext(authContext);
    let client = getClient();
    const [data, setData] = useState(null)
    let pinRef = useRef()
    const [submitted, setSubmitted] = useState(false)

    const changeViewerYear = (yr) => {
        if (parseInt(yr) < moment().year() && parseInt(yr) != -1) {
            toast.error("Year is not in future!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return
        }

        setSubmitted(true)

        client.mutate({
            mutation: CREATE_PREDICTION,
            variables: {
                eventId: id,
                requestId: uuidv4(),
                year: parseInt(yr)
            }
        }).then(res => {
            let tempData = { ...data };
            if (tempData.viewerLatestPrediction != null) {
                tempData.viewerLatestPrediction.year = parseInt(yr);
            }
            else {
                tempData.viewerLatestPrediction = {
                    "year": parseInt(yr)
                };
            }
            tempData.predictionAggregate = res.data.createPrediction.event.predictionAggregate;

            setData(tempData)
            setYearData(res.data.createPrediction.event.viewerLatestPrediction.year)

        }).catch(err => {
            toast.error("Error Submitting Year!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }

    const setYearData = (year) => {
        if (year != -1) {
            year.toString().split("").forEach((el, i) => {
                pinRef.current.inputs[i].value = el;
            })
        } else {
            "0000".split("").forEach((el, i) => {
                pinRef.current.inputs[i].value = "";
            })
        }
    }

    useEffect(() => {
        setData(null)
        client.query({
            query: EVENT,
            variables: {
                id: id
            }
        }).then(res => {
            setData(res.data.event)
            if (res.data.event?.viewerLatestPrediction?.year && res.data.event?.viewerLatestPrediction?.year != -1) {
                setYearData(res.data.event.viewerLatestPrediction.year)
                setSubmitted(true)
            }

        }).catch(err => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }, [id])

    useEffect(() => {
        if (document.querySelector(".heading-text") != null) {
            document.querySelector(".heading-text").remove()
        }
        if (document.querySelector(".event-heading") != null) {
            document.querySelector(".event-heading").remove()
        }
    }, [data])

    const MyLoader = (props) => (
        <ContentLoader
            speed={2}
            width="100%"
            height={1080}
            viewBox="0 0 100% 1080"
            backgroundColor="#f0f0f0"
            foregroundColor="#dedede"
            {...props}
        >
            <rect x="1" y="0" rx="10" ry="10" width="100%" height="45vh" />
            <rect x="10%" y="50vh" rx="10" ry="10" width="80%" height="30" />
            <rect x="35%" y="56vh" rx="8" ry="8" width="30%" height="30" />
            <rect x="42%" y="63vh" rx="5" ry="5" width="16%" height="20" />
            <rect x="0" y="70vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="75vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="80vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="85vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="90vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="95vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="100vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="105vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="1010vh" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="1015vh" rx="5" ry="5" width="100%" height="30" />

        </ContentLoader>
    )

    return (
        <Wrapper>
            <Container fluid>
                <div className="eventDetail">
                    <div style={{ overflow: "hidden", padding: "1rem 0 0" }}>
                        <Button className="btn-back" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Go Back</Button>
                    </div>
                    {data ?
                        <>
                            <div className="img-holder">
                                <img className="articleImg mx-auto" src={data?.featuredImage?.largeUrl ? data?.featuredImage?.largeUrl : placeholder} alt="" />
                            </div>
                            <header className="articleHeader">
                                <h1 className="text-center m-0">{data?.title}</h1>
                                <p className="text-center font-italic text-small mt-2 mt-md-4"><em>Last updated on {data?.insertedAt ? moment.unix(data?.insertedAt).format("MMM DD, YYYY") : "--"}</em></p>
                            </header>
                            <div className="articleContent" dangerouslySetInnerHTML={{ __html: data?.htmlBlock }}></div>
                            <Row className="articleNavigation align-items-center">
                                <Col className="d-flex justify-content-start">
                                    {/* <p className="d-flex align-items-center m-0"><img src={IconArrowBack} alt="" />&nbsp; Back</p> */}
                                </Col>
                                <Col className="d-flex justify-content-center event-reactions">
                                    <Reactions bookmark gap="gap-3" width="1.25rem" height="1.25rem" id={id} rated={data.rated} canRate={data.viewerCanRate} bookmarked={data.viewerHasBookmarked} />
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    {/* <p className="d-flex align-items-center m-0">Next&nbsp;<span className="d-none d-md-inline">Article</span> &nbsp;<img src={Rocket} alt="" /></p> */}
                                </Col>
                            </Row>
                            <hr />
                            <Row className="tags-area">
                                <header className="mb-4">
                                    <h2>Tags</h2>
                                    <div>
                                        {data?.tags && data?.tags.map(el => {
                                            return (
                                                <span key={el.id} className="badge rounded-pill mb-2">{el.name}</span>
                                            )
                                        })}
                                    </div>
                                </header>
                                <div>
                                    {!submitted && <h2 className="mb-3">When do think that’s gonna happen?</h2>}
                                    <ReactPinField className={`pin-year ${submitted ? "d-none" : ""}`} ref={pinRef} length={4} placeholder={0} onComplete={e => changeViewerYear(e)} />
                                    {data.viewerLatestPrediction && <YearChart data={data?.predictionAggregate} />}
                                    <p className="text-center text-small mt-3">{data?.predictionAggregate.totalCount > 0 ? ((data?.predictionAggregate.neverCount / data?.predictionAggregate.totalCount) * 100).toFixed(2) : "0"}% THINK, THIS EVENT WILL NEVER HAPPEN</p>
                                    {submitted ?
                                        <Button className="my-4 shadow-sm rounded-3 prediction-field overflow-hidden" onClick={() => setSubmitted(false)}>
                                            Resubmit
                                            <Button variant="outline-secondary" id="button-addon2">
                                                <FontAwesomeIcon icon={faAngleRight} />
                                            </Button>
                                        </Button>
                                        :
                                        <Button className="my-4 shadow-sm rounded-3 prediction-field overflow-hidden" onClick={() => changeViewerYear(-1)}>
                                            I think this will never happen
                                            <Button variant="outline-secondary" id="button-addon2">
                                                <FontAwesomeIcon icon={faAngleRight} />
                                            </Button>
                                        </Button>
                                    }
                                    {data?.predictedImpacts && data?.predictedImpacts.map(el => {
                                        return (
                                            <ImportanceRange eventId={id} key={el.id} eventKey={el.key} heading={el.message} stats={el.statistics} userValue={el.yourValue} />
                                        )
                                    })}
                                </div>
                            </Row>
                            <CommentSection resourceID={data?.id || null} commentsData={data?.commentConnection || { nodes: [] }} />
                            <Row className="my-5">
                                <Milestones cols={6} heading={"Related Milestones"} data={data?.related?.events} />
                            </Row>
                            <Row className="my-5">
                                <NewsFeed cols={4} heading={"Related Articles"} data={data?.related?.articles} />
                            </Row>
                            <Row className="my-5">
                                <CompanySlider data={data?.related?.companies} />
                            </Row>
                        </> : <MyLoader />}
                </div>
            </Container>
        </Wrapper>
    )
}

export default EventDetail
