import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './trendingcompany.scss'

const TrendingCompany=(props)=>{
    return(
        <div className='column'>
            <div className='image-holder'><img src={props?.data?.featuredImage?.originalUrl} alt="Supertrends" /></div>
            <div className='content-holder'>
                <header>
                    <h2>{props?.data?.name}</h2>
                    <p>{props?.data?.location}</p>
                </header>
                <div className='company-content-holder'>
                     <p><span>{props.data.tableEntries.filter(el => el.key == "Location")?.[0]?.value ? props.data.tableEntries.filter(el => el.key == "Location")?.[0]?.value : "NA"}</span></p>
                     <p><span><strong>Year Founded:</strong> {props.data.tableEntries.filter(el => el.key == "Year founded")?.[0]?.value ? props.data.tableEntries.filter(el => el.key == "Year founded")?.[0]?.value : "NA"}</span></p>
                </div>
                {/* <p>{props.companydetails}</p> */}
                <Link className='btn btn-primary border-0' to={"../companies/" + props.data.id}>Learn More</Link>
            </div>
        </div>
    );
}

export default TrendingCompany;