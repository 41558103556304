import { authContext } from 'contexts/AuthContext';
import { dataContext } from 'contexts/DataContext';
import React, { useContext, useRef, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import './editprofile.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { UPDATE_USER } from 'graphql/mutations';
import { toast } from 'react-toastify';
import placeholder from "assets/images/placeholder.png"

const EditProfile=()=>{
    const {data,setData} = useContext(dataContext);
    const { getClient } = useContext(authContext);
    const [loading, setLoading] = useState(false);
    let imageRef = useRef({});

    const schema = yup.object({
        firstName:yup.string().required(),
        lastName:yup.string().required(),
        biography:yup.string().required(),
    })
    
    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const client = getClient(true);
    let reader = new FileReader()  

    const onSubmit = data => {
        setLoading(true);

        client.mutate({
            mutation: UPDATE_USER,
            variables:{
                input:{
                    firstName:data.firstName,
                    lastName:data.lastName,
                    biography:data.biography,
                    avatar: {
                        copyright: "supertrends",
                        upload:data.image[0]
                    }
                },
            }
        }).then(res=>{
            setLoading(false);

            setData(null);
            toast.success("Profile Updated", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }).catch(err=>{
            setLoading(false);
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
    }

    const handleChange = (e) => {
        let newSrc = URL.createObjectURL(e.target.files[0])
        imageRef.current.src = newSrc;
    }

    if(data == null || data == undefined){
        return (
            <></>
        )
    } else return(
        <div className="account-details-block edit-profile">
            <div className='mx-md-5'>
                <Form onSubmit={handleSubmit(onSubmit)}>                
                    <div className='account-header'>
                        <div className='image-holder'><img ref={imageRef} src={data.avatar.url ? data.avatar.url : placeholder} alt="Description" /></div>
                        <strong>{data.firstName} {data.lastName}</strong>

                        <Form.Group controlId='formGridImage'>
                            <Form.Label className="btn btn-primary"> Change picture</Form.Label>
                            <Form.Control type="file" accept=".jpg, .jpeg, .png"  onChange={() => handleChange()} hidden {...register("image" , {onChange: e => {handleChange(e)}})}/>
                        </Form.Group>
                    </div>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder={data.firstName} defaultValue={data.firstName} {...register("firstName")}
                                className={`${errors.firstName ? "border border-danger" : ""}`}
                            />
                            <p>{errors.firstName?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" placeholder={data.lastName} defaultValue={data.lastName} {...register("lastName")}
                                className={`${errors.lastName ? "border border-danger" : ""}`}
                            />
                            <p>{errors.lastName?.message}</p>
                        </Form.Group>
                    </Row>

                    <Form.Group className="mb-3" controlId="formGridBio">
                        <Form.Label>Bio</Form.Label>
                        <Form.Control as="textarea" placeholder={data.biographyBlock.plainText} defaultValue={data.biographyBlock.plainText} {...register("biography")}/>
                        <p>{errors.biography?.message}</p>
                    </Form.Group>

                    <Button variant="primary" type="submit" disabled={loading}>
                        {!loading ? "Update" : <Spinner animation="grow" size="sm" variant="light" />}
                    </Button>
                </Form>
            </div>
        </div>
    );
}

export default EditProfile;