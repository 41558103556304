import React from 'react';

const LudicrousTimeLineSlide = (props) => {
    const { data, max } = props;
    let totalEvents = data?.historicEventsCount + data?.featuredEventsCount + data?.lockedEventsCount + data?.unlockedEventsCount;

    let dotsArr = [];
    dotsArr.push(
        ...Array(data.historicEventsCount).fill("historic"),
        ...Array(data.featuredEventsCount).fill("featured"),
        ...Array(data.unlockedEventsCount).fill("unlocked"),
        ...Array(data.lockedEventsCount).fill("locked")
    );

    return (
        <div className='slide-holder'>
            {dotsArr.filter((el,i) => i < 27).map(el => {
                return(
                    <span className={el}></span>
                )
            })}
        </div>
    );
}

export default LudicrousTimeLineSlide;